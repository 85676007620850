import React from "react";

import PageHelmet from "../PageHelmet";

import "./Main.css";
import logo from "../../images/logo.png";

import MainTitle from "../MainTitle/MainTitle";
import MainSlides from "../MainSlides/MainSlides";
import MainTaste from "../MainTaste/MainTaste";
import MainReviews from "../MainReviews/MainReviews";
import SocialMedia from "../SocialMedia/SocialMedia";

const Main = ({
  headerHeight,
  openComponent,
  handleGoGift,
  handleTelegramClick,
  handleVkClick,
}) => {
  return (
    <nav className="main" id="main">
      <PageHelmet
        title="Лучшие натуральные добавки для здоровья и красоты от компании 'Japan Formula'"
        description="Витамины и добавки для здоровья и красоты.
       Только натуральные ингредиенты и инновационные формулы.
       Доставка по всей России. Закажите сейчас и почувствуйте разницу!"
        keywords="БАДы, витамины, протеиновые батончики, протеиновые коктейли, здоровье, спортивное питание, добавки, витамины для здоровья, белковые батончики, качественные БАДы"
        image={logo}
        canonicalUrl="https://japanformula.com/"
      />
      <MainTitle headerHeight={headerHeight} handleGoGift={handleGoGift} />
      <MainSlides openComponent={openComponent} />
      <MainTaste />
      <SocialMedia
        handleTelegramClick={handleTelegramClick}
        handleVkClick={handleVkClick}
      />
      <MainReviews />
    </nav>
  );
};

export default Main;
