import React from "react";

const MarketplaceLink = ({ href, children }) => {
  const handleClick = (e) => {
    e.preventDefault();
    window.open(href, "_blank", "noopener,noreferrer");
  };

  return (
    <a href={href} onClick={handleClick}>
      {children}
    </a>
  );
};

export default MarketplaceLink;
