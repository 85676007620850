import React, { useEffect, useState } from "react";

import PageHelmet from "../PageHelmet";

import "./Podarok.css";

import ContactForm from "../ContactForm/ContactForm";
import MarketplaceLink from "../MarketplaceLink";
import { cardsMarketplaces } from "../../utils/cardsMarketplaces";
import podarok from "../../images/gift.png";
import eye from "../../images/eye.png";
import eyeBlack from "../../images/eyeBlack.png";
import feedback from "../../images/feedback.png";
import mail from "../../images/mail.png";
import shop from "../../images/shop.png";
import screen from "../../images/SCREEN.png";
import telegram from "../../images/telegram.png";
import VK from "../../images/VK.png";
import pochta from "../../images/pochta.png";
import important from "../../images/important.png";
import socialMedia from "../../images/socialMedia.png";

const phoneUserName = "loveourclients";

const Podarok = ({
  headerHeight,
  handleGoMain,
  handleGoStore,
  handleTelegramClick,
  handleVkClick,
}) => {
  const [hovered, setHovered] = useState(null);
  const [openQuestions, setOpenQuestions] = useState([]);

  const handleTitleClick = (id) => {
    if (openQuestions.includes(id)) {
      setOpenQuestions(openQuestions.filter((q) => q !== id));
    } else {
      setOpenQuestions([...openQuestions, id]);
    }
  };

  const handleItemHover = (id, isHovered) => {
    setHovered(isHovered ? id : null);
  };

  const handleBlockClick = (event) => {
    event.stopPropagation();
  };

  const handleTelegramClickSupport = () => {
    const telegramUrl = `https://t.me/${phoneUserName}`;
    window.open(telegramUrl, "_blank");
  };

  const handleEmailClick = () => {
    window.location.href = `mailto:loveourclients@yandex.ru`;
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const questionsData = [
    {
      id: 1,
      title: "Приобретите нашу продукцию",
      text: (
        <>
          <div className="podarok__marketplace-block">
            <p className="podarok__text">
              Приобретите любую продукцию на Вашей любимой торговой площадке
            </p>
            <div className="podarok__marketplace-shop">
              {cardsMarketplaces.map((marketplace) => (
                <MarketplaceLink key={marketplace.name} href={marketplace.url}>
                  <img
                    className="podarok__marketplace-item"
                    src={marketplace.img}
                    alt={marketplace.name}
                    title={marketplace.name}
                  />
                </MarketplaceLink>
              ))}
            </div>
          </div>
        </>
      ),
      img: shop,
    },
    {
      id: 2,
      title: "Оставьте отзыв на торговой площадке, где Вы делали покупку",
      text: (
        <>
          <div className="podarok__marketplace-block">
            {cardsMarketplaces.map((marketplace) => (
              <MarketplaceLink key={marketplace.name}>
                <div
                  className="podarok__marketplace-feedback"
                  onClick={handleBlockClick}
                >
                  <img
                    className="podarok__marketplace-item"
                    src={marketplace.img}
                    alt={marketplace.name}
                    style={{ cursor: "auto" }}
                  />
                  <p className="podarok__text">{marketplace.text}</p>
                </div>
              </MarketplaceLink>
            ))}
          </div>
        </>
      ),
      img: feedback,
    },
    {
      id: 3,
      title:
        "Сделайте скриншот ПОКУПКИ из ЛИЧНОГО кабинета торговой площадки, где совершили покупку",
      text: (
        <>
          <div className="podarok__marketplace-contacts">
            <p className="podarok__text">
              Зайдите в личный кабинет и сделайте скриншот покупки/заказа именно
              из ЛИЧНОГО кабинета.
              <br />
              <br /> P.S. скриншот покупки на Валдберис необходимо делать не
              через приложение, а через любой браузер (Chrome, Яндекс.Браузер,
              Safari, Opera, Firefox и т.д):
              <br /> зайти в раздел "Отзывы" и "Покупки" и сделать скрин.
              <br /> <br />
              При написании и публикации отзыва на площадке ozon.ru будьте
              внимательны и уверены в том что, пишете отзыв о товаре, а не о
              качестве доставки! Отзывы за доставку будут отклонены!
            </p>
          </div>
          {/* <div className="podarok__marketplace-screen"></div> */}
        </>
      ),
      img: screen,
    },
    {
      id: 4,
      title:
        "Сделайте скриншот ОТЗЫВА из ЛИЧНОГО кабинета торговой площадки, где совершили покупку",
      text: (
        <>
          <div className="podarok__marketplace-contacts">
            <div className="podarok__text">
              1. Чтобы получить кэшбэк необходимо оставить отзыв на купленный
              продукт, по правилам всех маркетплейсов отзыв о продукте можно
              оставить только 1 раз.
              <br />
              2. Чтобы получить кэшбэк ещё раза, Вам необходимо приобрести 2
              разных продукта, таким образом у вас будет возможность оставить
              отзыв о каждом из них, или купить продукт на другой площадке.
              <br />
              3. Получить кэш за отзыв вы можете в течение месяца со дня его
              написания. Отзывы написанные более месяца назад не
              рассматриваются.
              <br /> 4. Если приобретаете несколько разных продуктов, пишите
              разные отзывы, а не копируйте одинаковый отзыв на все
              продукты,ведь каждый продукт индивидуальный.
              <br /> <br />
              При написании и публикации отзыва на площадке ozon.ru будьте
              внимательны и уверены в том что, пишете отзыв о товаре, а не о
              качестве доставки! Отзывы за доставку будут отклонены!
              <br />
              <br /> P.S. Фотографии, в которых отзыв сфотографирован с экрана
              компьютера, камеру телефона/планшета, не принимаются!
              <br /> <br /> P.S. Так же не принимаются отзывы из общего доступа,
              которые может скопировать каждый, только из личного кабинета!
            </div>
          </div>
        </>
      ),
      img: screen,
    },

    {
      id: 5,
      title: "Подпишитесь на любую из наших соцсетей",
      text: (
        <>
          <div className="podarok__social-media">
            <p className="podarok__text">
              1. Чтобы получить кэшбэк необходимо подписаться на любую из наших
              сетей.
            </p>
            <div className="podarok__telegram-block">
              <img src={VK} alt="Иконка ВКонтакте" onClick={handleVkClick} />
              <img
                src={telegram}
                alt="Иконка телеграмм"
                onClick={handleTelegramClick}
              />
            </div>
            <p className="podarok__text">
              2. Подпишись, чтобы первым узнавать о наших новых продуктах,
              участвовать в интересных акциях!
            </p>
          </div>
        </>
      ),
      img: socialMedia,
    },
    {
      id: 6,
      title:
        "Пришлите ФОТОГРАФИЮ товара, скриншот ОТЗЫВА и ПОКУПКИ из ЛИЧНОГО кабинета торговой площадки, где совершили покупку, а также скриншот подписки на любую нашу соцсеть.",
      text: (
        <>
          <div className="podarok__marketplace-contacts">
            <div className="podarok__text">
              <p className="podarok__subtitle-contacts"> Пришлите: </p>
              <br /> 1. фотографию купленного товара;
              <br />
              2. скриншот покупки(заказа) из ЛИЧНОГО кабинета;
              <br />
              3. скриншот отзыва из ЛИЧНОГО кабинета;
              <br />
              4. скриншот вашей подписки на любую из наших соцсетей;
              <br />
              <br />
              <p className="podarok__subtitle-contacts">
                В теме сообщения укажите:
              </p>
              <br /> 1. Ваше ФИО для перевода;
              <br />
              2. номер телефона - если нужно будет с вами связаться;
              <br />
              3. карта Сбербанка или Альфа-Банка, либо номер СБП, какой именно
              Банк для перевода Бонуса-кэш. (Если будет указана карта другого
              банка, с Вашего кэш могут снять комиссию).
            </div>
            <p className="podarok__subtitle-btn">
              и всё пришлите в telegram @loveourclients
            </p>
            <button
              className="podarok__contacts-btn  hover"
              onClick={handleTelegramClickSupport}
            >
              Telegram @loveourclients
            </button>

            <p className="podarok__subtitle-btn">
              ИЛИ заполните и отправьте форму, прикрепив нужные
              фотографии/скрины
            </p>
            <ContactForm />

            <p className="podarok__subtitle-btn">
              ИЛИ всё пришлите на почту loveourclients@yandex.ru
            </p>
            <button
              className="podarok__contacts-btn podarok__btn-email hover"
              onClick={handleEmailClick}
            >
              Email
            </button>

            <p className="podarok__subtitle-btn">
              После проверки Вашего отзыва мы пришлем Вам кэшбэк.
            </p>
          </div>
        </>
      ),
      img: mail,
    },
    {
      id: 7,
      title: "Важное",
      text: (
        <>
          <div className="podarok__marketplace-contacts">
            <div className="podarok__text">
              <p className="podarok__subtitle-contacts"> ВРЕМЯ </p>
              <br />
              Получить кэш за отзыв можете только в течение месяца со дня его
              написания.
            </div>
            <div className="podarok__text">
              <p className="podarok__subtitle-contacts"> ОЗОН </p>
              <br />
              При написании и публикации отзыва на площадке ozon.ru будьте
              внимательны и уверены в том что, пишете отзыв о товаре, а не о
              качестве доставки! Отзывы за доставку будут отклонены!
            </div>
            <div className="podarok__text">
              <p className="podarok__subtitle-contacts"> ВАЛДБЕРИС </p>
              <br />
              Скриншот покупки на Валдберис необходимо делать не через
              приложение, а через любой браузер (Chrome, Яндекс.Браузер, Safari,
              Opera, Firefox и т.д):
              <br /> зайти в раздел "Отзывы" и "Покупки" и сделать скрин.
            </div>
          </div>
        </>
      ),
      img: important,
    },
  ];

  return (
    <section
      className="podarok"
      id="podarok"
      style={{ paddingTop: `${headerHeight}px` }}
    >
      <PageHelmet
        title="Подарок от Japan Formula"
        description="Лучшие витамины и добавки для вашего здоровья. Натуральные продукты, высокое качество. Заботьтесь о себе с японской точностью и инновациями."
        keywords="Витамины, добавки, протеиновые батончики"
        image={podarok}
        canonicalUrl="https://japanformula.com/podarok/"
        questionsData={questionsData}
        cardsMarketplaces={cardsMarketplaces}
      />
      <div className="podarok__wrapper">
        <div className="podarok__image-container">
          <img className="podarok__img" src={podarok} alt="продукция jf" />
        </div>
        <div className="about-us__button-block">
          <button
            className="about-us__button-main privacy-policy__button"
            type="button"
            onClick={handleGoMain}
          >
            На главную
          </button>
          <button
            className="about-us__button-main privacy-policy__button"
            type="button"
            onClick={handleGoStore}
          >
            Где купить
          </button>
        </div>
        <h1 className="podarok__title">Как получить ПОДАРОК</h1>
        <h2 className="podarok__subtitle">
          * В случае если с товаром что-то не так, не спешите оставлять ваш
          отзыв.
          <br /> Напишите нам:
        </h2>
        <ul className="podarok__list-contact">
          <li className="podarok__contact-item hover">
            <img
              src={telegram}
              alt="Иконка телеграмм"
              onClick={handleTelegramClickSupport}
            />
          </li>
          <li className="podarok__contact-item hover">
            <img src={pochta} alt="Иконка почты" onClick={handleEmailClick} />
          </li>
        </ul>
        <h2 className="podarok__subtitle">и мы с радостью вам поможем!</h2>
        <div className="podarok__blocks-container">
          {questionsData.map((question) => (
            <div className="podarok__block" key={question.id}>
              <div
                className={`podarok__header ${
                  openQuestions.includes(question.id) ? "active" : ""
                } `}
                onClick={() => handleTitleClick(question.id)}
                onMouseEnter={() => handleItemHover(question.id, true)}
                onMouseLeave={() => handleItemHover(question.id, false)}
                title="Нажми, чтобы узнать подробнее"
              >
                <img
                  className="podarok__img-icon"
                  src={question.img}
                  alt="продукция jf"
                />
                <h2 className="podarok__block-title">{question.title}</h2>
                <img
                  className={`podarok__img-eye ${
                    hovered === question.id ? "active" : ""
                  } `}
                  src={hovered === question.id ? eyeBlack : eye}
                  alt="значок глаза"
                />
              </div>
              {openQuestions.includes(question.id) && (
                <div>{question.text}</div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Podarok;
