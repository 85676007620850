import React, { useState } from "react";
import MarketplaceLink from "../MarketplaceLink";
import "./MainReviews.css";

import arrowRight from "../../images/arrowRight.png";
import Point from "../../images/Point.svg";

import { cardsMainReviewsData } from "../../utils/cardsMainReviewsData";
import { cardsMarketplaces } from "../../utils/cardsMarketplaces";

const MainReviews = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleArrowClick = (direction) => {
    if (direction === "next") {
      setCurrentIndex(
        (prevIndex) => (prevIndex + 1) % cardsMainReviewsData.length
      );
    } else if (direction === "prev") {
      setCurrentIndex(
        (prevIndex) =>
          (prevIndex - 1 + cardsMainReviewsData.length) %
          cardsMainReviewsData.length
      );
    }
  };

  const handlePointClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <section className="main-reviews" id="main-reviews">
      <div className="main-reviews__container">
        <h1 className="main-reviews__title">Реальные отзывы покупателей</h1>
        <div className="main-reviews__wrapper">
          <div className="main-reviews__block">
            <img
              className="main-reviews__arrow main-reviews__left-arrow hover"
              src={arrowRight}
              alt="значок стрелки налево"
              onClick={() => handleArrowClick("prev")}
            />
            <div className="main-reviews__img">
              <img
                src={cardsMainReviewsData[currentIndex].img}
                alt={cardsMainReviewsData[currentIndex].alt}
              />
            </div>

            <img
              className="main-reviews__arrow hover"
              src={arrowRight}
              alt="значок стрелки направо"
              onClick={() => handleArrowClick("next")}
            />
          </div>
          <h2 className="main-reviews__author">
            {cardsMainReviewsData[currentIndex].text}
          </h2>

          <div className="main-reviews__point-group">
            {cardsMainReviewsData.map((review, index) => (
              <img
                key={index}
                className="main-reviews__img-point hover"
                src={Point}
                alt="значок точки"
                onClick={() => handlePointClick(index)}
              />
            ))}
          </div>
          <div className="main-reviews__content">
            <h2 className="main-reviews__content-subtitle">
              Ещё больше отзывов смотри в популярных онлайн-магазинах
            </h2>
            <div className="main-reviews__marketplace-list">
              {cardsMarketplaces.map((marketplace) => (
                <MarketplaceLink key={marketplace.name} href={marketplace.url}>
                  <img
                    className="main-reviews__marketplace-item"
                    src={marketplace.img}
                    alt={marketplace.name}
                    title={marketplace.name}
                  />
                </MarketplaceLink>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainReviews;
