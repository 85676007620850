import React from "react";
import { useNavigate } from "react-router-dom";

import "./Footer.css";
import instagram from "../../images/insagram.png";
import telegram from "../../images/telegram.png";
import vk from "../../images/VK.png";

const Footer = ({
  handleGoMain,
  handleGoGift,
  handleTelegramClick,
  handleVkClick,
}) => {
  const todaytYear = new Date().getFullYear();

  const navigate = useNavigate();

  const handlePrivacyPolicyClick = () => {
    navigate("/privacy-policy/");
  };

  const handleCoocieClick = () => {
    navigate("/about-cookies/");
  };

  const handleGoAbout = () => {
    navigate("/about/");
  };

  const handleGoScientificCenter = () => {
    navigate("/scientific-center/");
  };

  const handleGoProducts = () => {
    navigate("/products/");
  };

  const handleGoCollagen = () => {
    navigate("/products/collagen/");
  };

  const handleGoProteinBars = () => {
    navigate("/products/protein-bars/");
  };

  const handleGoFatBurners = () => {
    navigate("/products/fat-burners/");
  };

  const handleGoDetox = () => {
    navigate("/products/detox/");
  };

  const handleGoVitamins = () => {
    navigate("/products/vitamins/");
  };

  return (
    <footer className="footer">
      <div className="footer__wrapper">
        <div className="footer__block">
          <ul className="footer__list">
            <li className="footer__item">
              <ul
                className="footer__item-title hover"
                onClick={handleGoProducts}
              >
                каталог JAPAN FORMULA
              </ul>

              <ul>
                <li>
                  <p
                    className="footer__item-subtitle"
                    onClick={handleGoCollagen}
                  >
                    коллаген
                  </p>
                </li>
                <li>
                  <p
                    className="footer__item-subtitle"
                    onClick={handleGoProteinBars}
                  >
                    протеиновые батончики
                  </p>
                </li>
                <li>
                  <p
                    className="footer__item-subtitle"
                    onClick={handleGoFatBurners}
                  >
                    жиросжигатели
                  </p>
                </li>
                <li>
                  <p className="footer__item-subtitle" onClick={handleGoDetox}>
                    детокс
                  </p>
                </li>
                <li>
                  <p
                    className="footer__item-subtitle"
                    onClick={handleGoVitamins}
                  >
                    витамины
                  </p>
                </li>
              </ul>
            </li>
            <li className="footer__item">
              <ul className="footer__item-title">Ссылки</ul>
              <ul>
                <li>
                  <p className="footer__item-subtitle" onClick={handleGoMain}>
                    главная
                  </p>
                </li>
                <li>
                  <p
                    className="footer__item-subtitle"
                    onClick={handleGoScientificCenter}
                  >
                    центр знаний
                  </p>
                </li>
                <li>
                  <p className="footer__item-subtitle" onClick={handleGoAbout}>
                    о нас
                  </p>
                </li>
              </ul>
            </li>
            <li className="footer__item">
              <ul className="footer__item-title">Для вас</ul>
              <ul>
                <li>
                  <p className="footer__item-subtitle" onClick={handleGoGift}>
                    подарок от команды
                  </p>
                </li>
                <li>
                  <p
                    className="footer__item-subtitle"
                    onClick={handlePrivacyPolicyClick}
                  >
                    политика конфиденциальности
                  </p>
                </li>
                <li>
                  <p
                    className="footer__item-subtitle"
                    onClick={handleCoocieClick}
                  >
                    файлы куки
                  </p>
                </li>
              </ul>
            </li>
          </ul>
          <ul className="footer__list-contact">
            <div>
              <li className="footer__telegram hover">
                <img
                  src={telegram}
                  alt="Иконка телеграмм"
                  onClick={handleTelegramClick}
                />
              </li>
              <li className="footer__telegram hover">
                <img src={vk} alt="Иконка Вконтакте" onClick={handleVkClick} />
              </li>
            </div>
            <li className="footer__block-instagram hover">
              <div className="footer__instagram hover">
                <a
                  href="https://www.instagram.com/japanformula_ru/?hl=ru"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={instagram} alt="логотип сайта JAPAN FORMULA" />
                </a>
                <p className="footer__asterisk">
                  *Признана экстремистской организацией и запрещена на
                  территории РФ.
                </p>
              </div>
            </li>
          </ul>
        </div>

        <hr />
        <div className="footer__block-info">
          <h1 className="footer__title">JAPAN FORMULA</h1>
          <div className="footer__copyright">{todaytYear}</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
