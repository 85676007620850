import React from "react";
import { useLocation } from "react-router-dom";

import "./Header.css";

import NavTab from "../NavTab/NavTab";
import Menu from "../Menu/Menu";

import logo from "../../images/logo.png";

const Header = ({ isMobile, handleGoMain, headerHeight, handleGoGift }) => {
  const location = useLocation();

  return (
    <header className="header">
      <div className="header__wrapper">
        <div className="header__logo" onClick={handleGoMain}>
          <img src={logo} alt="логотип сайта Japan Formula" />
        </div>

        {isMobile ? (
          <Menu isMobile={isMobile} />
        ) : (
          <NavTab isMobile={isMobile} headerHeight={headerHeight} />
        )}

        {location.pathname !== "/privacy-policy/" &&
          location.pathname !== "/podarok/" && (
            <button className="header__btn" onClick={handleGoGift}>
              ПОЛУЧИТЬ ПОДАРОК
            </button>
          )}
      </div>
    </header>
  );
};

export default Header;
