import vitCarnitin11 from "./imagesVitamins/vitCarnitin11.webp";
import vitCarnitin12 from "./imagesVitamins/vitCarnitin12.webp";
import vitCarnitin13 from "./imagesVitamins/vitCarnitin13.webp";
import vitWoman11 from "./imagesVitamins/vitWoman11.webp";
import vitWoman12 from "./imagesVitamins/vitWoman12.webp";
import vitWoman13 from "./imagesVitamins/vitWoman13.webp";
import vitMan11 from "./imagesVitamins/vitMan11.webp";
import vitMan12 from "./imagesVitamins/vitMan12.webp";
import vitMan13 from "./imagesVitamins/vitMan13.webp";
import vitEye11 from "./imagesVitamins/vitEye11.webp";
import vitEye12 from "./imagesVitamins/vitEye12.webp";
import vitEye13 from "./imagesVitamins/vitEye13.webp";
import vitMG11 from "./imagesVitamins/vitMG11.webp";
import vitMG12 from "./imagesVitamins/vitMG12.webp";
import vitMG13 from "./imagesVitamins/vitMG13.webp";
import vitCollagenMarine11 from "./imagesVitamins/vitCollagenMarine11.webp";
import vitCollagenMarine12 from "./imagesVitamins/vitCollagenMarine12.webp";
import vitCollagenMarine13 from "./imagesVitamins/vitCollagenMarine13.webp";
import vitCollagen11 from "./imagesVitamins/vitCollagen11.webp";
import vitCollagen12 from "./imagesVitamins/vitCollagen12.webp";
import vitCollagen13 from "./imagesVitamins/vitCollagen13.webp";
import vitZMA11 from "./imagesVitamins/vitZMA11.webp";
import vitZMA12 from "./imagesVitamins/vitZMA12.webp";
import vitZMA13 from "./imagesVitamins/vitZMA13.webp";
import vitMelatonin11 from "./imagesVitamins/vitMelatonin11.webp";
import vitMelatonin12 from "./imagesVitamins/vitMelatonin12.webp";
import vitMelatonin13 from "./imagesVitamins/vitMelatonin13.webp";


export const vitaminsProducts = {
    'vitamins-women': {
        title: 'Women витамины и витаминный комплекс для женского здоровья',
        productType: 'Пищевая добавка. Капсулы.',
        description: (
            <>
                <p>Women витамины и витаминный комплекс от Japan Formula - это не просто добавка к пище. Это настоящее спасение для женщин, которые заботятся о своем здоровье.</p>
                <p>  Japan Formula разработал уникальный комплекс витаминов, который содержит все необходимые витамины и минералы для поддержания женского здоровья. Витамины для женщин помогают улучшить состояние кожи, волос и ногтей, укрепить иммунную систему и повысить энергию.</p>
                <p>   Women витамины и витаминный комплекс Japan Formula содержат все необходимые витамины для женщин: витамин А, витамин D, витамин Е, витамин С, витамин В12, фолиевая кислота, железо, цинк, кальций, магний, медь, марганец, йод и селен.</p>
            </>
        ),
        price: '850',
        taste: '',
        purposes: ['здоровье кожи, волос и ногтей', 'спокойствие', 'красота и здоровье', 'лучшее для женщин'],
        textPurpose: 'дефицит витамина C, укрепление иммунитета',
        composition: 'Мальтодекстрин, желатиновая капсула, Цитрат магния, карбонат кальция, Аскорбиновая кислота (витамин С), Экстракт Сои (Содержит Биотин) экстракт зеленого чая, экстракт виноградной косточки, гингко билоба экстракт, экстракт пажитника, Витамин В3, Витамин Е, Цитрат Цинка, экстракт черного перца, Фумарат железа, Витамин В5, глюконат марганца, глюконат меди , витамин В6, пиколинат хрома витамин В7 , йодит какальция, витамин В9, Селенит натрия,',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В оригинальной упаковке. В защищенном от солнца помещении',
        gender: 'Женский',
        images: [vitWoman11, vitWoman12, vitWoman13],
        img: vitWoman11,
        contraindications: <>
            <p>1. Индивидуальная непереносимость компонентов, входящих в состав</p>
            <p>2. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
            <p>3. Дети до 12 лет: Не рекомендуется детям младше 12 лет без консультации с врачом.</p>
        </>,
        contentMethod: (
            <>
                <p>Есть противопоказания, посоветуйтесь с врачом.</p>
                <p>Взрослым по 1 капсуле 3 раза в день во время еды.</p>
                <p>Продолжительность приема - 6 месяцев.</p>мл
                <p>При необходимости прием можно повторить.</p>

            </>
        ),
    },
    'vitamins-men': {
        title: 'Витаминный комплекс для мужчин, мультивитамины спортивные',
        productType: 'Пищевая добавка. Капсулы.',
        description: (
            <>
                <p>Витаминный комплекс для мужчин Japan Formula - это уникальный продукт, который поможет укрепить здоровье и повысить энергию. Этот витаминный комплекс содержит все необходимые витамины и минералы для поддержания здоровья мужчин. Он также содержит антиоксиданты, которые помогают защитить клетки от повреждений и улучшить общее состояние организма.</p>
                <p>Преимущества витаминного комплекса для мужчин Japan Formula:</p>
                <p> - Укрепление иммунитета</p>
                <p> - Улучшение общего состояния организма</p>
                <p>- Повышение энергии и работоспособности</p>
                <p> - Защита клеток от повреждений</p>
                <p>  - Поддержка здоровья мужчин</p>
            </>
        ),
        price: '900',
        taste: '',
        purposes: ['энергия', 'лучшее для мужчин'],
        textPurpose: 'Восстановление, Выносливость, Красота и здоровье, Набор мышечной массы, Повышение работоспособности, ',
        composition: 'Мальтодекстрин, желатиновая капсула, Цитрат магния, карбонат кальция, Аскорбиновая кислота (витамин С), Экстракт Сои (Содержит Биотин) экстракт зеленого чая, экстракт виноградной косточки, гингко билоба экстракт, экстракт пажитника, Витамин В3, Витамин Е, Цитрат Цинка, экстракт черного перца, Фумарат железа, Витамин В5, глюконат марганца, глюконат меди , витамин В6, пиколинат хрома витамин В7 , йодит какальция, витамин В9, Селенит натрия,',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В оригинальной упаковке. В защищенном от солнца помещении',
        gender: 'Мужской',
        images: [vitMan11, vitMan12, vitMan13],
        img: vitMan11,
        contraindications: <>
            <p>1. Индивидуальная непереносимость компонентов, входящих в состав</p>
            <p>2. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
            <p>3. Дети до 12 лет: Не рекомендуется детям младше 12 лет без консультации с врачом.</p>
        </>,
        contentMethod: (
            <>
                <p>Есть противопоказания, посоветуйтесь с врачом.</p>
                <p>Взрослым по 1 капсуле 3 раза в день во время еды.</p>
                <p>Продолжительность приема - 6 месяцев.</p>мл
                <p>При необходимости прием можно повторить.</p>

            </>
        ),
    },
    'vitamins-vision': {
        title: 'Витамины для зрения с лютеином и витамином А для глаз',
        productType: 'Пищевая добавка. Капсулы.',
        description: (
            <>
                <p>Витаминный комплекс для глаз "Japan Formula" - это уникальное средство, которое поможет сохранить здоровье Ваших глаз и улучшить зрение. Он содержит лютеин и витамин А, которые являются важными компонентами для поддержания здоровья глаз.</p>
                <p>Преимущества витаминного комплекса "Japan Formula":</p>
                <p> -  Улучшение зрения: лютеин помогает улучшить остроту зрения и предотвратить развитие заболеваний глаз.</p>
                <p> - Защита от ультрафиолетовых лучей: витамин А в комплексе помогает защитить глаза от вредного воздействия солнечных лучей.</p>
                <p>- Снижение усталости глаз: витаминный комплекс помогает уменьшить усталость глаз и повысить концентрацию внимания.</p>
                <p> - Укрепление сосудов: лютеин способствует укреплению капилляров в глазах и улучшает кровообращение.</p>
                <p>  - Уменьшение риска развития заболеваний глаз: витамины в комплексе помогают предотвратить развитие многих заболеваний глаз, таких как катаракта и глаукома.</p>
            </>
        ),
        price: '800',
        taste: '',
        textPurpose: 'повышенная утомляемость глаз, поддержание здорового состояния сосудов глаз, профилактика возникновения и прогрессирования заболеваний глаз',
        composition: 'Мальтодекстрин, Витамин С, экстракт виноградных косточек, Экстракт ананаса, экстракт Черники, Альфа липоевая кислота, Витамин Р, желатиновые капсулы, цитрат цинка, Витамин А , лютеин',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        purposes: ['спокойствие', 'восстановление', 'красота и здоровье', 'лучшее для женщин', 'лучшее для мужчин'],
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В оригинальной упаковке. В защищенном от солнца помещении',
        gender: 'Женский, Мужской',
        images: [vitEye11, vitEye12, vitEye13],
        img: vitEye11,
        contraindications: <>
            <p>1. Индивидуальная непереносимость компонентов, входящих в состав</p>
            <p>2. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
            <p>3. Дети до 12 лет: Не рекомендуется детям младше 12 лет без консультации с врачом.</p>
        </>,
        contentMethod: (
            <>
                <p>Есть противопоказания, посоветуйтесь с врачом.</p>
                <p>Взрослым по 1 капсуле 3 раза в день во время еды.</p>
                <p>Продолжительность приема - 6 месяцев.</p>мл
                <p>При необходимости прием можно повторить.</p>

            </>
        ),
    },
    'vitamins-magne': {
        title: 'Магний цитрат с витамином В6 B 12, комплекс предтренировочный',
        productType: 'Пищевая добавка. Капсулы.',
        description: (
            <>
                <p>Japan Formula Магний цитрат - это комплекс витаминов B6 и B12 с добавлением магния, который помогает улучшить работу нервной системы, укрепить кости и мышцы, а также повысить уровень энергии. Этот продукт является отличным выбором для тех, кто хочет получить максимальную пользу от магния и витаминов группы B.</p>
                <p>Магний цитрат Japan Formula является одним из самых популярных продуктов на рынке, благодаря своему высокому качеству и эффективности. Он содержит магний в форме цитрата, который легко усваивается организмом и быстро начинает действовать. Витамин B6 помогает улучшить настроение и снизить уровень стресса, а витамин B12 способствует нормальному функционированию нервной системы и снижению уровня гомоцистеина в крови.</p>
                <p> Преимущества Japan Formula Магния цитрат:</p>
                <p> - Улучшает работу нервной системы.</p>
                <p>- Укрепляет кости и мышцы.</p>
                <p> - Помогает снизить уровень стресса.</p>
                <p>  -  Улучшает настроение.</p>
            </>
        ),
        taste: '',
        price: '800',
        purposes: ['спокойствие', 'восстановление', 'красота и здоровье', 'лучшее для женщин', 'лучшее для мужчин'],
        textPurpose: 'предтренировочным комплексом, который поможет вам достичь максимальных результатов в спорте и повседневной жизни',
        composition: 'Кальций, Мальтодекстрин, Магний, Витамин С, Витамин В3, Витамин Е, Витамин В6, Витамин В5, желатиновая капсула (желатин, загуститель, вода, пищевой краситель)',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В оригинальной упаковке. В защищенном от солнца помещении',
        gender: 'Женский, Мужской',
        images: [vitMG11, vitMG12, vitMG13],
        img: vitMG11,
        contraindications: <>
            <p>1. Индивидуальная непереносимость компонентов, входящих в состав</p>
            <p>2. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
            <p>3. Дети до 12 лет: Не рекомендуется детям младше 12 лет без консультации с врачом.</p>
        </>,
        contentMethod: (
            <>
                <p>Есть противопоказания, посоветуйтесь с врачом.</p>
                <p>Взрослым по 2 капсуле 2 раза в день во время еды.</p>
                <p>Продолжительность приема - 6 месяцев.</p>мл
                <p>При необходимости прием можно повторить.</p>

            </>
        ),
    },
    'vitamins-collagen-marine': {
        title: 'Коллаген морской в капсулах c витамином C, питьевой collagen С',
        productType: 'Пищевая добавка. Капсулы.',
        description: (
            <>
                <p>Japan Formula - это бренд, который специализируется на производстве высококачественного коллагена в капсулах с витамином С. Коллаген в капсулах - это удобный для приема продукт, который можно пить как обычную воду.</p>
                <p>Коллаген - это белок, который является основным строительным материалом кожи, суставов, костей и других тканей. Он также играет важную роль в поддержании здоровья волос, ногтей и зубов. Недостаток коллагена может привести к различным заболеваниям и проблемам со здоровьем.</p>
                <p> Преимущества употребления коллагена с витамином С от Japan Formula:</p>
                <p> - Улучшение состояния кожи, волос и ногтейУлучшает работу нервной системы.</p>
                <p>- Поддержка здоровья суставов и костей</p>
                <p> - Профилактика заболеваний и проблем со здоровьем.</p>
                <p> Japan Formula использует только высококачественный морской коллаген, который обладает всеми полезными свойствами натурального коллагена. Продукт также содержит витамин С, который помогает улучшить усвоение коллагена и повышает его эффективность.</p>
            </>
        ),
        taste: '',
        price: '1300',
        textPurpose: 'Восстановление, Красота и здоровье, Профилактика',
        composition: (
            <>
                <p>Основной ингредиент: рыбий коллаген. Кукурузное водорастворимое волокно, карбонат кальция, Витамин С, изомальтоолигосахарид, гиалуроновая кислота, тальк, гидроксипропилметилцеллюлоза, диоксид кремния, витамин В3, витамин Е.</p>
                <p>Диетические особенности: без ГМО, без аспартама, без глюкозы, без глютена, без лактозы, без сукралозы, веганский</p>
            </>
        ),

        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В оригинальной упаковке. В защищенном от солнца помещении',
        gender: 'Женский, Мужской',
        images: [vitCollagenMarine11, vitCollagenMarine12, vitCollagenMarine13],
        img: vitCollagenMarine11,
        contraindications: <>
            <p>1. Индивидуальная непереносимость компонентов, входящих в состав</p>
            <p>2. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
            <p>3. Дети до 12 лет: Не рекомендуется детям младше 12 лет без консультации с врачом.</p>
        </>,
        contentMethod: (
            <>
                <p>Есть противопоказания, посоветуйтесь с врачом.</p>
                <p>Взрослым по 1 капсуле 3 раза в день во время еды.</p>
                <p>Продолжительность приема - 6 месяцев.</p>мл
                <p>При необходимости прием можно повторить.</p>

            </>
        ),
    },
    'vitamins-collagen': {
        title: 'Коллаген в капсулах с витамином С, collagen для суставов 1 и 3 тип',
        productType: 'Пищевая добавка. Капсулы.',
        description: (
            <>
                <p>Коллаген – это белок, который является основным компонентом соединительной ткани и необходим для поддержания здоровья суставов. Он помогает укрепить суставы, улучшить их гибкость и снизить риск развития остеоартрита.</p>
                <p>Japan Formula предлагает коллаген в капсулах, который содержит витамин С, который помогает улучшить усвоение коллагена организмом. Коллаген первого типа является основным белком соединительной ткани, а коллаген третьего типа способствует укреплению суставов и связок.</p>
                <p> Преимущества коллагена в капсулах:</p>
                <p>- Укрепление суставов и связок</p>
                <p>- Укрепление суставов и связок</p>
                <p>- Снижение риска развития остеоартрита</p>
                <p>- Защита от повреждений суставов при физических нагрузках</p>
                <p>- Поддержка здоровья кожи, волос и ногтей</p>
                <p>Коллаген в капсулах от Japan Formula – это надежный и эффективный способ укрепить здоровье суставов и получить все преимущества этого белка. Попробуйте и убедитесь сами!</p>
            </>
        ),
        taste: '',
        price: '1300',
        textPurpose: 'Восстановление, Красота и здоровье, Профилактика',
        composition: (
            <>
                <p>Основной ингредиент: коллаген говяжий. Декстрин кукурузный, мальтодекстрин, соевая клетчатка, сухое молоко 1.5%, кукурузный крахмал, комплекс витаминов ( витамин С, витамин D3, витамин В5, витамин В6, витамин В2, витамин В1, витамин Е, витамин В9).</p>
                <p>Диетические особенности: без ГМО, без аспартама, без глюкозы, без глютена, без лактозы, без сукралозы</p>
            </>
        ),

        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В оригинальной упаковке. В защищенном от солнца помещении',
        gender: 'Женский, Мужской',
        images: [vitCollagen11, vitCollagen12, vitCollagen13],
        img: vitCollagen11,
        contraindications: <>
            <p>1. Индивидуальная непереносимость компонентов, входящих в состав</p>
            <p>2. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
            <p>3. Дети до 12 лет: Не рекомендуется детям младше 12 лет без консультации с врачом.</p>
        </>,
        contentMethod: (
            <>
                <p>Есть противопоказания, посоветуйтесь с врачом.</p>
                <p>Взрослым по 1 капсуле 2 раза в день во время еды.</p>
                <p>Продолжительность приема - 6 месяцев.</p>мл
                <p>При необходимости прием можно повторить.</p>

            </>
        ),
    },
    'vitamins-zma': {
        title: 'ZMA предтренировочный комплекс, бустер тестостерона, для роста мышц',
        productType: 'Пищевая добавка. Капсулы/таблетки.',
        description: (
            <>
                <p>Zma - это предтренировочный комплекс от бренда Japan Formula. Он предназначен для повышения уровня тестостерона и роста мышц. Zma содержит в себе экстракт корня женьшеня, который помогает улучшить кровообращение и повысить уровень энергии. Также в составе есть цинк, который является важным микроэлементом для здоровья организма.</p>
                <p>Zma имеет ряд преимуществ:</p>
                <p>- Повышает уровень тестостерона - тестостерон является главным гормоном, отвечающим за рост мышц и либидо.</p>
                <p>- Увеличивает энергию - экстракт корня женьшеня помогает улучшить кровообращение, что позволяет организму получать больше кислорода и энергии.</p>
                <p>- Улучшает когнитивные функции - цинк является важным микроэлементом, который улучшает работу мозга и концентрацию.</p>
                <p>- Помогает уменьшить усталость - Zma повышает уровень энергии, что помогает уменьшить усталость и улучшить общее состояние организма.</p>
                <p>- Подходит для всех - Zma не содержит запрещенных веществ и может быть использован всеми, кто хочет повысить уровень тестостерона и увеличить мышечную массу.</p>
                <p>В целом, Zma является отличным выбором для тех, кто хочет улучшить свою физическую форму и повысить уровень тестостерона. Его состав содержит только натуральные ингредиенты, которые помогут вам достичь своих целей.</p>

            </>
        ),
        price: '950',
        taste: '',
        purposes: ['энергия', 'спокойствие', 'восстановление', 'красота и здоровье', 'лучшее для мужчин'],
        textPurpose: 'Восстановление, Выносливость, Красота и здоровье',
        composition: (
            <>
                <p>Мальтодекстрин , L аспарагиновая кислота, цитрат магния, цитрат цинка, желатиновые капсулы, витамин Б6</p>
            </>
        ),
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В оригинальной упаковке. В защищенном от солнца помещении',
        gender: 'Женский, Мужской',
        images: [vitZMA11, vitZMA12, vitZMA13],
        img: vitZMA11,
        contraindications: <>
            <p>1. Индивидуальная непереносимость компонентов, входящих в состав</p>
            <p>2. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
            <p>3. Дети до 14 лет: Не рекомендуется детям младше 14 лет без консультации с врачом.</p>
        </>,
        contentMethod: (
            <>
                <p>Есть противопоказания, посоветуйтесь с врачом.</p>
                <p>Взрослым по 1 капсуле 3 раза в день во время еды.</p>
                <p>Продолжительность приема - 6 месяцев.</p>мл
                <p>При необходимости прием можно повторить.</p>

            </>
        ),
    },
    'vitamins-carnitine': {
        title: 'L карнитин l carnitine жиросжигатель, капсулы для похудения',
        productType: 'Пищевая добавка. Капсулы.',
        description: (
            <>
                <p>Japan Formula l карнитин l-carnitine" - это инновационный жиросжигающий продукт, который поможет вам достичь желаемого результата в похудении. Он содержит активный ингредиент, который ускоряет метаболизм и сжигает лишние жиры, что позволяет вам чувствовать себя энергичнее и бодрее.</p>
                <p>Капсулы содержат только натуральные ингредиенты, которые не вызывают побочных эффектов и безопасны для здоровья. Кроме того, они легко усваиваются и быстро начинают действовать.</p>
                <p> Преимущества "Japan Formula" заключаются в том, что он помогает уменьшить аппетит, ускоряет обмен веществ и способствует сжиганию жиров. Это позволяет вам контролировать свой вес и достигать желаемых результатов в похудении без вреда для здоровья.</p>
                <p>"Japan Formula" - это отличный выбор для тех, кто хочет достичь своей цели в похудении и получить желаемый результат. Попробуйте его уже сегодня и убедитесь в его эффективности!</p>
            </>
        ),
        price: '800',
        taste: '',
        textPurpose: 'Спортивное питание, жиросжигатель, кето, комплекс витаминов, похудение; корректор аппетита, жиросжигатель',
        composition: (
            <>
                <p>Л-карнитин тартрат, стеарат магния, кальций, Витамин С, экстракт гуараны, экстракт ананаса, экстракт плодов гарцинии камбоджийской, экстракт зеленого кофе, альфа липоевая кислота, желатиновая капсула (желатин, загуститель, вода, пищевой краситель).</p>
                <p>Диетические особенности: без ГМО, без аспартама, без глютена, без лактозы</p>
            </>
        ),

        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В оригинальной упаковке. В защищенном от солнца помещении',
        gender: 'Женский, Мужской',
        images: [vitCarnitin11, vitCarnitin12, vitCarnitin13],
        img: vitCarnitin11,
        contraindications: <>
            <p>1. Индивидуальная непереносимость компонентов, входящих в состав</p>
            <p>2. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
            <p>3. Дети до 14 лет: Не рекомендуется детям младше 14 лет без консультации с врачом.</p>
        </>,
        contentMethod: (
            <>
                <p>Есть противопоказания, посоветуйтесь с врачом.</p>
                <p>Взрослым по 1 или 2 капсулы в день во время еды.</p>
                <p>Продолжительность приема - 6 месяцев.</p>мл
                <p>При необходимости прием можно повторить.</p>
            </>
        ),
    },
    'vitamins-melatonin': {
        title: 'Мелатонин для сна 3 мг успокоительное капсулы, снотворное',
        productType: 'Пищевая добавка. Капсулы.',
        description: (
            <>
                <p>Мелатонин в капсулах лучший комплекс для здорового и крепкого сна! Мелатонин важный гормон, он помогает справиться с бессонницей, способствует нормализации цикла сна и бодрствования, улучшает качество и продолжительность сна и общее состояние здоровья.</p>
                <p>Прием его даст возможность погрузиться в глубокий сон, действуя, как мягкое снотворное, что способствует восстановлению мышц после физических нагрузок, улучшает самочувствие после утреннего подъема, отдыхает мозг, нормально функционирует нервная система, пропадают тревожность и усталость. </p>
                <p>  Мелатонин помогает нормализовать сон людям которые вынуждены регулярно работать в ночное время, а также при смене часовых поясов.</p>
                <p> С возрастом выработка собственного мелатонина снижается поэтому его необходимо принимать, как витамины для сна.Melatonin поможет вам наладить нормальный, спокойный сон, вы будете просыпаться отдохнувшим, что положительно скажется на иммунитете и общем состоянии здоровья.</p>
            </>
        ),
        price: '900',
        taste: '',
        textPurpose: 'Успокоитльное; Улучшает сон; От стресса;',
        composition: (
            <>
                <p>Мальтодекстрин, сухой мед, экстракт ромашки, мелатонин, витамин В6, желатиновая капсула (желатин, загуститель, вода, пищевой краситель).</p>
            </>
        ),

        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        country: 'Россия',
        purposes: ['спокойствие', 'восстановление', 'красота и здоровье', 'лучшее для женщин', 'лучшее для мужчин'],
        expirationDate: '24',
        storageConditions: 'В оригинальной упаковке. В защищенном от солнца помещении',
        gender: 'Женский, Мужской',
        images: [vitMelatonin11, vitMelatonin12, vitMelatonin13],
        img: vitMelatonin11,
        contraindications: <>
            <p>1. Индивидуальная непереносимость компонентов, входящих в состав</p>
            <p>2. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
            <p>3. Дети до 18 лет: Не рекомендуется детям младше 18 лет без консультации с врачом.</p>
        </>,
        contentMethod: (
            <>
                <p>Есть противопоказания, посоветуйтесь с врачом.</p>
                <p>Взрослым по 2 капсулы в день за полчаса до сна.</p>
                <p>Продолжительность приема - 6 месяцев.</p>мл
                <p>При необходимости прием можно повторить.</p>
            </>
        ),
    },
}