// import boxberry from "../images/boxberry.png";
import detmir from "../images/detmir.png";
import ozon from "../images/ozon.png";
import megamarket from "../images/megamarket.png";
import yandex from "../images/yandex.png";
import wildberries from "../images/wildberries.png";

export const cardsMarketplaces = [
  { img: detmir, alt: "ссылка на маркплейс Детский мир", name: "Детский мир", url: 'https://www.detmir.ru/catalog/index/name/sortforbrand/brand/25643/', text: 'Зайдите в личный кабинет в раздел "Мои заказы". Выберите товар JAPAN FORMULA. Нажмите на кнопку "Оставить отзыв". Поставьте оценку, напишите комментарий. Дополните фото и/или видео. Отправьте отзыв.' },
  { img: ozon, alt: "ссылка на маркплейс Озон", name: "Озон", url: 'https://www.ozon.ru/brand/japan-formula-100079472/', text: 'Зайдите в личный кабинет в раздел "Заказы". Выберите товар JAPAN FORMULA. Нажмите на кнопку "Оценить товар"!!!! - отзыв о товаре, а не о качестве доставки. Откроется окно, где вы сможете рассказать о товаре, загрузить видео и/или фото. Нажмите кнопку "Отправить отзыв".' },
  {
    img: megamarket, alt: "ссылка на маркплейс МегаМаркет", name: "МегаМаркет", url: 'https://megamarket.ru/brands/japan-formula/', text: 'Зайдите в личный кабинет в раздел "Заказы". Выберите товар JAPAN FORMULA. Нажмите на кнопку "Оставить отзыв". Поставьте оценку, напишите комментарий. Дополните фото и/или видео. Отправьте отзыв.'
  },
  { img: yandex, alt: "ссылка на маркплейс ЯндексМаркет", name: "ЯндексМаркет", url: 'https://market.yandex.ru/brands--jp-formula/33930410?wprid=1719123739297737-8254556095759038213-balancer-l7leveler-kubr-yp-klg-62-BAL&utm_source_service=web&clid=703&src_pof=703&icookie=xXR9Fp85p%2ButCOWCDQNa8xQy8DzLKwz8%2B5CIQcNNSYaBsly89ZtOemXvf9wcGYwXIhQQlnr6iUjV4oiprG950oeEtZA%3D&baobab_event_id=lxr5wyzeau', text: 'Зайдите в личный кабинет в раздел "Заказы". Выберите товар JAPAN FORMULA. Поставьте оценку (количество звёзд). Откроется окно, где вы сможете рассказать о товаре, загрузить видео и/или фото. Нажмите кнопку "Отправить".' },
  { img: wildberries, alt: "ссылка на маркплейс wildberries", name: "Wildberries", url: 'https://www.wildberries.ru/brands/japan-formula', text: 'Зайдите в личный кабинет в раздел "Доставки", далее раздел "Покупки". Выберите товар JAPAN FORMULA. Поставьте оценку (количество звёзд). Откроется окно, где вы сможете рассказать о товаре, загрузить видео и/или фото. Нажмите кнопку "Отправить отзыв".' },
];


