import Collagen01 from "./imagesCollagen/Collagen01.webp";
import Collagen02 from "./imagesCollagen/Collagen02.webp";
import CollagenCh11 from "./imagesCollagen/CollagenCh11.webp";
import CollagenCh12 from "./imagesCollagen/CollagenCh12.webp";
import CollagenCherry11 from "./imagesCollagen/CollagenCherry11.webp";
import CollagenCherry12 from "./imagesCollagen/CollagenCherry12.webp";
import CollagenLychee11 from "./imagesCollagen/CollagenLychee11.webp";
import CollagenLychee12 from "./imagesCollagen/CollagenLychee12.webp";
import CollagenMelon11 from "./imagesCollagen/CollagenMelon11.webp";
import CollagenMelon12 from "./imagesCollagen/CollagenMelon12.webp";
import CollagenMango11 from "./imagesCollagen/CollagenMango11.webp";
import CollagenMango12 from "./imagesCollagen/CollagenMango12.webp";
import CollagenPineapple11 from "./imagesCollagen/CollagenPineapple11.webp";
import CollagenPineapple12 from "./imagesCollagen/CollagenPineapple12.webp";
import CollagenSC11 from "./imagesCollagen/CollagenSC11.webp";
import CollagenSC12 from "./imagesCollagen/CollagenSC12.webp";
import CollagenF11 from "./imagesCollagen/CollagenF11.webp";
import CollagenF12 from "./imagesCollagen/CollagenF12.webp";
import CollagenRB11 from "./imagesCollagen/CollagenRB11.webp";
import CollagenRB12 from "./imagesCollagen/CollagenRB12.webp";
import CollagenRR11 from "./imagesCollagen/CollagenRR11.webp";
import CollagenRR12 from "./imagesCollagen/CollagenRR12.webp";
import CollagenLatte11 from "./imagesCollagen/CollagenLatte11.webp";
import CollagenLatte12 from "./imagesCollagen/CollagenLatte12.webp";
import vitCollagenMarine11 from "./../Vitamins/imagesVitamins/vitCollagenMarine11.webp";
import vitCollagenMarine12 from "./../Vitamins/imagesVitamins/vitCollagenMarine12.webp";
import vitCollagenMarine13 from "./../Vitamins/imagesVitamins/vitCollagenMarine13.webp";
import vitCollagen11 from "./../Vitamins/imagesVitamins/vitCollagen11.webp";
import vitCollagen12 from "./../Vitamins/imagesVitamins/vitCollagen12.webp";
import vitCollagen13 from "./../Vitamins/imagesVitamins/vitCollagen13.webp";

export const collagenProducts = {
    'collagen-cherry': {
        title: 'Колаген Порошок "Вишня" для волос, кожи, ногтей и суставов Japan Formula',
        productType: 'Витаминный комплекс. Порошок.',
        description: (
            <>
                <p>Коллаген Japan Formula - инновационный продукт, изготовленный на основе современных японских ингредиентов. В его полезные свойства входит защита волос от внешних отрицательных воздействий, коллаген придает волосам блеск и делает их гуще, препятствует их выпадению, повышает прочность ногтевых пластин. Коллагеновые пептиды положительно сказываются на состоянии суставов и поддерживают их здоровое состояние. Также в полезные свойства коллагена Japan Formula входят улучшение пищеварительных процессов, снижение массы тела, повышается влажность кожного покрова, замедляются процессы старения. Вишня. </p>
            </>
        ),
        price: '1600',
        tasteEng: 'cherry',
        taste: 'Вишня',
        textPurpose: 'Улучшение пищеварительных процессов, снижение массы тела, повышается влажность кожного покрова, замедляются процессы старения',
        composition: 'коллаген, лимонная кислота, витамин е, ароматизатор, витамин в3, витамин с, витамин, декстроза, витаминный премикс, натуральный подсластитель сукралоза, краситель водорастворимый',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        water: <><p >Не растворять в горячей воде</p></>,
        purposes: ['здоровье кожи, волос и ногтей', 'восстановление', 'красота и здоровье', 'лучшее для женщин'],
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В защищенном от солнца помещении, В прохладном темном месте, В сухом прохладном месте',
        gender: 'Женский, Мужской',
        images: [CollagenCherry11, CollagenCherry12, Collagen01, Collagen02],
        img: CollagenCherry11,
        contraindications: <>
            <p>1. Аллергические реакции : Продукт содержит коллаген, лимонную кислоту и витаминный премикс, которые могут вызвать аллергические реакции у психических людей.</p>
            <p>2. Проблемы с пищеварением : Людям с хроническими заболеваниями желудочно-кишечного тракта следует употреблять с осторожностью.</p>
            <p>3. Беременные и кормящие женщины : Перед подачей продуктов рекомендуется проконсультироваться с врачом.</p>
            <p>4. Гипервитаминоз : Продукт содержит витаминный премикс, поэтому людям с гипервитаминозом следует воздержаться от употребления или проконсультироваться с врачом.</p>
            <p>5. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
            <p>6. Дети до 12 лет : Коллагеновый порошок не рекомендуется детям младше 12 лет без консультации врача.</p>
        </>,
        contentMethod: (
            <>
                <p> Принимать Коллаген "Вишня" от Japan Formula необходимо в разведенном виде - одну чайную ложку (5 гр) следует разводить в стакане с водой, йогуртом, молоком, кефиром, соевым молоком или любым типом молока на ваш вкус. </p>
            </>
        ),
    },
    'collagen-chocolate': {
        title: 'Колаген Порошок "Шоколад" для волос, кожи, ногтей и суставов Japan Formula',
        productType: 'Витаминный комплекс. Порошок.',
        description: (
            <>
                <p>Коллаген Japan Formula - инновационный продукт, изготовленный на основе современных японских ингредиентов. В его полезные свойства входит защита волос от внешних отрицательных воздействий, коллаген придает волосам блеск и делает их гуще, препятствует их выпадению, повышает прочность ногтевых пластин. Коллагеновые пептиды положительно сказываются на состоянии суставов и поддерживают их здоровое состояние. Также в полезные свойства коллагена Japan Formula входят улучшение пищеварительных процессов, снижение массы тела, повышается влажность кожного покрова, замедляются процессы старения. Шоколад. </p>
            </>
        ),
        tasteEng: 'chocolate',
        taste: 'Шоколад',
        price: '1900',
        textPurpose: 'Для здоровья кожи,волос,связок и суставов; Для здоровья соединительной ткани, кожи, волос; Для восстановления организма.',
        composition: 'коллаген, лимонная кислота, витамин е, ароматизатор, витамин в3, витамин с, витамин в1, витамин в2, витамин в6, декстроза, витаминный премикс, натуральный подсластитель сукралоза, витамин Д3 (холекальциферол), краситель водорастворимый',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        water: <><p >Не растворять в горячей воде</p></>,
        purposes: ['здоровье кожи, волос и ногтей', 'восстановление', 'красота и здоровье', 'лучшее для женщин'],
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В защищенном от солнца помещении, В прохладном темном месте, В сухом прохладном месте',
        gender: 'Женский, Мужской',
        images: [CollagenCh11, CollagenCh12, Collagen01, Collagen02],
        img: CollagenCh11,
        contraindications: <>
            <p>1. Аллергические реакции : Продукт содержит коллаген, лимонную кислоту и витаминный премикс, которые могут вызвать аллергические реакции у психических людей.</p>
            <p>2. Проблемы с пищеварением : Людям с хроническими заболеваниями желудочно-кишечного тракта следует употреблять с осторожностью.</p>
            <p>3. Беременные и кормящие женщины : Перед подачей продуктов рекомендуется проконсультироваться с врачом.</p>
            <p>4. Гипервитаминоз : Продукт содержит витаминный премикс, поэтому людям с гипервитаминозом следует воздержаться от употребления или проконсультироваться с врачом.</p>
            <p>5. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
            <p>6. Дети до 12 лет : Коллагеновый порошок не рекомендуется детям младше 12 лет без консультации врача.</p>
        </>,
        contentMethod: (
            <>
                <p> Принимать Коллаген "Шоколад" от Japan Formula необходимо в разведенном виде - одну чайную ложку (5 гр) следует разводить в стакане с водой, йогуртом, молоком, кефиром, соевым молоком или любым типом молока на ваш вкус. </p>
            </>
        ),
    },
    'collagen-lychee': {
        title: 'Колаген Порошок "Личи" для волос, кожи, ногтей и суставов Japan Formula',
        productType: 'Витаминный комплекс. Порошок.',
        description: (
            <>
                <p>Коллаген Japan Formula - инновационный продукт, изготовленный на основе современных японских ингредиентов. В его полезные свойства входит защита волос от внешних отрицательных воздействий, коллаген придает волосам блеск и делает их гуще, препятствует их выпадению, повышает прочность ногтевых пластин. Коллагеновые пептиды положительно сказываются на состоянии суставов и поддерживают их здоровое состояние. Также в полезные свойства коллагена Japan Formula входят улучшение пищеварительных процессов, снижение массы тела, повышается влажность кожного покрова, замедляются процессы старения.
                    Личи. </p>
            </>
        ),
        tasteEng: 'lychee',
        taste: 'Личи',
        price: '1900',
        textPurpose: 'восстановление организма, для волос и ногтей; Диетическая еда; Добавка для суставов и связок. БАД .',
        composition: 'коллаген, лимонная кислота, витамин е, ароматизатор, витамин в3, витамин с, витамин в1, витамин в2, витамин в6, декстроза, витаминный премикс, натуральный подсластитель сукралоза, витамин Д3 (холекальциферол), краситель водорастворимый',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        water: <><p >Не растворять в горячей воде</p></>,
        purposes: ['здоровье кожи, волос и ногтей', 'восстановление', 'красота и здоровье', 'лучшее для женщин'],
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В защищенном от солнца помещении, В прохладном темном месте, В сухом прохладном месте',
        gender: 'Женский, Мужской',
        images: [CollagenLychee11, CollagenLychee12, Collagen01, Collagen02],
        img: CollagenLychee11,
        contraindications: <>
            <p>1. Аллергические реакции : Продукт содержит коллаген, лимонную кислоту и витаминный премикс, которые могут вызвать аллергические реакции у психических людей.</p>
            <p>2. Проблемы с пищеварением : Людям с хроническими заболеваниями желудочно-кишечного тракта следует употреблять с осторожностью.</p>
            <p>3. Беременные и кормящие женщины : Перед подачей продуктов рекомендуется проконсультироваться с врачом.</p>
            <p>4. Гипервитаминоз : Продукт содержит витаминный премикс, поэтому людям с гипервитаминозом следует воздержаться от употребления или проконсультироваться с врачом.</p>
            <p>5. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
            <p>6. Дети до 12 лет : Коллагеновый порошок не рекомендуется детям младше 12 лет без консультации врача.</p>
        </>,
        contentMethod: (
            <>
                <p> Принимать Коллаген "Личи" от Japan Formula необходимо в разведенном виде - одну чайную ложку (5 гр) следует разводить в стакане с водой, йогуртом, молоком, кефиром, соевым молоком или любым типом молока на ваш вкус. </p>
            </>
        ),
    },
    'collagen-melon': {
        title: 'Колаген Порошок "Дыня" для волос, кожи, ногтей и суставов Japan Formula',
        productType: 'Витаминный комплекс. Порошок.',
        description: (
            <>
                <p>Коллаген Japan Formula - инновационный продукт, изготовленный на основе современных японских ингредиентов. В его полезные свойства входит защита волос от внешних отрицательных воздействий, коллаген придает волосам блеск и делает их гуще, препятствует их выпадению, повышает прочность ногтевых пластин. Коллагеновые пептиды положительно сказываются на состоянии суставов и поддерживают их здоровое состояние. Также в полезные свойства коллагена Japan Formula входят улучшение пищеварительных процессов, снижение массы тела, повышается влажность кожного покрова, замедляются процессы старения.мином С, для суставов, питьевой, гидролизованный, для кожи и волос. Дыня. </p>
            </>
        ),
        price: '1800',
        tasteEng: 'melon',
        taste: 'Дыня',
        textPurpose: 'Для здоровья кожи,волос,связок и суставов; Для здоровья соединительной ткани, кожи, волос; Для восстановления организма, для волос и ногтей; Диетическая еда; Добавка для суставов и связок. БАД.',
        composition: 'коллаген, лимонная кислота, витамин е, ароматизатор, витамин в3, витамин с, витамин в1, витамин в2, витамин в6, декстроза, витаминный премикс, натуральный подсластитель сукралоза, витамин Д3 (холекальциферол), краситель водорастворимый',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        water: <><p >Не растворять в горячей воде</p></>,
        purposes: ['здоровье кожи, волос и ногтей', 'восстановление', 'красота и здоровье', 'лучшее для женщин'],
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В защищенном от солнца помещении, В прохладном темном месте, В сухом прохладном месте',
        gender: 'Женский, Мужской',
        images: [CollagenMelon11, CollagenMelon12, Collagen01, Collagen02],
        img: CollagenMelon11,
        contraindications: <>
            <p>1. Аллергические реакции : Продукт содержит коллаген, лимонную кислоту и витаминный премикс, которые могут вызвать аллергические реакции у психических людей.</p>
            <p>2. Проблемы с пищеварением : Людям с хроническими заболеваниями желудочно-кишечного тракта следует употреблять с осторожностью.</p>
            <p>3. Беременные и кормящие женщины : Перед подачей продуктов рекомендуется проконсультироваться с врачом.</p>
            <p>4. Гипервитаминоз : Продукт содержит витаминный премикс, поэтому людям с гипервитаминозом следует воздержаться от употребления или проконсультироваться с врачом.</p>
            <p>5. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
            <p>6. Дети до 12 лет : Коллагеновый порошок не рекомендуется детям младше 12 лет без консультации врача.</p>
        </>,
        contentMethod: (
            <>
                <p> Принимать Коллаген "Дыня" от Japan Formula необходимо в разведенном виде - одну чайную ложку (5 гр) следует разводить в стакане с водой, йогуртом, молоком, кефиром, соевым молоком или любым типом молока на ваш вкус. </p>
            </>
        ),
    },
    'collagen-mango': {
        title: 'Колаген Порошок "Манго" для волос, кожи, ногтей и суставов Japan Formula',
        productType: 'Витаминный комплекс. Порошок.',
        description: (
            <>
                <p>Коллаген Japan Formula - инновационный продукт, изготовленный на основе современных японских ингредиентов. В его полезные свойства входит защита волос от внешних отрицательных воздействий, коллаген придает волосам блеск и делает их гуще, препятствует их выпадению, повышает прочность ногтевых пластин. Коллагеновые пептиды положительно сказываются на состоянии суставов и поддерживают их здоровое состояние. Также в полезные свойства коллагена Japan Formula входят улучшение пищеварительных процессов, снижение массы тела, повышается влажность кожного покрова, замедляются процессы старения. Манго. </p>
            </>
        ),
        tasteEng: 'mango',
        taste: 'Манго',
        price: '1700',
        textPurpose: 'для похудения, поддержания диеты и утоления голода; БАД',
        composition: 'коллаген, лимонная кислота, витамин е, ароматизатор, витамин в3, витамин с, витамин в1, витамин в2, витамин в6, декстроза, витаминный премикс, натуральный подсластитель сукралоза, витамин Д3 (холекальциферол), краситель водорастворимый',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        water: <><p >Не растворять в горячей воде</p></>,
        purposes: ['здоровье кожи, волос и ногтей', 'восстановление', 'красота и здоровье', 'лучшее для женщин'],
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В защищенном от солнца помещении, В прохладном темном месте, В сухом прохладном месте',
        gender: 'Женский, Мужской',
        images: [CollagenMango11, CollagenMango12, Collagen01, Collagen02],
        img: CollagenMango11,
        contraindications: <>
            <p>1. Аллергические реакции : Продукт содержит коллаген, лимонную кислоту и витаминный премикс, которые могут вызвать аллергические реакции у психических людей.</p>
            <p>2. Проблемы с пищеварением : Людям с хроническими заболеваниями желудочно-кишечного тракта следует употреблять с осторожностью.</p>
            <p>3. Беременные и кормящие женщины : Перед подачей продуктов рекомендуется проконсультироваться с врачом.</p>
            <p>4. Гипервитаминоз : Продукт содержит витаминный премикс, поэтому людям с гипервитаминозом следует воздержаться от употребления или проконсультироваться с врачом.</p>
            <p>5. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
            <p>6. Дети до 12 лет : Коллагеновый порошок не рекомендуется детям младше 12 лет без консультации врача.</p>
        </>,
        contentMethod: (
            <>
                <p> Принимать Коллаген "Манго" от Japan Formula необходимо в разведенном виде - одну чайную ложку (5 гр) следует разводить в стакане с водой, йогуртом, молоком, кефиром, соевым молоком или любым типом молока на ваш вкус. </p>
            </>
        ),
    },
    'collagen-mocha-frappuccino': {
        title: 'Колаген Порошок "Фраппучино Мока" для волос, кожи, ногтей и суставов Japan Formula',
        productType: 'Витаминный комплекс. Порошок.',
        description: (
            <>
                <p>Коллаген Japan Formula - инновационный продукт, изготовленный на основе современных японских ингредиентов. В его полезные свойства входит защита волос от внешних отрицательных воздействий, коллаген придает волосам блеск и делает их гуще, препятствует их выпадению, повышает прочность ногтевых пластин. Коллагеновые пептиды положительно сказываются на состоянии суставов и поддерживают их здоровое состояние. Также в полезные свойства коллагена Japan Formula входят улучшение пищеварительных процессов, снижение массы тела, повышается влажность кожного покрова, замедляются процессы старения. Фраппучино Мока. </p>
            </>
        ),
        tasteEng: 'FRAPPUCCINO MOKA',
        taste: 'Фраппучино Мока',
        price: '1800',
        textPurpose: 'для здоровья кожи,волос,связок и суставов; для здоровья соединительной ткани, кожи, волос; для восстановления организма, для волос и ногтей; диетическая еда; добавка для суставов и связок; БАД',
        composition: 'коллаген, лимонная кислота, витамин е, ароматизатор, витамин в3, витамин с, витамин в1, витамин в2, витамин в6, декстроза, витаминный премикс, натуральный подсластитель сукралоза, витамин Д3 (холекальциферол), краситель водорастворимый',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        water: <><p >Не растворять в горячей воде</p></>,
        purposes: ['здоровье кожи, волос и ногтей', 'восстановление', 'красота и здоровье', 'лучшее для женщин'],
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В защищенном от солнца помещении, В прохладном темном месте, В сухом месте, В сухом прохладном месте, При температуре до +30`C',
        gender: 'Женский, Мужской',
        images: [CollagenF11, CollagenF12, Collagen01, Collagen02],
        img: CollagenF11,
        contraindications: <>
            <p>1. Аллергические реакции : Продукт содержит коллаген, лимонную кислоту и витаминный премикс, которые могут вызвать аллергические реакции у психических людей.</p>
            <p>2. Проблемы с пищеварением : Людям с хроническими заболеваниями желудочно-кишечного тракта следует употреблять с осторожностью.</p>
            <p>3. Беременные и кормящие женщины : Перед подачей продуктов рекомендуется проконсультироваться с врачом.</p>
            <p>4. Гипервитаминоз : Продукт содержит витаминный премикс, поэтому людям с гипервитаминозом следует воздержаться от употребления или проконсультироваться с врачом.</p>
            <p>5. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
            <p>6. Дети до 12 лет : Коллагеновый порошок не рекомендуется детям младше 12 лет без консультации врача.</p>
        </>,
        contentMethod: (
            <>
                <p> Принимать Коллаген "Фраппучино Мока" от Japan Formula необходимо в разведенном виде - одну чайную ложку (5 гр) следует разводить в стакане с водой, йогуртом, молоком, кефиром, соевым молоком или любым типом молока на ваш вкус. </p>
            </>
        ),
    },
    'collagen-pineapple': {
        title: 'Колаген Порошок "Ананас" для волос, кожи, ногтей и суставов Japan Formula',
        productType: 'Витаминный комплекс. Порошок.',
        description: (
            <>
                <p>Коллаген Japan Formula - инновационный продукт, изготовленный на основе современных японских ингредиентов. В его полезные свойства входит защита волос от внешних отрицательных воздействий, коллаген придает волосам блеск и делает их гуще, препятствует их выпадению, повышает прочность ногтевых пластин. Коллагеновые пептиды положительно сказываются на состоянии суставов и поддерживают их здоровое состояние. Также в полезные свойства коллагена Japan Formula входят улучшение пищеварительных процессов, снижение массы тела, повышается влажность кожного покрова, замедляются процессы старения. Ананас. </p>
            </>
        ),
        tasteEng: 'pineapple',
        taste: 'Ананас',
        price: '1800',
        textPurpose: 'для похудения, поддержания диеты и утоления голода; БАД',
        composition: 'коллаген, лимонная кислота, витамин е, ароматизатор, витамин в3, витамин с, витамин в1, витамин в2, витамин в6, декстроза, витаминный премикс, натуральный подсластитель сукралоза, витамин Д3 (холекальциферол), краситель водорастворимый',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        water: <><p >Не растворять в горячей воде</p></>,
        purposes: ['здоровье кожи, волос и ногтей', 'восстановление', 'красота и здоровье', 'лучшее для женщин'],
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В защищенном от солнца помещении, В прохладном темном месте, В сухом прохладном месте',
        gender: 'Женский, Мужской',
        images: [CollagenPineapple11, CollagenPineapple12, Collagen01, Collagen02],
        img: CollagenPineapple11,
        contraindications: <>
            <p>1. Аллергические реакции : Продукт содержит коллаген, лимонную кислоту и витаминный премикс, которые могут вызвать аллергические реакции у психических людей.</p>
            <p>2. Проблемы с пищеварением : Людям с хроническими заболеваниями желудочно-кишечного тракта следует употреблять с осторожностью.</p>
            <p>3. Беременные и кормящие женщины : Перед подачей продуктов рекомендуется проконсультироваться с врачом.</p>
            <p>4. Гипервитаминоз : Продукт содержит витаминный премикс, поэтому людям с гипервитаминозом следует воздержаться от употребления или проконсультироваться с врачом.</p>
            <p>5. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
            <p>6. Дети до 12 лет : Коллагеновый порошок не рекомендуется детям младше 12 лет без консультации врача.</p>
        </>,
        contentMethod: (
            <>
                <p> Принимать Коллаген "Ананас" от Japan Formula необходимо в разведенном виде - одну чайную ложку (5 гр) следует разводить в стакане с водой, йогуртом, молоком, кефиром, соевым молоком или любым типом молока на ваш вкус. </p>

            </>
        ),
    },
    'collagen-strawberries-cream': {
        title: 'Колаген Порошок "Клубника-Сливки" для волос, кожи, ногтей и суставов Japan Formula',
        productType: 'Витаминный комплекс. Порошок.',
        description: (
            <>
                <p>Коллаген Japan Formula - инновационный продукт, изготовленный на основе современных японских ингредиентов. В его полезные свойства входит защита волос от внешних отрицательных воздействий, коллаген придает волосам блеск и делает их гуще, препятствует их выпадению, повышает прочность ногтевых пластин. Коллагеновые пептиды положительно сказываются на состоянии суставов и поддерживают их здоровое состояние. Также в полезные свойства коллагена Japan Formula входят улучшение пищеварительных процессов, снижение массы тела, повышается влажность кожного покрова, замедляются процессы старения. Клубника-Сливки. </p>
            </>
        ),
        price: '1700',
        tasteEng: 'strawberry',
        taste: 'Клубника-Сливки',
        textPurpose: 'восстановление суставов, для улучшения состояния волос, для улучшения состояния кожи, укрепление костей, укрепление ногтей и волос; БАД',
        composition: 'коллаген, лимонная кислота, витамин е, ароматизатор, витамин в3, витамин с, витамин, декстроза, витаминный премикс, натуральный',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        water: <><p >Не растворять в горячей воде</p></>,
        purposes: ['здоровье кожи, волос и ногтей', 'восстановление', 'красота и здоровье', 'лучшее для женщин'],
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В защищенном от солнца помещении, В прохладном темном месте, В сухом прохладном месте',
        gender: 'Женский, Мужской',
        images: [CollagenSC11, CollagenSC12, Collagen01, Collagen02],
        img: CollagenSC11,
        contraindications: <>
            <p>1. Аллергические реакции : Продукт содержит коллаген, лимонную кислоту и витаминный премикс, которые могут вызвать аллергические реакции у психических людей.</p>
            <p>2. Проблемы с пищеварением : Людям с хроническими заболеваниями желудочно-кишечного тракта следует употреблять с осторожностью.</p>
            <p>3. Беременные и кормящие женщины : Перед подачей продуктов рекомендуется проконсультироваться с врачом.</p>
            <p>4. Гипервитаминоз : Продукт содержит витаминный премикс, поэтому людям с гипервитаминозом следует воздержаться от употребления или проконсультироваться с врачом.</p>
            <p>5. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
            <p>6. Дети до 12 лет : Коллагеновый порошок не рекомендуется детям младше 12 лет без консультации врача.</p>
        </>,
        contentMethod: (
            <>
                <p> Принимать Коллаген "Клубника-Сливки" от Japan Formula необходимо в разведенном виде - одну чайную ложку (5 гр) следует разводить в стакане с водой, йогуртом, молоком, кефиром, соевым молоком или любым типом молока на ваш вкус. </p>

            </>
        ),
    },
    'collagen-raspberries': {
        title: 'Колаген Порошок "Малина" для волос, кожи, ногтей и суставов Japan Formula',
        productType: 'Витаминный комплекс. Порошок.',
        description: (
            <>
                <p>Коллаген Japan Formula - инновационный продукт, изготовленный на основе современных японских ингредиентов. В его полезные свойства входит защита волос от внешних отрицательных воздействий, коллаген придает волосам блеск и делает их гуще, препятствует их выпадению, повышает прочность ногтевых пластин. Коллагеновые пептиды положительно сказываются на состоянии суставов и поддерживают их здоровое состояние. Также в полезные свойства коллагена Japan Formula входят улучшение пищеварительных процессов, снижение массы тела, повышается влажность кожного покрова, замедляются процессы старения. Малина. </p>
            </>
        ),
        tasteEng: 'raspberry',
        taste: 'Малина',
        price: '1700',
        textPurpose: 'восстановление суставов, для улучшения состояния волос, для улучшения состояния кожи, укрепление костей, укрепление ногтей и волос; БАД',
        composition: 'коллаген, лимонная кислота, витамин е, витамин в3, витамин с, витамин в1, витамин в2, витамин в6, декстроза, ароматизаторы, витаминный премикс, натуральный подсластитель сукралоза, краситель водорастворимый, Витамин Д3',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        water: <><p >Не растворять в горячей воде</p></>,
        purposes: ['здоровье кожи, волос и ногтей', 'восстановление', 'красота и здоровье', 'лучшее для женщин'],
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В защищенном от солнца помещении, В прохладном темном месте, В сухом прохладном месте',
        gender: 'Женский, Мужской',
        images: [CollagenRB11, CollagenRB12, Collagen01, Collagen02],
        img: CollagenRB11,
        contraindications: <>
            <p>1. Аллергические реакции : Продукт содержит коллаген, лимонную кислоту и витаминный премикс, которые могут вызвать аллергические реакции у психических людей.</p>
            <p>2. Проблемы с пищеварением : Людям с хроническими заболеваниями желудочно-кишечного тракта следует употреблять с осторожностью.</p>
            <p>3. Беременные и кормящие женщины : Перед подачей продуктов рекомендуется проконсультироваться с врачом.</p>
            <p>4. Гипервитаминоз : Продукт содержит витаминный премикс, поэтому людям с гипервитаминозом следует воздержаться от употребления или проконсультироваться с врачом.</p>
            <p>5. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
            <p>6. Дети до 12 лет : Коллагеновый порошок не рекомендуется детям младше 12 лет без консультации врача.</p>
        </>,
        contentMethod: (
            <>
                <p> Принимать Коллаген "Малина" от Japan Formula необходимо в разведенном виде - одну чайную ложку (5 гр) следует разводить в стакане с водой, йогуртом, молоком, кефиром, соевым молоком или любым типом молока на ваш вкус. </p>
            </>
        ),
    },
    'collagen-rocky-road': {
        title: 'Колаген Порошок "Печенье Роки Роад" для волос, кожи, ногтей и суставов Japan Formula',
        productType: 'Витаминный комплекс. Порошок.',
        description: (
            <>
                <p>Коллаген Japan Formula - инновационный продукт, изготовленный на основе современных японских ингредиентов. В его полезные свойства входит защита волос от внешних отрицательных воздействий, коллаген придает волосам блеск и делает их гуще, препятствует их выпадению, повышает прочность ногтевых пластин. Коллагеновые пептиды положительно сказываются на состоянии суставов и поддерживают их здоровое состояние. Также в полезные свойства коллагена Japan Formula входят улучшение пищеварительных процессов, снижение массы тела, повышается влажность кожного покрова, замедляются процессы старения. Печенье Роки Роад. </p>
            </>
        ),
        tasteEng: 'rocky road',
        taste: 'Печенье Роки Роад',
        price: '1900',
        textPurpose: 'восстановление суставов, для улучшения состояния волос, для улучшения состояния кожи, укрепление костей, укрепление ногтей и волос; БАД',
        composition: 'коллаген, лимонная кислота, витамин е, витамин в3, витамин с, витамин в1, витамин в2, витамин в6, декстроза, ароматизаторы, витаминный премикс, натуральный подсластитель сукралоза, краситель водорастворимый, Витамин Д3',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        water: <><p >Не растворять в горячей воде</p></>,
        purposes: ['здоровье кожи, волос и ногтей', 'восстановление', 'красота и здоровье', 'лучшее для женщин'],
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В защищенном от солнца помещении, В прохладном темном месте, В сухом прохладном месте',
        gender: 'Женский, Мужской',
        images: [CollagenRR11, CollagenRR12, Collagen01, Collagen02],
        img: CollagenRR11,
        contraindications: <>
            <p>1. Аллергические реакции : Продукт содержит коллаген, лимонную кислоту и витаминный премикс, которые могут вызвать аллергические реакции у психических людей.</p>
            <p>2. Проблемы с пищеварением : Людям с хроническими заболеваниями желудочно-кишечного тракта следует употреблять с осторожностью.</p>
            <p>3. Беременные и кормящие женщины : Перед подачей продуктов рекомендуется проконсультироваться с врачом.</p>
            <p>4. Гипервитаминоз : Продукт содержит витаминный премикс, поэтому людям с гипервитаминозом следует воздержаться от употребления или проконсультироваться с врачом.</p>
            <p>5. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
            <p>6. Дети до 12 лет : Коллагеновый порошок не рекомендуется детям младше 12 лет без консультации врача.</p>
        </>,
        contentMethod: (
            <>
                <p> Принимать Коллаген "Печенье Роки Роад" от Japan Formula необходимо в разведенном виде - одну чайную ложку (5 гр) следует разводить в стакане с водой, йогуртом, молоком, кефиром, соевым молоком или любым типом молока на ваш вкус. </p>

            </>
        ),
    },
    'collagen-matcha': {
        title: 'Колаген Порошок "Матча" для волос, кожи, ногтей и суставов Japan Formula',
        productType: 'Витаминный комплекс. Порошок.',
        description: (
            <>
                <p>Коллаген Japan Formula - инновационный продукт, изготовленный на основе современных японских ингредиентов. В его полезные свойства входит защита волос от внешних отрицательных воздействий, коллаген придает волосам блеск и делает их гуще, препятствует их выпадению, повышает прочность ногтевых пластин. Коллагеновые пептиды положительно сказываются на состоянии суставов и поддерживают их здоровое состояние. Также в полезные свойства коллагена Japan Formula входят улучшение пищеварительных процессов, снижение массы тела, повышается влажность кожного покрова, замедляются процессы старения.
                    Матча. Без вкуса.</p>
            </>
        ),
        tasteEng: 'matcha',
        taste: 'Матча',
        price: '1700',
        textPurpose: 'восстановление суставов, для улучшения состояния волос, для улучшения состояния кожи, укрепление костей, укрепление ногтей и волос; БАД',
        composition: 'коллаген, лимонная кислота, витамин е, витамин в3, витамин с, витамин в1, витамин в2, витамин в6, декстроза, ароматизаторы, натуральный подсластитель сукралоза, краситель водорастворимый, Витамин Д3',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        water: <><p >Не растворять в горячей воде</p></>,
        purposes: ['здоровье кожи, волос и ногтей', 'восстановление', 'красота и здоровье', 'лучшее для женщин'],
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В защищенном от солнца помещении, В прохладном темном месте, В сухом прохладном месте',
        gender: 'Женский, Мужской',
        images: [CollagenLatte11, CollagenLatte12, Collagen01, Collagen02],
        img: CollagenLatte11,
        contraindications: <>
            <p>1. Аллергические реакции : Продукт содержит коллаген, лимонную кислоту и витаминный премикс, которые могут вызвать аллергические реакции у психических людей.</p>
            <p>2. Проблемы с пищеварением : Людям с хроническими заболеваниями желудочно-кишечного тракта следует употреблять с осторожностью.</p>
            <p>3. Беременные и кормящие женщины : Перед подачей продуктов рекомендуется проконсультироваться с врачом.</p>
            <p>4. Гипервитаминоз : Продукт содержит витаминный премикс, поэтому людям с гипервитаминозом следует воздержаться от употребления или проконсультироваться с врачом.</p>
            <p>5. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
            <p>6. Дети до 12 лет : Коллагеновый порошок не рекомендуется детям младше 12 лет без консультации врача.</p>
        </>,
        contentMethod: (
            <>
                <p> Принимать Коллаген "Матча Латте" от Japan Formula необходимо в разведенном виде - одну чайную ложку (5 гр) следует разводить в стакане с водой, йогуртом, молоком, кефиром, соевым молоком или любым типом молока на ваш вкус. </p>

            </>
        ),
    },
    'collagen-collagen-marine': {
        title: 'Коллаген морской в капсулах c витамином C, питьевой collagen С',
        productType: 'Пищевая добавка. Капсулы.',
        description: (
            <>
                <p>Japan Formula - это бренд, который специализируется на производстве высококачественного коллагена в капсулах с витамином С. Коллаген в капсулах - это удобный для приема продукт, который можно пить как обычную воду.</p>
                <p>Коллаген - это белок, который является основным строительным материалом кожи, суставов, костей и других тканей. Он также играет важную роль в поддержании здоровья волос, ногтей и зубов. Недостаток коллагена может привести к различным заболеваниям и проблемам со здоровьем.</p>
                <p> Преимущества употребления коллагена с витамином С от Japan Formula:</p>
                <p> - Улучшение состояния кожи, волос и ногтейУлучшает работу нервной системы.</p>
                <p>- Поддержка здоровья суставов и костей</p>
                <p> - Профилактика заболеваний и проблем со здоровьем.</p>
                <p> Japan Formula использует только высококачественный морской коллаген, который обладает всеми полезными свойствами натурального коллагена. Продукт также содержит витамин С, который помогает улучшить усвоение коллагена и повышает его эффективность.</p>
            </>
        ),
        taste: '',
        price: '1300',
        textPurpose: 'Восстановление, Красота и здоровье, Профилактика',
        composition: (
            <>
                <p>Основной ингредиент: рыбий коллаген. Кукурузное водорастворимое волокно, карбонат кальция, Витамин С, изомальтоолигосахарид, гиалуроновая кислота, тальк, гидроксипропилметилцеллюлоза, диоксид кремния, витамин В3, витамин Е.</p>
                <p>Диетические особенности: без ГМО, без аспартама, без глюкозы, без глютена, без лактозы, без сукралозы, веганский</p>
            </>
        ),

        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        purposes: ['здоровье кожи, волос и ногтей', 'восстановление', 'красота и здоровье', 'лучшее для женщин'],
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В оригинальной упаковке. В защищенном от солнца помещении',
        gender: 'Женский, Мужской',
        images: [vitCollagenMarine11, vitCollagenMarine12, vitCollagenMarine13],
        img: vitCollagenMarine11,
        contraindications: <>
            <p>1. Индивидуальная непереносимость компонентов, входящих в состав</p>
            <p>2. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
            <p>3. Дети до 12 лет: Не рекомендуется детям младше 12 лет без консультации с врачом.</p>
        </>,
        contentMethod: (
            <>
                <p>Есть противопоказания, посоветуйтесь с врачом.</p>
                <p>Взрослым по 1 капсуле 3 раза в день во время еды.</p>
                <p>Продолжительность приема - 6 месяцев.</p>мл
                <p>При необходимости прием можно повторить.</p>

            </>
        ),
    },
    'collagen-collagen-natur': {
        title: 'Коллаген в капсулах с витамином С, collagen для суставов 1 и 3 тип',
        productType: 'Пищевая добавка. Капсулы.',
        description: (
            <>
                <p>Коллаген – это белок, который является основным компонентом соединительной ткани и необходим для поддержания здоровья суставов. Он помогает укрепить суставы, улучшить их гибкость и снизить риск развития остеоартрита.</p>
                <p>Japan Formula предлагает коллаген в капсулах, который содержит витамин С, который помогает улучшить усвоение коллагена организмом. Коллаген первого типа является основным белком соединительной ткани, а коллаген третьего типа способствует укреплению суставов и связок.</p>
                <p> Преимущества коллагена в капсулах:</p>
                <p>- Укрепление суставов и связок</p>
                <p>- Укрепление суставов и связок</p>
                <p>- Снижение риска развития остеоартрита</p>
                <p>- Защита от повреждений суставов при физических нагрузках</p>
                <p>- Поддержка здоровья кожи, волос и ногтей</p>
                <p>Коллаген в капсулах от Japan Formula – это надежный и эффективный способ укрепить здоровье суставов и получить все преимущества этого белка. Попробуйте и убедитесь сами!</p>
            </>
        ),
        taste: '',
        price: '1300',
        textPurpose: 'Восстановление, Красота и здоровье, Профилактика',
        composition: (
            <>
                <p>Основной ингредиент: коллаген говяжий. Декстрин кукурузный, мальтодекстрин, соевая клетчатка, сухое молоко 1.5%, кукурузный крахмал, комплекс витаминов ( витамин С, витамин D3, витамин В5, витамин В6, витамин В2, витамин В1, витамин Е, витамин В9).</p>
                <p>Диетические особенности: без ГМО, без аспартама, без глюкозы, без глютена, без лактозы, без сукралозы</p>
            </>
        ),

        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        purposes: ['здоровье кожи, волос и ногтей', 'восстановление', 'красота и здоровье', 'лучшее для женщин'],
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В оригинальной упаковке. В защищенном от солнца помещении',
        gender: 'Женский, Мужской',
        images: [vitCollagen11, vitCollagen12, vitCollagen13],
        img: vitCollagen11,
        contraindications: <>
            <p>1. Индивидуальная непереносимость компонентов, входящих в состав</p>
            <p>2. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
            <p>3. Дети до 12 лет: Не рекомендуется детям младше 12 лет без консультации с врачом.</p>
        </>,
        contentMethod: (
            <>
                <p>Есть противопоказания, посоветуйтесь с врачом.</p>
                <p>Взрослым по 1 капсуле 2 раза в день во время еды.</p>
                <p>Продолжительность приема - 6 месяцев.</p>мл
                <p>При необходимости прием можно повторить.</p>

            </>
        ),
    },
}
