import React from "react";
import { useParams } from "react-router-dom";
import PageHelmet from "../PageHelmet"; 
import { articlesData } from "../../utils/articlesData";

import "./Article.css";

const Article = ({ headerHeight, handleGoBack }) => {
  const { articleId } = useParams();
  const article = articlesData[articleId];

  if (!article) {
    return <div>Статья не найдена</div>;
  }

  return (
    <div className="article" style={{ paddingTop: `${headerHeight}px` }}>
      <PageHelmet
        title={article.title} 
        description={article.text} 
        keywords="здоровье, питание, фитнес, коллаген, японские традиции, правильное питание, советы по здоровью"
        image={article.img}
        canonicalUrl={`https://japanformula.com/scientific-center/${articleId}/`}
      />
      <div className="article__wrapper">
        <button
          className="article__button-main privacy-policy__button"
          type="button"
          onClick={handleGoBack}
        >
          НАЗАД К СТАТЬЯМ
        </button>
        <img className="article__img" src={article.img} alt={article.title} />
        <h1 className="article__title">{article.title}</h1>
        <div className="article__text">{article.content}</div>
        <button
          className="article__button-main privacy-policy__button"
          type="button"
          onClick={handleGoBack}
        >
          НАЗАД К СТАТЬЯМ
        </button>
      </div>
    </div>
  );
};

export default Article;
