import React from "react";
import "./SakuraPetal.css";
import sakuraFlower from "../../images/sakuraFlower.png";
import petal from "../../images/petal.png";

const SakuraPetal = () => {
  const petalCount = 50;
  const petals = Array.from({ length: petalCount });

  return (
    <div className="sakura-container">
      {petals.map((_, index) => (
        <div
          key={index}
          className="sakura-petal"
          style={{
            "--random-x": Math.random(),
            "--random-duration": `${Math.random() * 20 + 10}s`, // Скорость анимации
            "--random-delay": `${Math.random() * 2}s`, // Уменьшение задержки
            top: `${Math.random() * 100}vh`, // Случайная начальная вертикальная позиция
          }}
        >
          <img src={petal} alt="логотип сайта Japan Formula" />
        </div>
      ))}

      {petals.map((_, index) => (
        <div
          key={index}
          className="sakura-petal-img"
          style={{
            "--random-x": Math.random(),
            "--random-duration": `${Math.random() * 20 + 10}s`,
            "--random-delay": `${Math.random() * 2}s`,
            top: `${Math.random() * 100}vh`, // Случайная начальная вертикальная позиция
          }}
        >
          <img src={sakuraFlower} alt="логотип сайта Japan Formula" />
        </div>
      ))}
    </div>
  );
};

export default SakuraPetal;
