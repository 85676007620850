import React, { useRef, useState, useEffect } from "react";
import "./VideoPlayer.css";
import videoMain from "../../video/videoMain.mp4";
import MainImage from "../../images/MainFon.png"; 

const VideoPlayer = () => {
  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true);
  const [volume, setVolume] = useState(1); // Начальная громкость
  const [isPlaying, setIsPlaying] = useState(true);
  const [videoError, setVideoError] = useState(false);

  const toggleMute = () => {
    setIsMuted(!isMuted);
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
    }
  };

  const handleVolumeChange = (e) => {
    const newVolume = e.target.value;
    setVolume(newVolume);
    if (videoRef.current) {
      videoRef.current.volume = newVolume;
    }
  };

  const togglePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleVideoError = () => {
    setVideoError(true);
  };

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement) {
      videoElement.muted = true; // видео начинается без звука
      videoElement.play().catch((error) => {
        console.error("Error trying to play video:", error);
        setVideoError(true);
      });

      // Обработчик ошибки загрузки видео
      videoElement.addEventListener("error", handleVideoError);

      return () => {
        if (videoElement) {
          videoElement.removeEventListener("error", handleVideoError);
        }
      };
    }
  }, []);

  return (
    <div className="video-container">
      {videoError ? (
        <img
          src={MainImage}
          alt="Горы в Японии"
          className="fallback-image"
        //   onError={handleVideoError} 
        />
      ) : (
        <video
          width="600"
          height="400"
          loop
          ref={videoRef}
          controlsList="nodownload"
          playsInline
        >
          <source src={videoMain} type="video/mp4"  onError={handleVideoError} />          
          Ваш браузер не поддерживает данное видео.
        </video>
      )}
      <div className="controls">
        <button onClick={toggleMute} className="mute-button">
          {isMuted ? "включить звук" : "выключить звук"}
        </button>
        {!isMuted && (
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={volume}
            onChange={handleVolumeChange}
            className="volume-slider"
          />
        )}
        <button onClick={togglePlayPause} className="play-pause-button">
          {isPlaying ? "пауза" : "смотреть"}
        </button>
      </div>
    </div>
  );
};

export default VideoPlayer;
