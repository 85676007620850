import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./SlideTableTopic.css";
import SakuraPetal from "../SakuraPetal/SakuraPetal";
import Fon10 from "../../images/Fon10.png";

import { cardsData } from "../../utils/cardsData";

const SlideTableTopic = ({
  headerHeight,
  handleGoBack,
  handleGoStore,
}) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [cardsPerPage, setCardsPerPage] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleLoadMore = () => {
    if (cardsPerPage < cardsData.length) {
      if (screenWidth >= 1160) {
        setCardsPerPage((prevCardsPerPage) => prevCardsPerPage + 4);
      } else if (screenWidth >= 865) {
        setCardsPerPage((prevCardsPerPage) => prevCardsPerPage + 3);
      } else {
        setCardsPerPage((prevCardsPerPage) => prevCardsPerPage + 2);
      }
    }
  };

  useEffect(() => {
    if (screenWidth >= 1280) {
      setCardsPerPage(9);
    } else if (screenWidth >= 769) {
      setCardsPerPage(6);
    } else {
      setCardsPerPage(5);
    }
  }, [screenWidth]);

  const handleSlideClick = (purpose) => {
    navigate(`/products-by-purpose/${purpose}`);
  };

  return (
    <section
      className="slide-table"
      id="slide-table"
      style={{ paddingTop: `${headerHeight}px` }}
    >
      <div className="slide-table__image-container">
        <img className="slide-table__img" src={Fon10} alt="Горы в Японии" />
        <SakuraPetal />
      </div>
      <div className="slide-table__block-info">
        <div className="about-us__button-block">
          <button
            className="about-us__button-main privacy-policy__button"
            type="button"
            onClick={handleGoBack}
          >
            НАЗАД
          </button>
          <button
            className="about-us__button-main privacy-policy__button"
            type="button"
            onClick={handleGoStore}
          >
            Где купить
          </button>
        </div>
        <h1 className="slide-table__title">
          Поставьте цель, и мы поможем её достичь
        </h1>
        <h3 className="slide-table__name"> Продукты для достижения твоих задач</h3>
      </div>
      <ul className="slide-table__wrapper">
        {cardsData.slice(0, cardsPerPage).map((data, index) => (
          <li key={index} className="slide-table__slide">
            <img
              className="slide-table__slide-img"
              src={data.img}
              alt={data.alt}
              onClick={() => handleSlideClick(data.purpose)}
            />
          </li>
        ))}
      </ul>
      {cardsPerPage < cardsData.length && (
        <button
          className="slide-table__button"
          type="button"
          onClick={handleLoadMore}
        >
          Ещё
        </button>
      )}
    </section>
  );
};

export default SlideTableTopic;
