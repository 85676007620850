import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import PageHelmet from "../PageHelmet";

import { articlesProducts } from "../../utils/articlesProducts";
import { proteinBarsProducts } from "./../ProteinBars/proteinBarsProducts.js";
import { proteinShakesProducts } from "./../ProteinShakes/proteinShakesProducts.js";
import { collagenProducts } from "./../Collagen/collagenProducts.js";
import { fatBurnersProducts } from "./../FatBurners/fatBurnersProducts.js";
import { detoxProducts } from "./../Detox/detoxProducts.js";
import { vitaminsProducts } from "./../Vitamins/vitaminsProducts.js";
import "./ArticleProducts.css";

const productDatabases = {
  "protein-bars": proteinBarsProducts,
  "protein-shakes": proteinShakesProducts,
  collagen: collagenProducts,
  "fat-burners": fatBurnersProducts,
  detox: detoxProducts,
  vitamins: vitaminsProducts,
};

const ArticleProducts = ({ headerHeight, handleGoBack }) => {
  const { productId } = useParams();
  const article = articlesProducts[productId];
  const [products, setProducts] = useState({});

  useEffect(() => {
    if (productId && productDatabases[productId]) {
      setProducts(productDatabases[productId]);
    }
  }, [productId]);

  if (!article) {
    return <div>Информация не найдена</div>;
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div
      className="article-products"
      style={{ paddingTop: `${headerHeight}px` }}
    >
      <PageHelmet
        title={`${article.title} от Japan Formula - Высокое качество и вкус`}
        description={`Откройте для себя ${article.text} Высокое качество, отличный вкус и полезные ингредиенты для вашего здоровья и фитнеса от Japan Formula.`}
        keywords="Коллаген, жиросжигатель, детокс, протеиновый батончик, витамины, протеин"
        image={article.img}
        canonicalUrl="https://japanformula.com/products/"
      />
      <div className="article-products__wrapper">
        <button
          className="article-products__button-main privacy-policy__button"
          type="button"
          onClick={handleGoBack}
        >
          ВСЕ ПРОДУКТЫ
        </button>
        <div className="article-products__block-text">
          <h1 className="article-products__title">{article.title}</h1>
          <div className="article-products__text">{article.content}</div>
        </div>
        <div className="article-products__block-text">
          <h1 className="article-products__title">{article.titleMethod}</h1>
          <div className="article-products__text">{article.contentMethod}</div>
        </div>

        <div className="article-products__block-container">
          <ul className="article-products__container">
            {Object.keys(products).map((key) => {
              const product = products[key];
              const image = product.img;

              return (
                <li key={key} className="article-products__card">
                  <Link
                    to={`/products/${productId}/${key}`}
                    className="article-products__card-link"
                  >
                    <div className="image-container">
                      {image && (
                        <img
                          src={image}
                          alt={product.title}
                          className="article-products__card-img"
                        />
                      )}
                    </div>
                    <div className="article-products__card-content">
                      <h3 className="article-products__card-title">
                        {product.title}
                      </h3>
                      <div className="article-products__card-text">
                        {product.taste}
                      </div>
                    </div>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
        <button
          className="article-products__button-main privacy-policy__button"
          type="button"
          onClick={handleGoBack}
        >
          ВСЕ ПРОДУКТЫ
        </button>
      </div>
    </div>
  );
};

export default ArticleProducts;
