import React, { useEffect } from "react";
import { motion } from "framer-motion";

import "./MainTitle.css";
import MainImage from "../../images/MainFon.png";
import CloudsImage from "../../images/clouds.png";
import WaterImage from "../../images/water.png";

const MainTitle = ({ headerHeight, handleGoGift }) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const cloudAnimation = {
    loop: Infinity,
    ease: "linear",
    duration: 60,
    repeatDelay: 0,
  };

  //тройное повторение движение воды
  const waterAnimation = {
    duration: 10,
    ease: "easeInOut",
    repeat: 3,
    repeat: Infinity,
    repeatDelay: 0,
  };

  return (
    <section
      className="main-title"
      id="main-title"
      style={{ paddingTop: `${headerHeight}px` }}
    >
      <div className="main-title__wrapper">
        <div className="main-title__container">
          <div className="main-title__image-container">
            <img
              className="main-title__container-img"
              src={MainImage}
              alt="Основной фон гор в Японии с движущимися облаками и водой"
            />
            <motion.img
              className="main-title__container-clouds"
              src={CloudsImage}
              alt="Облака плывут над горами в Японии"
              animate={{ x: [0, 100, 0] }}
              transition={cloudAnimation}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                pointerEvents: "none",
              }}
            />
            <motion.img
              className="main-title__container-water"
              src={WaterImage}
              alt="Водная анимация у подножия гор в Японии"
              animate={{ x: [0, 3, 0] }}
              transition={waterAnimation}
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "98%",
                pointerEvents: "none",
              }}
            />
          </div>

          <div className="main-title__text">
            <h1 className="main-title__title">
              Натуральные добавки <br />
              <span className="japan-formula">Japan Formula</span>
              <br />
              для здоровья и красоты
            </h1>

            <p className="main-title__subtitle">
              ЕСТЕСТВЕННАЯ КРАСОТА И ЗДОРОВЬЕ
            </p>
          </div>
          <button className="main-title__btn" onClick={handleGoGift}>
            ПОЛУЧИТЬ ПОДАРОК
          </button>
        </div>
      </div>
    </section>
  );
};

export default MainTitle;
