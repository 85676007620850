import React, { useState } from "react";

import "./Menu.css";
import burgerBlack from "../../images/burgerBlack.svg";

import BurgerMenu from "../BurgerMenu/BurgerMenu";

const Menu = ({ isMobile }) => {
  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);

  const handleBurgerButtonClick = () => {
    setIsBurgerMenuOpen(!isBurgerMenuOpen);
  };

  return (
    <section className="menu">
      <button type="button" className="menu__close">
        <img
          src={burgerBlack}
          alt="Иконка открытия бургер меню"
          onClick={handleBurgerButtonClick}
        />
      </button>
      {isBurgerMenuOpen && (
        <BurgerMenu
          handleBurgerButtonClick={handleBurgerButtonClick}
          isMobile={isMobile}
        />
      )}
    </section>
  );
};
export default Menu;
