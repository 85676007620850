import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "./NoAccess.css";

const NoAccess = ({
  headerHeight,
  setCookieConsent,
  setIsCookieConsentVisible,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    const header = document.querySelector(".header");
    const headerHeight = header ? header.offsetHeight : 0;

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    setIsCookieConsentVisible(false);
  }, [setIsCookieConsentVisible]);

  const handleGoBack = () => {
    localStorage.removeItem("cookieConsent");
    setCookieConsent(null);
    window.location.replace("/");
  };

  const handlePrivacyPolicyClick = () => {
    localStorage.setItem("allowPrivacyPolicy", "true");
    navigate("/privacy-policy/");
  };

  return (
    <section className="no-access" style={{ paddingTop: `${headerHeight}px` }}>
      <div className="no-access__wrapper">
        <h1 className="no-access__title">ДОСТУП ОГРАНИЧЕН</h1>

        <h2 className="no-access__subtitle ">Уважаемый пользователь,</h2>
        <p className="no-access__text">
          Для обеспечения полной функциональности нашего сайта мы используем
          файлы cookie и локальноe хранилище. Эти технологии помогают нам
          улучшить ваш пользовательский опыт, проанализировать использование
          сайта и предоставить персонализированный контент.
          <br /> К сожалению, без вашего согласия на использование файлов cookie
          и локального хранилища, мы не можем предоставить доступ ко всем
          функциям нашего сайта. Пожалуйста, примите использование этих
          технологий для продолжения работы на нашем сайте.
          <br />
          Если у вас возникли вопросы или вы хотите узнать больше о том, как мы
          используем данные, пожалуйста, ознакомьтесь с нашей
        </p>
        <button
          className="no-access__link no-access__btn"
          onClick={handlePrivacyPolicyClick}
        >
          Политикой конфиденциальности
        </button>
        <p className="no-access__text">
          Спасибо за понимание!
          <br /> С уважением, Команда поддержки.
        </p>

        <button
          className="no-access__link no-access__button"
          onClick={handleGoBack}
        >
          Вернуться на главную
        </button>
      </div>
    </section>
  );
};

export default NoAccess;
