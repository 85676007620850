import Lemon11 from "./imagesproteinSnakes/Lemon11.webp";
import Lemon13 from "./imagesproteinSnakes/Lemon13.webp";
import Lemon14 from "./imagesproteinSnakes/Lemon14.webp";
import strawberry11 from "./imagesproteinSnakes/strawberry11.webp";
import strawberry13 from "./imagesproteinSnakes/strawberry13.webp";
import strawberry14 from "./imagesproteinSnakes/strawberry14.webp";
import cofee11 from "./imagesproteinSnakes/cofee11.webp";
import cofee12 from "./imagesproteinSnakes/cofee12.webp";
import cofee13 from "./imagesproteinSnakes/cofee13.webp";
import cofee14 from "./imagesproteinSnakes/cofee14.webp";
import mango11 from "./imagesproteinSnakes/mango11.webp";
import mango12 from "./imagesproteinSnakes/mango12.webp";
import mango13 from "./imagesproteinSnakes/mango13.webp";
import mango14 from "./imagesproteinSnakes/mango14.webp";

export const proteinShakesProducts = {
    'lemon-protein-shakes': {
        title: 'Белковый коктейль для похудения. Без сахара.',
        productType: 'Протеин. Порошок.',
        description: (
            <>
                <p> Данный пп коктейль разработан для похудения и для борьбы с лишним весом, он поможет добиться вам желаемых результатов. Его можете использоваться, как часть правильного питания, как замена пищи, как детокс. Выпив всего одну порцию, вы получите чувство насыщения на несколько часов. </p>
                <p>Антиоксиданты в составе обеспечивают защиту клеток организма от вредного воздействия извне, замедляют процессы старения. </p>
            </>
        ),
        price: '1150 ',
        tasteEng: 'lemon',
        taste: 'Лимон Лайм',
        textPurpose: 'Восстановление, Выносливость, Красота и здоровье, Набор мышечной массы, Повышение работоспособности, Похудение/Сушка',
        purposes: ['здоровье кожи, волос и ногтей', 'энергия', 'восстановление', 'красота и здоровье', 'детокс, очищение', 'лучшее для мужчин'],
        composition: 'Мальтодекстрин, молоко сухое,; изолят соевого белка, пищевой ароматизатор, таурин,; витамин С, стевиозид, пищевой краситель, витамин В3,; витамин Е, витамин В5, витамин В6, витамин В2,; витамин В1, витамин В9, витамин D3.',
        text: 'Добавки: протеины; аминокислоты; растительные экстракты',
        water: <><p >Не растворять в горячей воде</p></>,
        country: 'Россия',
        expirationDate: '36',
        storageConditions: 'В защищенном от солнца помещении, В сухом прохладном месте',
        gender: 'Женский, Мужской',
        images: [Lemon11, Lemon13, Lemon14],
        img: Lemon11,
        contraindications: <>
            <p>1. Индивидуальная непереносимость ингредиентов : Возможна индивидуальная непереносимость некоторых компонентов продукта</p>
            <p>2. Пациентам с хроническими заболеваниями : Людям с хроническими заболеваниями сердца, почек или печени следует проконсультироваться с врачом перед употреблением.</p>
            <p>3. Беременные и кормящие женщины : Употребление продукта должно быть согласовано с лечащим врачом.</p>
            <p>4. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
        </>,
        contentMethod: (
            <>
                <p> 2 мерные ложки (5гр) смеси развести в стакане воды, молока, йогурта или кефира (200мл).</p>
                <p> Принимать 1-2 раза в день.</p>
                <p>При приготовлении напитка рекомендуется использовать блендер или шейкер.</p>

            </>
        ),
    },
    'strawberry-protein-shakes': {
        title: 'Белковый коктейль для похудения. Без сахара.',
        productType: 'Протеин. Порошок.',
        description: (
            <>
                <p> Данный пп коктейль разработан для похудения и для борьбы с лишним весом, он поможет добиться вам желаемых результатов. Его можете использоваться, как часть правильного питания, как замена пищи, как детокс. Выпив всего одну порцию, вы получите чувство насыщения на несколько часов. </p>
                <p>Антиоксиданты в составе обеспечивают защиту клеток организма от вредного воздействия извне, замедляют процессы старения. </p>
            </>
        ),
        price: '1150 ',
        tasteEng: 'strawberry',
        taste: 'Клубника со сливками',
        textPurpose: 'Восстановление, Выносливость, Красота и здоровье, Набор мышечной массы, Повышение работоспособности, Похудение/Сушка',
        purposes: ['здоровье кожи, волос и ногтей', 'энергия', 'восстановление', 'красота и здоровье', 'детокс, очищение'],
        composition: 'Мальтодекстрин, молоко сухое,; изолят соевого белка, пищевой ароматизатор, таурин,; витамин С, стевиозид, пищевой краситель, витамин В3,; витамин Е, витамин В5, витамин В6, витамин В2,; витамин В1, витамин В9, витамин D3.',
        text: 'Добавки: протеины; аминокислоты; растительные экстракты',
        water: <><p >Не растворять в горячей воде</p></>,
        country: 'Россия',
        expirationDate: '36',
        storageConditions: 'В защищенном от солнца помещении, В сухом прохладном месте',
        gender: 'Женский, Мужской',
        images: [strawberry11, strawberry13, strawberry14],
        img: strawberry11,
        contraindications: <>
            <p>1. Индивидуальная непереносимость ингредиентов : Возможна индивидуальная непереносимость некоторых компонентов продукта</p>
            <p>2. Пациентам с хроническими заболеваниями : Людям с хроническими заболеваниями сердца, почек или печени следует проконсультироваться с врачом перед употреблением.</p>
            <p>3. Беременные и кормящие женщины : Употребление продукта должно быть согласовано с лечащим врачом.</p>
            <p>4. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
        </>,
        contentMethod: (
            <>
                <p> 2 мерные ложки (5гр) смеси развести в стакане воды, молока, йогурта или кефира (200мл).</p>
                <p> Принимать 1-2 раза в день.</p>
                <p>При приготовлении напитка рекомендуется использовать блендер или шейкер.</p>

            </>
        ),
    },
    'coffee-protein-shakes': {
        title: 'Белковый коктейль для похудения. Без сахара.',
        productType: 'Протеин. Порошок.',
        description: (
            <>
                <p> Данный пп коктейль разработан для похудения и для борьбы с лишним весом, он поможет добиться вам желаемых результатов. Его можете использоваться, как часть правильного питания, как замена пищи, как детокс. Выпив всего одну порцию, вы получите чувство насыщения на несколько часов. </p>
                <p>Антиоксиданты в составе обеспечивают защиту клеток организма от вредного воздействия извне, замедляют процессы старения. </p>
            </>
        ),
        price: '1150 ',
        tasteEng: 'coffee latte',
        taste: 'Кофе Латте',
        textPurpose: 'Восстановление, Выносливость, Красота и здоровье, Набор мышечной массы, Повышение работоспособности, Похудение/Сушка',
        purposes: ['здоровье кожи, волос и ногтей', 'энергия', 'восстановление', 'красота и здоровье', 'детокс, очищение', 'лучшее для женщин'],
        composition: 'Мальтодекстрин, молоко сухое,; изолят соевого белка, пищевой ароматизатор, таурин,; витамин С, стевиозид, пищевой краситель, витамин В3,; витамин Е, витамин В5, витамин В6, витамин В2,; витамин В1, витамин В9, витамин D3.',
        text: 'Добавки: протеины; аминокислоты; растительные экстракты',
        water: <><p >Не растворять в горячей воде</p></>,
        country: 'Россия',
        expirationDate: '36',
        storageConditions: 'В защищенном от солнца помещении, В сухом прохладном месте',
        gender: 'Женский, Мужской',
        images: [cofee11, cofee12, cofee13, cofee14],
        img: cofee11,
        contraindications: <>
            <p>1. Индивидуальная непереносимость ингредиентов : Возможна индивидуальная непереносимость некоторых компонентов продукта</p>
            <p>2. Пациентам с хроническими заболеваниями : Людям с хроническими заболеваниями сердца, почек или печени следует проконсультироваться с врачом перед употреблением.</p>
            <p>3. Беременные и кормящие женщины : Употребление продукта должно быть согласовано с лечащим врачом.</p>
            <p>4. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
        </>,
        contentMethod: (
            <>
                <p> 2 мерные ложки (5гр) смеси развести в стакане воды, молока, йогурта или кефира (200мл).</p>
                <p> Принимать 1-2 раза в день.</p>
                <p>При приготовлении напитка рекомендуется использовать блендер или шейкер.</p>

            </>
        ),
    },
    'mango-protein-shakes': {
        title: 'Белковый коктейль для похудения. Без сахара.',
        productType: 'Протеин. Порошок.',
        description: (
            <>
                <p> Данный пп коктейль разработан для похудения и для борьбы с лишним весом, он поможет добиться вам желаемых результатов. Его можете использоваться, как часть правильного питания, как замена пищи, как детокс. Выпив всего одну порцию, вы получите чувство насыщения на несколько часов. </p>
                <p>Антиоксиданты в составе обеспечивают защиту клеток организма от вредного воздействия извне, замедляют процессы старения. </p>
            </>
        ),
        price: '1150 ',
        tasteEng: 'mango',
        taste: 'Манго',
        textPurpose: 'Восстановление, Выносливость, Красота и здоровье, Набор мышечной массы, Повышение работоспособности, Похудение/Сушка',
        purposes: ['здоровье кожи, волос и ногтей', 'энергия', 'восстановление', 'красота и здоровье', 'детокс, очищение'],
        composition: 'Мальтодекстрин, молоко сухое,; изолят соевого белка, пищевой ароматизатор, таурин,; витамин С, стевиозид, пищевой краситель, витамин В3,; витамин Е, витамин В5, витамин В6, витамин В2,; витамин В1, витамин В9, витамин D3.',
        text: 'Добавки: протеины; аминокислоты; растительные экстракты',
        water: <><p >Не растворять в горячей воде</p></>,
        country: 'Россия',
        expirationDate: '36',
        storageConditions: 'В защищенном от солнца помещении, В сухом прохладном месте',
        gender: 'Женский, Мужской',
        images: [mango11, mango12, mango13, mango14],
        img: mango11,
        contraindications: <>
            <p>1. Индивидуальная непереносимость ингредиентов : Возможна индивидуальная непереносимость некоторых компонентов продукта</p>
            <p>2. Пациентам с хроническими заболеваниями : Людям с хроническими заболеваниями сердца, почек или печени следует проконсультироваться с врачом перед употреблением.</p>
            <p>3. Беременные и кормящие женщины : Употребление продукта должно быть согласовано с лечащим врачом.</p>
            <p>4. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
        </>,
        contentMethod: (
            <>
                <p> 2 мерные ложки (5гр) смеси развести в стакане воды, молока, йогурта или кефира (200мл).</p>
                <p> Принимать 1-2 раза в день.</p>
                <p>При приготовлении напитка рекомендуется использовать блендер или шейкер.</p>

            </>
        ),
    },
}