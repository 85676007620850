import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './NotFoundPage.css';

const NotFoundPage = ({ headerHeight }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleGoBack = () => {
    navigate("/");
  };

  React.useEffect(() => {
    // Установка заголовка страницы
    document.title = '404 - Страница не найдена';
  }, []);

  return (
    <main>
      <section className="not-found-page">
        <div
          className="not-found-page__wrapper"
          style={{ paddingTop: `${headerHeight}px` }}
        >
          <h1 className="not-found-page__title">404</h1>
          <span className="not-found-page__subtitle">Страница не найдена</span>

          <button className="not-found-page__button" onClick={handleGoBack}>
            НАЗАД
          </button>
        </div>
      </section>
    </main>  
  );
};

export default NotFoundPage;
