import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "./App.css";

import Main from "../Main/Main";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import About from "../About/About";
import Store from "../Store/Store";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import NotFoundPage from "../NotFoundPage/NotFoundPage";
import ScientificCenter from "../ScientificCenter/ScientificCenter";
import Article from "../Article/Article";
import Products from "../Products/Products";
import ArticleProducts from "../ArticleProducts/ArticleProducts";
import ProductDetail from "../ProductDetail/ProductDetail";
import ChooseTaste from "../ChooseTaste/ChooseTaste";
import ProductsByPurpose from "../ProductsByPurpose/ProductsByPurpose";
import useHeaderHeight from "../../hooks/useHeaderHeight";
import CookieConsent from "../CookieConsent/CookieConsent";
import NoAccess from "../NoAccess/NoAccess";
import InfoAboutCookies from "../InfoAboutCookies/InfoAboutCookies";
import SlideTableTopic from "../SlideTableTopic/SlideTableTopic";
import Gift from "../Podarok/Podarok";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";

const phoneUserName = "japanformula";

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isNotFoundPage, setIsNotFoundPage] = useState(false);

  const [cookieConsent, setCookieConsent] = useState(null);
  const [isCookieConsentVisible, setIsCookieConsentVisible] = useState(true);

  const [currentComponent, setCurrentComponent] = useState(null);

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");

    const allowPrivacyPolicy = localStorage.getItem("allowPrivacyPolicy");
    if (consent === "declined" && !allowPrivacyPolicy) {
      navigate("/no-access/");
    } else {
      setCookieConsent(consent);
    }
  }, [navigate]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isMobile = windowWidth < 769;
  const headerHeight = useHeaderHeight();

  useEffect(() => {
    window.scrollTo(0, 0); // Прокручиваем страницу вверх при изменении маршрута
  }, [location.pathname]);

  useEffect(() => {
    const validPaths = [
      "/",
      "/about/",
      "/store/",
      "/podarok/",
      "/privacy-policy/",
      "/about-cookies/",
      "/no-access/",
      "/goal/",
      "/scientific-center/",
      "/products/",
      "/terms-and-conditions/",
    ];

    const dynamicPaths = [
      "/scientific-center/:id/",
      "/choose-taste/:taste/",
      "/products/:productId/",
      "/products/:productId/:productKey/",
      "/products-by-purpose/:purpose/",
    ];

    const pathIsValid =
      validPaths.includes(location.pathname) ||
      dynamicPaths.some((path) => {
        const pathParts = path.split("/").filter(Boolean);
        const locationParts = location.pathname.split("/").filter(Boolean);

        if (pathParts.length !== locationParts.length) {
          return false;
        }

        return pathParts.every((part, index) => {
          return part.startsWith(":") || part === locationParts[index];
        });
      });
    setIsNotFoundPage(!pathIsValid);
  }, [location.pathname]);

  const handleGoMain = () => {
    setCurrentComponent(null);
    navigate("/");
  };

  const handleGoBack = () => {
    setCurrentComponent(null);
    navigate(-1);
  };

  const handleGoStore = () => {
    setCurrentComponent(null);
    navigate("/store/");
  };

  const handleGoGift = () => {
    setCurrentComponent(null);
    navigate("/podarok/");
  };

  const handleTelegramClick = () => {
    const telegramUrl = `https://t.me/${phoneUserName}`;
    window.open(telegramUrl, "_blank");
  };

  const handleVkClick = () => {
    window.open("https://vk.com/japanformula", "_blank");
  };

  const openComponent = (Component) => {
    setCurrentComponent(
      <Component
        handleGoMain={handleGoMain}
        handleGoBack={handleGoBack}
        headerHeight={headerHeight}
      />
    );
  };

  return (
    <div className="app">
      {!isNotFoundPage && (
        <Header
          isMobile={isMobile}
          handleGoMain={handleGoMain}
          headerHeight={headerHeight}
          handleGoGift={handleGoGift}
        />
      )}

      {currentComponent ? (
        currentComponent
      ) : (
        <Routes>
          <Route
            path="/"
            element={
              <Main
                headerHeight={headerHeight}
                openComponent={openComponent}
                handleGoGift={handleGoGift}
                handleTelegramClick={handleTelegramClick}
                handleVkClick={handleVkClick}
              />
            }
          />
          <Route
            path="/about/"
            element={
              <About
                headerHeight={headerHeight}
                handleGoMain={handleGoMain}
                handleGoStore={handleGoStore}
                handleGoGift={handleGoGift}
                handleTelegramClick={handleTelegramClick}
                handleVkClick={handleVkClick}
              />
            }
          />
          <Route
            path="/store/"
            element={<Store headerHeight={headerHeight} />}
          />
          <Route
            path="/scientific-center/"
            element={<ScientificCenter headerHeight={headerHeight} />}
          />
          <Route
            path="/scientific-center/:articleId/"
            element={
              <Article
                headerHeight={headerHeight}
                handleGoBack={handleGoBack}
              />
            }
          />
          <Route
            path="/choose-taste/:taste/"
            element={
              <ChooseTaste
                headerHeight={headerHeight}
                handleGoBack={handleGoBack}
                handleGoStore={handleGoStore}
              />
            }
          />
          <Route
            path="/podarok/"
            element={
              <Gift
                headerHeight={headerHeight}
                handleGoMain={handleGoMain}
                handleGoStore={handleGoStore}
                handleTelegramClick={handleTelegramClick}
                handleVkClick={handleVkClick}
              />
            }
          />
          <Route
            path="/products/"
            element={<Products headerHeight={headerHeight} />}
          />
          <Route
            path="/products/:productId/"
            element={
              <ArticleProducts
                headerHeight={headerHeight}
                handleGoBack={handleGoBack}
              />
            }
          />
          <Route
            path="/products-by-purpose/:purpose/"
            element={
              <ProductsByPurpose
                headerHeight={headerHeight}
                handleGoBack={handleGoBack}
                handleGoStore={handleGoStore}
              />
            }
          />
          <Route
            path="/products/:productId/:productKey/"
            element={
              <ProductDetail
                headerHeight={headerHeight}
                handleGoBack={handleGoBack}
              />
            }
          />

          <Route
            path="/goal/"
            element={
              <SlideTableTopic
                headerHeight={headerHeight}
                openComponent={openComponent}
                handleGoMain={handleGoMain}
                handleGoBack={handleGoBack}
                handleGoStore={handleGoStore}
              />
            }
          />
          <Route
            path="/privacy-policy/"
            element={
              <PrivacyPolicy
                handleGoBack={handleGoBack}
                headerHeight={headerHeight}
                setIsCookieConsentVisible={setIsCookieConsentVisible}
              />
            }
          />
          <Route
            path="/about-cookies/"
            element={
              <InfoAboutCookies
                headerHeight={headerHeight}
                setCookieConsent={setCookieConsent}
                setIsCookieConsentVisible={setIsCookieConsentVisible}
              />
            }
          />
          <Route
            path="*"
            element={<NotFoundPage headerHeight={headerHeight} />}
          />
          <Route
            path="/no-access/"
            element={
              <NoAccess
                headerHeight={headerHeight}
                setCookieConsent={setCookieConsent}
                setIsCookieConsentVisible={setIsCookieConsentVisible}
              />
            }
          />

          <Route
            path="/terms-and-conditions/"
            element={
              <TermsAndConditions
                headerHeight={headerHeight}
                handleGoBack={handleGoBack}
              />
            }
          />
        </Routes>
      )}

      {!isNotFoundPage && (
        <Footer
          handleGoMain={handleGoMain}
          handleGoGift={handleGoGift}
          handleTelegramClick={handleTelegramClick}
          handleVkClick={handleVkClick}
        />
      )}
      {cookieConsent !== "accepted" && isCookieConsentVisible && (
        <>
          <CookieConsent setCookieConsent={setCookieConsent} />
        </>
      )}
    </div>
  );
};

export default App;
