import dm1 from "../images/dm1-min.png";
import dm2 from "../images/dm2-min.png";
import mm1 from "../images/mm1-min.png";
import mm2 from "../images/mm2-min.png";
import mm3 from "../images/mm3-min.png";
import oz1 from "../images/oz1-min.png";
import oz2 from "../images/oz2-min.png";
import w1 from "../images/w1-min.png";
import w2 from "../images/w2-min.png";
import w3 from "../images/w3-min.png";
import ym1 from "../images/ym1-min.png";
import ym2 from "../images/ym2-min.png";
import ym3 from "../images/ym3-min.png";


export const cardsMainReviewsData = [
  { img: dm1, alt: "отзывы c маркплейса Детский мир", text: 'отзывы c торговой площадки "Детский мир"' },
  { img: dm2, alt: "отзывы c маркплейса Детский мир", text: 'отзывы c торговой площадки "Детский мир"' },
  { img: mm1, alt: "отзывы c маркплейса Мега Маркет", text: 'отзывы c торговой площадки "МегаМаркет"' },
  { img: mm2, alt: "отзывы c маркплейса Мега Маркет", text: 'отзывы c торговой площадки "МегаМаркет"' },
  { img: mm3, alt: "отзывы c маркплейса Мега Маркет", text: 'отзывы c торговой площадки "МегаМаркет"' },
  { img: oz1, alt: "отзывы c маркплейса OZON", text: 'отзывы c торговой площадки "OZON"' },
  { img: oz2, alt: "отзывы c маркплейса OZON", text: 'отзывы c торговой площадки "OZON"' },
  { img: w1, alt: "отзывы c маркплейса WILDBERRIES", text: 'отзывы c торговой площадки "WILDBERRIES"' },
  { img: w2, alt: "отзывы c маркплейса WILDBERRIES", text: 'отзывы c торговой площадки "WILDBERRIES"' },
  { img: w3, alt: "отзывы c маркплейса WILDBERRIES", text: 'отзывы c торговой площадки "WILDBERRIES"' },
  { img: ym1, alt: "отзывы c маркплейса ЯндексМаркет", text: 'отзывы c торговой площадки "ЯндексМаркет"' },
  { img: ym3, alt: "отзывы c маркплейса ЯндексМаркет", text: 'отзывы c торговой площадки "ЯндексМаркет"' },
  { img: ym3, alt: "отзывы c маркплейса ЯндексМаркет", text: 'отзывы c торговой площадки "ЯндексМаркет"' },
];

