import React from "react";

import "./ChooseUs.css";

const ChooseUs = () => {
  return (
    <section className="choose-us" id="choose-us">
      <div className="choose-us__wrapper">
        <h1 className="choose-us__title">
          Почему выбирают нашу компанию "JAPAN FORMULA"
        </h1>
        <h2 className="choose-us__subtitle">Качество и безопасность:</h2>
        <p className="choose-us__text">
          Мы сотрудничаем только с проверенными производителями и поставщиками,
          чья продукция соответствует международным стандартам качества и
          безопасности.
        </p>
        <h2 className="choose-us__subtitle">Широкий ассортимент:</h2>
        <p className="choose-us__text">
          У нас вы найдете всё необходимое для поддержания активного и здорового
          образа жизни, от спортивного питания до витаминов и БАДов.
        </p>
        <h2 className="choose-us__subtitle">Экспертная поддержка:</h2>
        <p className="choose-us__text">
          Наша команда высококвалифицированных специалистов, включающая
          экспертов в области питания, фитнеса и медицины, постоянно работает
          над разработкой и совершенствованием продуктов. Мы уделяем особое
          внимание исследованиям и инновациям, чтобы создавать решения, которые
          не только удовлетворяют, но и превосходят ожидания наших клиентов.
          Каждый продукт, который мы предлагаем, тщательно разработан с учетом
          новейших научных данных и рекомендаций, чтобы наилучшим образом
          соответствовать вашим индивидуальным потребностям и целям, будь то
          улучшение спортивных результатов, поддержание общего здоровья или
          повышение уровня энергии и выносливости.
        </p>
        <h2 className="choose-us__subtitle">Удобный сервис:</h2>
        <p className="choose-us__text">
          Мы работаем с основными маркетплейсами. Вы всегда можете
          воспользоваться любимым из них и сделать заказ. Проводим регулярные
          акции для наших клиентов
        </p>
      </div>
    </section>
  );
};

export default ChooseUs;
