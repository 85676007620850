import React, { useEffect } from "react";

import "./AboutUs.css";

import VideoPlayer from "../VideoPlayer/VideoPlayer";

const AboutUs = ({ headerHeight, handleGoMain, handleGoStore }) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <section
      className="about-us"
      id="about-us"
      style={{ paddingTop: `${headerHeight}px` }}
    >
      <div className="about-us__wrapper">
        <div className="about-us__container">
          <h1 className="main-title__title">
            Ваш путь к совершенству НАЧИНАЕТСЯ ЗДЕСЬ
          </h1>
          <p className="main-title__subtitle">
            ЕСТЕСТВЕННАЯ ЭНЕРГИЯ И ЗДОРОВЬЕ
          </p>

          <VideoPlayer />

          <div className="main-title__text"></div>
        </div>
        <div className="about-us__button-block">
          <button
            className="about-us__button-main privacy-policy__button"
            type="button"
            onClick={handleGoMain}
          >
            На главную
          </button>
          <button
            className="about-us__button-main privacy-policy__button"
            type="button"
            onClick={handleGoStore}
          >
            Где купить
          </button>
        </div>
        <h1 className="about-us__title">
          Добро пожаловать на сайт нашей компании "JAPAN FORMULA"
        </h1>
        <h2 className="about-us__subtitle">Наша миссия</h2>
        <p className="about-us__text">
          Мы стремимся помочь каждому достичь своих спортивных целей и улучшить
          качество жизни. Наша миссия – предоставлять качественные и безопасные
          продукты, которые способствуют здоровью, энергичности и отличным
          спортивным результатам.
        </p>
        <h2 className="about-us__subtitle">Что мы предлагаем</h2>
        <h3 className="about-us__topic">Спортивное питание:</h3>
        <p className="about-us__text">
          Наш ассортимент включает различные виды протеинов, аминокислот,
          креатина и других добавок, которые необходимы для роста мышц,
          увеличения выносливости и быстрого восстановления.
        </p>
        <h3 className="about-us__topic">Витамины:</h3>
        <p className="about-us__text">
          Мы предлагаем широкий выбор витаминов и минералов, необходимых для
          поддержания общего здоровья и благополучия.
        </p>
        <h3 className="about-us__topic">Протеиновые батончики:</h3>{" "}
        <p className="about-us__text">
          Наши протеиновые батончики – идеальный перекус для тех, кто ведет
          активный образ жизни и нуждается в дополнительном источнике белка и
          энергии.
        </p>
        <h3 className="about-us__topic">БАДы:</h3>
        <p className="about-us__text">
          В нашем каталоге вы найдете биологически активные добавки, которые
          помогают улучшить работу организма, поддержать иммунитет и повысить
          жизненный тонус.
        </p>
      </div>
    </section>
  );
};

export default AboutUs;
