import Crossfit from "../images/Crossfit.png";
import collagen1 from "../images/collagen1.png";
import collagen2 from "../images/collagen2.png";
import collagen3 from "../images/collagen3.png";
import culture from "../images/culture.png";
import deficit from "../images/deficit.png";
import diversity from "../images/diversity.png";
import nutrition from "../images/nutrition.png";
import body from "../images/body_weight.png";
import tea from "../images/tea.png";

export const articlesData = {
    'polza-pitevogo-kollagena': {
        title: 'Польза питьевого коллагена',
        content: (
            <>
                <p>Коллаген – важнейший протеин, составляющий около одной трети белков в теле человека. Он играет ключевую роль в поддержании эластичности и упругости кожи, здоровье суставов и костей. С возрастом естественная выработка коллагена снижается, что приводит к потере эластичности кожи, образованию морщин и ухудшению состояния суставов. Питьевой коллаген помогает компенсировать эту потерю, поддерживая здоровье кожи, волос, ногтей и суставов.
                    <br />  <br />Молекулы коллагена слишком крупные, чтобы проникать через кожу при наружном применении, поэтому прием внутрь является наиболее эффективным способом его использования. Биодобавки с коллагеном, особенно морского или животного происхождения, становятся все более популярными благодаря их эффективности. Исследования показывают, что регулярный прием коллагена может улучшить увлажненность и эластичность кожи, уменьшить количество морщин, а также укрепить кости и суставы. <br />  <br />Для достижения видимых результатов рекомендуется проходить полный курс приема коллагена, обычно от 3 до 6 месяцев. Это позволяет проводить профилактику старения, улучшать здоровье костей и суставов, а также поддерживать молодость и красоту кожи. Современные исследования также указывают на положительное влияние коллагена на здоровье сердца и кишечника.</p>
            </>
        ),
        text: 'Коллаген – протеин, который составляет одну треть белков в теле человека. Его свойства полезны для кожи, помогают избавляться от растяжек и морщин. Чтобы организм функционировал правильно и без каких-то сбоев…',
        img: collagen1,
    },
    'osnovy-pravilnogo-pitaniya': {
        title: 'Основы правильного питания',
        content: (
            <>
                <p>Правильное питание – это ключ к здоровью, энергичности и хорошей форме. В современном мире, полном стресса и быстрых темпов жизни, важно уделять внимание своему рациону. Полноценное питание обеспечивает организм всеми необходимыми нутриентами, включая белки, углеводы, жиры, витамины и минералы.
                    <br />  <br />Сбалансированный рацион должен включать разнообразные продукты: фрукты и овощи – источники витаминов и антиоксидантов, цельнозерновые продукты – богатые клетчаткой и углеводами, а также белковые продукты, такие как мясо, рыба, яйца и молочные продукты. Недавние исследования показывают, что достаточное потребление белка особенно важно для поддержания мышечной массы и метаболизма, особенно у пожилых людей.
                    <br />  <br />Не забывайте пить достаточное количество воды – в среднем около 2 литров в день. Вода необходима для всех процессов в организме, включая пищеварение, терморегуляцию и детоксикацию. Также полезно включать в рацион свежевыжатые соки и смузи, но умеренно, чтобы избежать излишнего потребления сахара.
                    <br />  <br />Современные диетологи также рекомендуют уменьшить потребление обработанных продуктов, которые часто содержат добавленный сахар, соли и нездоровые жиры. Вместо этого предпочтение следует отдавать натуральным продуктам, приготовленным дома. Регулярная физическая активность в сочетании с правильным питанием помогает поддерживать здоровье, предотвращать хронические заболевания и улучшать качество жизни.</p>
            </>
        ),
        text: 'Чтобы быть энергичным, здоровым и находится в хорошей форме просто необходимо правильно питаться (не стоит забывать и о физической нагрузке). В современном мире, когда мы подвергаемся стрессу ежедневно, человек находит…',
        img: nutrition,
    },
    'defitsit-kollagena-v-organizme': {
        title: 'Дефицит коллагена в организме',
        content: (
            <>
                <p>Коллаген – это структурный белок, который выполняет функцию клея в тканях, поддерживая их целостность и эластичность. Он важен для здоровья кожи, суставов, сухожилий, ногтей и волос. С возрастом естественная выработка коллагена снижается, что приводит к появлению морщин, снижению эластичности кожи, ломкости ногтей и выпадению волос.</p>

                <p>Одним из методов определения недостатка коллагена является проверка эластичности кожи: поднимите верхнюю часть века и наблюдайте, как быстро кожа вернется в исходное положение. Если это происходит медленно, возможно, у вас дефицит коллагена. Другие симптомы включают целлюлит, сухость кожи, ухудшение структуры волос и ногтей, а также боли в суставах.</p>

                <p>Современные исследования показывают, что прием коллагеновых добавок может помочь компенсировать его недостаток, улучшая состояние кожи, волос, ногтей и суставов. Кроме того, коллаген может способствовать улучшению здоровья сердечно-сосудистой системы, поддержке здорового пищеварения и снижению воспалительных процессов в организме.</p>

                <p>Для достижения наилучших результатов рекомендуется включать в рацион продукты, богатые коллагеном,  такие как костные бульоны, рыба, курица и яйца, а также принимать коллагеновые добавки под контролем врача или диетолога. Совмещение коллагеновых добавок с витамином С помогает улучшить синтез коллагена в организме.</p>
            </>
        ),
        text: 'Как известно есть множество разновидностей белка, один из них является коллаген, который выполняет функцию клея в тканях и является неким строительным соединением. Состояние кожи, суставов и сухожилий, ногтей и волос…',
        img: diversity,
    },
    'kollagen-ot-a-do-ya': {
        title: 'Коллаген от А до Я',
        content: (
            <>
                <p>Коллаген – это нитевидный белок, который составляет основную часть соединительной ткани в организме. Его количество составляет около одной трети всех белков в нашем теле. Коллаген необходим для здоровья суставов, костей, волос, кожи, ногтей и зубов. Он также играет важную роль в поддержании структуры стенок вен, капилляров и артерий.
                    <br />  <br />С возрастом выработка коллагена в организме снижается, что приводит к ухудшению состояния кожи, костей и суставов. Коллагеновые фибриллы истощаются, что приводит к появлению морщин, снижению эластичности кожи и ослаблению суставов. Важно поддерживать уровень коллагена в организме для сохранения здоровья и молодости.
                    <br />  <br />Коллаген можно восполнять с помощью биодобавок, которые содержат гидролизованный коллаген, легко усваиваемый организмом. Такие добавки помогают улучшить состояние кожи, укрепить суставы и кости, а также поддерживать здоровье волос и ногтей. Регулярное потребление коллагена способствует улучшению общего состояния организма и предотвращению возрастных изменений.</p>

                <br />  <br />
                <div className="article__subtitle"> Виды коллагена</div>

                <p>Существует несколько типов коллагена, но основные из них – это типы I, II и III:</p>

                <p> Тип I: Самый распространенный тип коллагена, который находится в коже, костях, сухожилиях и зубах. Он отвечает за прочность и структуру тканей.
                    Тип II: Присутствует в хрящах и помогает поддерживать гибкость и здоровье суставов.
                    Тип III: Находится в стенках артерий и других полых органах, поддерживая их структуру и эластичность.</p>
                    <br />  <br />
                <div className="article__subtitle"> Источники коллагена в пище</div>

                <p>Коллаген можно получить не только из биодобавок, но и из продуктов питания:</p>

                <p> Костный бульон: Один из лучших природных источников коллагена, получаемый путем варки костей животных.
                    Рыба: Содержит высококачественный коллаген, особенно в коже и костях рыбы.
                    Яйца: Яичные белки содержат аминокислоты, необходимые для синтеза коллагена.
                    Мясо: Говядина, курица и другие виды мяса содержат коллаген, особенно в соединительных тканях.</p>
                    <br />  <br />
                <div className="article__subtitle">Преимущества коллагена для здоровья</div>

                <p>Улучшение состояния кожи: Коллаген способствует увлажнению и повышению эластичности кожи, что помогает уменьшить морщины и сухость.
                    Поддержка суставов: Коллаген помогает уменьшить боль и воспаление в суставах, улучшая их подвижность и здоровье.
                    Укрепление костей: Коллаген повышает плотность костей и предотвращает остеопороз.
                    Здоровье волос и ногтей: Коллаген улучшает структуру волос и ногтей, делая их крепкими и здоровыми.
                    Поддержка мышц: Коллаген способствует росту и восстановлению мышечной ткани.</p>
                    <br />  <br />
                <div className="article__subtitle">Как правильно принимать коллаген</div>

                <p>Для получения максимальной пользы от коллагена рекомендуется:</p>

                <p>Принимать на голодный желудок: Это может способствовать лучшему усвоению.
                    Сочетать с витамином С: Витамин С необходим для синтеза коллагена, поэтому добавление его в рацион улучшит результаты.
                    Регулярность: Постоянное употребление коллагена дает наилучшие результаты, поэтому важно принимать его ежедневно.</p>
                    <br />  <br />
                <div className="article__subtitle">Рекомендации</div>

                <p>Выбирайте качественные добавки: Убедитесь, что в составе биодобавок нет лишних добавок и консервантов.
                    Консультация с врачом: Перед началом приема коллагеновых добавок проконсультируйтесь с врачом, особенно если у вас есть хронические заболевания или аллергии.</p>
                    <br />  <br />
                <div className="article__subtitle">Коллаген играет ключевую роль в поддержании здоровья и молодости организма. Включение коллагена в рацион питания и использование биодобавок может значительно улучшить качество жизни, помогая поддерживать здоровье кожи, суставов, костей, волос и ногтей.</div>

            </>
        ),
        text: 'Все мы слышали про важность коллагена, но для чего он нужен и в чем ощущается эта важность? Коллаген – нитевидный белок, составляющий главную часть соединительной ткани. Количество коллагена – примерно…',
        img: collagen2,
    },
    'kultura-yaponii': {
        title: 'Культура Японии',
        content: (
            <>
                <p>Японская культура формировалась с момента переселения предков японцев на японские острова.
                    Основное влияние на народ Японии повлияло географического положение страны, ее климат и очень жестокие природные напасти. Все это отражается в культуре, принятие природы как чего-то живого, строгое следование традициям и обрядам.
                    <br />  <br />
                    Японцы соблюдают строгую субординацию к принадлежности какому-то социальному классу (студенты, рабочие завода, фермеры), что сильно влияет на своеобразные связи внутри общества.
                    <br />  <br />
                    Честь для японцев не пустой звук, понятие о долге перед родиной, была одной из важных прерогатив для каждого японца, это считается всеобщей национальной нормой.
                    <br />  <br />
                    Сдержанность – главное украшение человека. Чем сдержанней и скромней человек, тем уважительнее к нему будут относиться. Вторгаться в чужое пространство человека считается очень неприличным жестом.</p>
            </>
        ),
        text: 'Японская культура формировалась с момента переселения предков японцев на японские острова. Основное влияние на народ Японии повлияло географического положение страны, ее климат и очень жестокие природные напасти. Все это отражается….',
        img: culture,
    },
    'chainye-traditsii-yaponii': {
        title: 'Чайные традиции Японии',
        content: (
            <>
                <p>Чайная церемония – это традиция, японский ритуал, который выделяется своей серьезностью и сложностью. Участники церемонии наслаждаются эстетикой ритуала, ведут спокойные беседы, освобождают разум и открываются гармонии. Самый главный канон проведения церемонии – соблюдение строгих правил, которые не изменяются веками.
                    <br />  <br /> История и происхождение
                    Традиция пить чай пришла из Китая, где люди почитают культ чая с давних пор. Отличие чайных церемоний Китая и Японии в том, что китайцы заложили принципы конфуцианства, а японцы отождествляли его с буддизмом, следовательно, церемонии пропитаны спокойствием, чистотой и медитацией.
                    <br />  <br /> Первыми привезли чай в Японию буддийские монахи в IX веке. В XII веке монах Эйсай привез семена чая и начал распространять его употребление в Японии. Позднее, в XVI веке, Сен-но Рикю, известный мастер чайной церемонии, установил основные принципы чайной церемонии, которые включают уважение, чистоту, гармонию и покой.</p>
                    <br />  <br />
                <div className="article__subtitle"> Виды чая, используемые в церемонии</div>
                <p> В традиционной японской чайной церемонии обычно используются два вида чая:

                    Матча: Порошковый зеленый чай, который взбивают в горячей воде до состояния густой пены. Это основной чай, используемый в чайной церемонии.
                    Сенча: Листовой зеленый чай, который заваривают в горячей воде. Хотя он реже используется в официальных церемониях, он популярен в повседневной жизни.
                    Символика и значение ритуала
                    Чайная церемония – это не просто акт питья чая, а духовная практика, направленная на достижение внутреннего мира и гармонии.</p>
                    <br />  <br />
                <div className="article__subtitle">Важные аспекты включают:</div>

                <p> Чистота (清潔, сейкэцу): Очистка души и тела, что символизируется тщательной чисткой инструментов.
                    Уважение (敬, кэй): Глубокое уважение ко всем участникам церемонии и окружающей среде.
                    Гармония (和, ва): Создание гармоничного пространства и отношения между людьми.
                    Покой (寂, дзяку): Достижение внутреннего спокойствия и медитации через ритуал.</p>
                    <br />  <br />
                <div className="article__subtitle">Инструменты чайной церемонии</div>

                <p>Чайная церемония включает использование множества специальных инструментов, каждый из которых имеет свое значение:
                    <br />
                    <br /> Часэн (茶筅): Бамбуковая веничка для взбивания матча.
                    <br />Чаван (茶碗): Чайная чаша, в которой взбивают и подают матча.
                    <br />  Тясаку (茶杓): Бамбуковая ложечка для зачерпывания порошка матча.
                    <br />  Кама (釜): Чайник для кипячения воды.
                    <br />  Фукаса (袱紗): Шелковая ткань, используемая для очистки чайных инструментов.</p>
                    <br />  <br />
                <div className="article__subtitle">Основные этапы чайной церемонии</div>

                <p>Подготовка: Хозяин подготавливает чайную комнату, инструменты и чай.
                    <br />  Приветствие гостей: Гости входят в чайную комнату, оставляют свои обувь и символически очищают руки и рот.
                    <br />  Очистка инструментов: Хозяин ритуально очищает чайные инструменты перед гостями.
                    <br /> Приготовление чая: Хозяин взбивает матча и подает его гостям. Гости пьют чай, наслаждаясь моментом и эстетикой.
                    <br />  Завершение: Гости благодарят хозяина, и церемония завершается.</p>
                    <br />  <br />
                <div className="article__subtitle">Влияние на современность</div>

                <p>Сегодня чайная церемония остается важной частью японской культуры и практикуется не только в Японии, но и по всему миру. Многие люди находят в ней способ снять стресс, найти внутренний покой и гармонию.</p>

                <p> В Японии есть множество крупных школ, которые обучают мастерству чайной церемонии. Их учение распространяется по всему миру – их представительства имеются во многих странах.</p>

                <p>Чайная церемония – это глубокий и значимый ритуал, который объединяет людей, вдохновляет на уважение и гармонию и помогает найти внутренний покой через простоту и медитацию.</p>
            </>
        ),
        text:
            'Чайная церемония – это традиция, японский ритуал, который выделяется своей серьезностью и сложностью. Участники церемонии наслаждаются эстетикой ритуала, ведут спокойные беседы, освобождают разум и открываются гармонии. Самый главный канон проведения… ',

        img: tea,
    },
    'osnovnye-tipy-kollagena': {
        title: 'Основные типы коллагена',
        content: (
            <>
                <p>Коллаген является важным структурным белком, который играет роль "строительного клея" в организме. Существует несколько типов коллагена, но главными являются три вида – первый, второй и третий.
                <br />  <br />
                    Первый тип:
                    <br />
                    Это самый распространенный и сильный тип коллагена, который образует сухожилия, связки, кожу и кости. Он особенно важен для регенерации тканей и поддержания структуры кожи.
                    <br />  <br />
                    Второй тип:
                    <br />
                    Этот тип коллагена отвечает за гибкость и эластичность суставов, а также за укрепление и восстановление хрящей. Он особенно важен для людей, страдающих от артрита и других заболеваний суставов.
                    <br />  <br />
                    Третий тип:
                    <br />
                    Этот тип коллагена составляет основу органов и кожи. Он часто работает вместе с первым типом коллагена, обеспечивая упругость кровеносных сосудов и тканей.
                    <br />  <br />
                    С возрастом выработка коллагена снижается, что приводит к ухудшению состояния кожи, суставов и костей. Регулярное потребление коллагеновых добавок помогает восполнить недостаток коллагена и поддерживать здоровье организма.</p>
            </>
        ),
        text: 'Уже многое было сказано о пользе коллагена. Настоящий «строительный клей» организма, который образует прочность организма: состояние волокна в тканях, влажность и подтянутость кожи, сухожилия и хрящи прочными. С возрастом (наступление…',
        img: collagen3,
    },
    'krossfit-novaya-zhizn': {
        title: 'Кроссфит – новая жизнь?',
        content: (
            <><p>Когда понимаешь, что хочешь заняться спортом, но на подсознании появляется картина нудного тренажерного зала, где нужно делать неинтересные и скучные упражнения – желание сразу покидает тебя. Но что если и в зале можно заниматься весело, интересно, а главное интенсивно?
                Тогда стоит попробовать кроссфит, именно с его помощью вы забудете о монотонных длительных тренировках, время будет пролетать незаметно и уже через пару недель вы заметите свой первый результат.
                <br />  <br />
                Кроссфит – это интенсивная тренировка в быстром темпе, которая состоит в сочетании элементов упражнений из широкого спектра различных дисциплин.  Тяжелая атлетика, аэробика, легкая атлетика, гимнастика и др. Это круговая тренировка длится около 30 – 60 минут. В нее входит комплекс упражнений на проработку всех групп мышц, в определенном порядке, следующих друг за другом. На этих тренировках вам точно некогда будет скучать! И не забудьте размяться перед тренировкой!</p> </>
        ),
        text: 'Когда понимаешь, что хочешь заняться спортом, но на подсознании появляется картина нудного тренажерного зала, где нужно делать неинтересные и скучные упражнения – желание сразу покидает тебя. Но что если и…',
        img: Crossfit,
    },
    'kak-rasschitat-indeks-massy-tela': {
        title: 'Как рассчитать индекс массы тела?',
        content: (
            <><p>Еда является несомненно важной частью жизни каждого человека. Если человек пренебрегает качеством питания (с точки зрения медицины), у него могут начаться серьезные проблемы со здоровьем. Панкреатит, холецистит, желчно-каменная болезнь – все это лишь маленькая часть того, что может произойти при неправильном питании.
                <br />
                Здоровое питание необходимо организму, чтобы заряжать его энергией, что важно правильной и чистой. Именно такой питание должно давать возможность поддерживать нормальный вес, приемлемую физическую форму, а главное физическую активность.
                <br />
                Для того чтобы понять, к какому весу стремится, существует такая единица измерения как индекс массы тела (ИМТ) – отношение веса к росту. Этот индекс часто применяет при определении ожирения у человека.
                <br />
                ИМТ = отношение веса тела в кг к квадрату роста в метрах.
                ИМТ = (кг/м2)
                <br />
                Индекс ИМТ может сообщить состояние о вашем здоровье.
                <br />
                Менее 15,99 — Выраженный дефицит массы тела
                <br />
                16,00 — 18,49 — Недостаточная (дефицит) масса тела
                <br />
                18,50 — 24,99 — Норма
                <br />
                25,00 — 29,99 — Избыточная масса тела (предожирение)
                <br />
                30,00 — 34,99 — Ожирение первой степени
                <br />
                35,00 — 39,99 — Ожирение второй степени
                <br />
                Более 40,00 — Ожирение третьей степени (морбидное)
                <br />
                Главный плюс – эта мера оценки универсальна для всех гендеров.</p> </>
        ),
        text: 'Еда является несомненно важной частью жизни каждого человека. Если человек пренебрегает качеством питания (с точки зрения медицины), у него могут начаться серьезные проблемы со здоровьем. Панкреатит, холецистит, желчно-каменная болезнь –…',
        img: body,
    },
    'kak-raznoobrazit-ratsion-poleznymi-produktami': {
        title: 'Как разнообразить рацион полезными продуктами?',
        content: (
            <><p>Правильное питание… Первое что приходит в голову когда читаешь эти два слова, это куриная грудка на пару, овощи и каши. Многие из вас наверняка пытались перейти на ПП, но продержались не долго, причиной этому служит незнание как разнообразить рацион, сделать его сбалансированным и разнообразным.
                <br />
                1.Добавьте новые оттенки обычной еде.
                <br />
                В салаты и гарнир можно добавить масло, соус или маринад. Обычный салат или гарнир легко можно превратить в нечто большее, добавив масло, соус или маринад. Оливковое масло подходит для салатов и гарниров.
                <br />
                Пробуйте новые полезные продукты.
                Главное не попасть в рутину из блюд, поэтому следует разбавлять свой рацион. Сырой шпинат отлично дополнит салат и придаст ему свежесть, а если потушить его, то получится вкусный гарнир. Туда же можно добавить и брокколи.
                <br />
                Освойте новые способы приготовления.
                Привычное приготовление как варка и жарка, быстро надоедают, поэтому обязательно нужно вносить в эту пару еще несколько способов термообработки продуктов.
                <br />
                Запекание отлично подходит для рыбы, овощей, фруктов. Тушение, для него мы возьмем приготовление мяса, овощей и круп. Не стоит забывать и о готовке на пару и сушке сухофруктов.</p> </>
        ),
        text: 'Правильное питание… Первое что приходит в голову когда читаешь эти два слова, это куриная грудка на пару, овощи и каши. Многие из вас наверняка пытались перейти на ПП, но продержались…',
        img: deficit,
    },
};
