import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import "./MainSlides.css";
import arrowRight from "../../images/arrowRight.png";
import { cardsData } from "../../utils/cardsData";

const MainSlides = ({ openComponent }) => {
  const containerRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const container = containerRef.current;
    const itemCount = cardsData.length;

    container.style.setProperty("--item-count", itemCount * 2);
    container.style.setProperty("--scroll-duration", itemCount * 9);

    const handleHover = () => {
      container.style.animationPlayState = "paused";
    };

    const handleLeave = () => {
      container.style.animationPlayState = "running";
    };

    container.addEventListener("mouseenter", handleHover);
    container.addEventListener("mouseleave", handleLeave);

    return () => {
      container.removeEventListener("mouseenter", handleHover);
      container.removeEventListener("mouseleave", handleLeave);
    };
  }, []);

  const handleArrowClick = (direction) => {
    if (direction === "next") {
      containerRef.current.style.animationDirection = "normal";
      containerRef.current.style.animationPlayState = "running";
    } else if (direction === "prev") {
      containerRef.current.style.animationDirection = "reverse";
      containerRef.current.style.animationPlayState = "running";
    }
  };

  const handleSlideClick = (purpose) => {
    navigate(`/products-by-purpose/${purpose}`);
  };
  
  const handleGoGoal = () => {
    navigate("/goal/");
  };

  return (
    <section className="main-slides" id="main-slides">
      <div className="main-slides__wrapper">
        <hr />
        <div className="main-slides__text">
          <p className="main-slides__subtitle" onClick={handleGoGoal}>
            Каталог по целям
          </p>
          <h1 className="main-slides__title">
            Найдите идеальные продукты для своих целей
          </h1>
        </div>
        <div className="main-slides__container">
          <div
            className="main-slides__inner-container animate"
            ref={containerRef}
          >
            {cardsData.concat(cardsData).map((data, index) => (
              <img
                key={index}
                className="main-slides__slide"
                src={data.img}
                alt={data.alt}
                onClick={() => handleSlideClick(data.purpose)}
              />
            ))}
          </div>
        </div>
        <div className="main-slides__arrow-block">
          <img
            className="main-slides__arrow main-slides__left-arrow hover"
            src={arrowRight}
            alt="Влево"
            onClick={() => handleArrowClick("next")}
          />
          <img
            className="main-slides__arrow hover"
            src={arrowRight}
            alt="Вправо"
            onClick={() => handleArrowClick("prev")}
          />
        </div>
      </div>
      
    </section>
  );
};

export default MainSlides;
