
import FatLycheeDay11 from "./../FatBurners/imagesFatBurners/FatLycheeDay11.webp";
import FatLycheeDay12 from "./../FatBurners/imagesFatBurners/FatLycheeDay12.webp";
import FatLycheeDay13 from "./../FatBurners/imagesFatBurners/FatLycheeDay13.webp";
import FatLycheeNight11 from "./../FatBurners/imagesFatBurners/FatLycheeNight11.webp";
import FatLycheeNight12 from "./../FatBurners/imagesFatBurners/FatLycheeNight12.webp";
import FatLycheeNight13 from "./../FatBurners/imagesFatBurners/FatLycheeNight13.webp";

import FatMangoDay11 from "./../FatBurners/imagesFatBurners/FatMangoDay11.webp";
import FatMangoDay12 from "./../FatBurners/imagesFatBurners/FatMangoDay12.webp";
import FatMangoDay13 from "./../FatBurners/imagesFatBurners/FatMangoDay13.webp";
import FatMangoNight11 from "./../FatBurners/imagesFatBurners/FatMangoNight11.webp";
import FatMangoNight12 from "./../FatBurners/imagesFatBurners/FatMangoNight12.webp";
import FatMangoNight13 from "./../FatBurners/imagesFatBurners/FatMangoNight13.webp";
import FatRaspberryDay11 from "./../FatBurners/imagesFatBurners/FatRaspberryDay11.webp";
import FatRaspberryDay12 from "./../FatBurners/imagesFatBurners/FatRaspberryDay12.webp";
import FatRaspberryDay13 from "./../FatBurners/imagesFatBurners/FatRaspberryDay13.webp";
import FatRaspberryNigh11 from "./../FatBurners/imagesFatBurners/FatRaspberryNight11.webp";
import FatRaspberryNight12 from "./../FatBurners/imagesFatBurners/FatRaspberryNight12.webp";
import FatRaspberryNight13 from "./../FatBurners/imagesFatBurners/FatRaspberryNight13.webp";
import vitCarnitin11 from "./../Vitamins/imagesVitamins/vitCarnitin11.webp";
import vitCarnitin12 from "./../Vitamins/imagesVitamins/vitCarnitin12.webp";
import vitCarnitin13 from "./../Vitamins/imagesVitamins/vitCarnitin13.webp";

export const fatBurnersProducts = {
    'fat-burners-lychee-day': {
        title: 'Жиросжигатель для похудения с L карнитином ДНЕВНОЙ со вкусом Личи',
        productType: 'Жиросжигатель. Порошок.',
        description: (
            <>
                <p>Keto Booster Day от Japan formula - это уникальная формула ингредиентов, которая способствует ускорению обмена веществ, снижению веса и уменьшению объемов тела. Keto Booster максимально эффективен, так как его постоянное воздействие ускоряет расщепление жира во всех проблемных зонах. Дневной жиросжигатель также подавляет чувство голода, повышает выносливость во время физических нагрузок, укрепляет мышцы, поддерживает стройность фигуры. Личи. </p>
            </>
        ),
        price: '1600 ',
        tasteEng: 'lychee',
        taste: 'Личи',
        textPurpose: ' Восстановление, Выносливость, Красота и здоровье, Набор мышечной массы, Повышение работоспособности, Похудение/Сушка, Профилактика, Сила',
        composition: ' l-carnitine, лимонная кислота, ароматизатор, ананаса экстракт, кофе зеленый, декстроза, сукралоза, витаминный премикс витаминный, хрома пиколинат. хрома пиколинат',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        water: <><p >Не растворять в горячей воде</p></>,
        purposes: ['стройность', 'энергия', 'восстановление', 'лучшее для мужчин'],
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В защищенном от солнца помещени',
        gender: 'Женский, Мужской',
        images: [FatLycheeDay11, FatLycheeDay12, FatLycheeDay13],
        img: FatLycheeDay11,
        contraindications: <>
            <p>1. Продукт содержит лимонную кислоту, ароматизаторы и декстрозу, которые могут вызвать аллергические реакции.</p>
            <p>2. Продукт содержит витаминный премикс, поэтому людям с гипервитаминозом следует  проконсультироваться с врачом.</p>
            <p>3. Продукт содержит зеленый кофе, поэтому людям с нервными расстройствами рекомендуется проконсультироваться с врачом перед употреблением.</p>
            <p>4. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
        </>,
        contentMethod: (
            <>
                <p> смешать одну чайную ложку (5 гр) с тёплой водой (200 мл), принимать 1-2 раза в первой половине дня.</p>

            </>
        ),
    },
    'fat-burners-lychee-night': {
        title: 'Жиросжигатель для похудения с L карнитином НОЧНОЙ со вкусом Личи',
        productType: 'Жиросжигатель. Порошок.',
        description: (
            <>
                <p>Keto Booster Day от Japan formula - это уникальная формула ингредиентов, которая способствует ускорению обмена веществ, снижению веса и уменьшению объемов тела. Keto Booster максимально эффективен, так как его постоянное воздействие ускоряет расщепление жира во всех проблемных зонах. Дневной жиросжигатель также подавляет чувство голода, повышает выносливость во время физических нагрузок, укрепляет мышцы, поддерживает стройность фигуры. Личи. </p>
            </>
        ),
        price: '1600 ',
        tasteEng: 'lychee',
        taste: 'Личи',
        textPurpose: ' Восстановление, Выносливость, Красота и здоровье, Набор мышечной массы, Повышение работоспособности, Похудение/Сушка, Профилактика, Сила',
        composition: ' l-carnitine, лимонная кислота, ароматизатор, ананаса экстракт, кофе зеленый, декстроза, сукралоза, витаминный премикс витаминный, хрома пиколинат. хрома пиколинат',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        water: <><p >Не растворять в горячей воде</p></>,
        purposes: ['стройность', 'энергия', 'восстановление', 'лучшее для мужчин'],
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В защищенном от солнца помещени',
        gender: 'Женский, Мужской',
        images: [FatLycheeNight11, FatLycheeNight12, FatLycheeNight13],
        img: FatLycheeNight11,
        contraindications: <>
            <p>1. Продукт содержит лимонную кислоту, ароматизаторы и декстрозу, которые могут вызвать аллергические реакции.</p>
            <p>2. Продукт содержит витаминный премикс, поэтому людям с гипервитаминозом следует  проконсультироваться с врачом.</p>
            <p>3. Продукт содержит зеленый кофе, поэтому людям с нервными расстройствами рекомендуется проконсультироваться с врачом перед употреблением.</p>
            <p>4. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
        </>,
        contentMethod: (
            <>
                <p> смешать одну чайную ложку (5 гр) с тёплой водой (200 мл), принимать 1-2 раза во второй половине дня.</p>

            </>
        ),
    },
    'fat-burners-mango-day': {
        title: 'Жиросжигатель для похудения с L карнитином ДНЕВНОЙ со вкусом Манго',
        productType: 'Жиросжигатель. Порошок.',
        description: (
            <>
                <p>Keto Booster Day от Japan formula - это уникальная формула ингредиентов, которая способствует ускорению обмена веществ, снижению веса и уменьшению объемов тела. Keto Booster максимально эффективен, так как его постоянное воздействие ускоряет расщепление жира во всех проблемных зонах. Дневной жиросжигатель также подавляет чувство голода, повышает выносливость во время физических нагрузок, укрепляет мышцы, поддерживает стройность фигуры. Манго. </p>
            </>
        ),
        price: '1600 ',
        tasteEng: 'mango',
        taste: 'Манго',
        textPurpose: 'Восстановление, Выносливость, Красота и здоровье, Набор мышечной массы, Повышение работоспособности, Похудение/Сушка, Профилактика, Сила',
        composition: 'l-carnitine, лимонная кислота, ароматизатор, ананаса экстракт, кофе зеленый, декстроза, сукралоза, витаминный премикс витаминный, хрома пиколинат',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        water: <><p >Не растворять в горячей воде</p></>,
        purposes: ['стройность', 'энергия', 'восстановление', 'лучшее для мужчин'],
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В защищенном от солнца помещени',
        gender: 'Женский, Мужской',
        images: [FatMangoDay11, FatMangoDay12, FatMangoDay13],
        img: FatMangoDay11,
        contraindications: <>
            <p>1. Продукт содержит лимонную кислоту, ароматизаторы и декстрозу, которые могут вызвать аллергические реакции.</p>
            <p>2. Продукт содержит витаминный премикс, поэтому людям с гипервитаминозом следует  проконсультироваться с врачом.</p>
            <p>3. Продукт содержит зеленый кофе, поэтому людям с нервными расстройствами рекомендуется проконсультироваться с врачом перед употреблением.</p>
            <p>4. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
        </>,
        contentMethod: (
            <>
                <p> смешать одну чайную ложку (5 гр) с тёплой водой (200 мл), принимать 1-2 раза в первой половине дня.</p>

            </>
        ),
    },
    'fat-burners-mango-night': {
        title: 'Жиросжигатель для похудения с L карнитином НОЧНОЙ со вкусом Манго',
        productType: 'Жиросжигатель. Порошок.',
        description: (
            <>
                <p>Keto Booster Day от Japan formula - это уникальная формула ингредиентов, которая способствует ускорению обмена веществ, снижению веса и уменьшению объемов тела. Keto Booster максимально эффективен, так как его постоянное воздействие ускоряет расщепление жира во всех проблемных зонах. Дневной жиросжигатель также подавляет чувство голода, повышает выносливость во время физических нагрузок, укрепляет мышцы, поддерживает стройность фигуры. Манго. </p>
            </>
        ),
        price: '1600 ',
        tasteEng: 'mango',
        taste: 'Манго',
        textPurpose: ' Восстановление, Выносливость, Красота и здоровье, Набор мышечной массы, Повышение работоспособности, Похудение/Сушка, Профилактика, Сила',
        composition: 'l-carnitine, лимонная кислота, ароматизатор, ананаса экстракт, кофе зеленый, декстроза, сукралоза, витаминный премикс витаминный, хрома пиколинат',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        water: <><p >Не растворять в горячей воде</p></>,
        purposes: ['стройность', 'энергия', 'восстановление', 'лучшее для мужчин'],
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В защищенном от солнца помещени',
        gender: 'Женский, Мужской',
        images: [FatMangoNight11, FatMangoNight12, FatMangoNight13],
        img: FatMangoNight11,
        contraindications: <>
            <p>1. Продукт содержит лимонную кислоту, ароматизаторы и декстрозу, которые могут вызвать аллергические реакции.</p>
            <p>2. Продукт содержит витаминный премикс, поэтому людям с гипервитаминозом следует  проконсультироваться с врачом.</p>
            <p>3. Продукт содержит зеленый кофе, поэтому людям с нервными расстройствами рекомендуется проконсультироваться с врачом перед употреблением.</p>
            <p>4. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
        </>,
        contentMethod: (
            <>
                <p> смешать одну чайную ложку (5 гр) с тёплой водой (200 мл), принимать 1-2 раза во второй половине дня.</p>

            </>
        ),
    },
    'fat-burners-raspberry-day': {
        title: 'Жиросжигатель для похудения с L карнитином ДНЕВНОЙ со вкусом Малины',
        productType: 'Жиросжигатель. Порошок.',
        description: (
            <>
                <p>Keto Booster Day от Japan formula - это уникальная формула ингредиентов, которая способствует ускорению обмена веществ, снижению веса и уменьшению объемов тела. Keto Booster максимально эффективен, так как его постоянное воздействие ускоряет расщепление жира во всех проблемных зонах. Дневной жиросжигатель также подавляет чувство голода, повышает выносливость во время физических нагрузок, укрепляет мышцы, поддерживает стройность фигуры. Малина. </p>
            </>
        ),
        price: '1600 ',
        tasteEng: 'raspberry',
        taste: 'Малина',
        textPurpose: 'Восстановление, Выносливость, Красота и здоровье, Набор мышечной массы, Повышение работоспособности, Похудение/Сушка, Профилактика, Сила',
        composition: 'l-carnitine, лимонная кислота, ароматизатор, ананаса экстракт, кофе зеленый, декстроза, сукралоза, витаминный премикс витаминный, хрома пиколинат',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        water: <><p >Не растворять в горячей воде</p></>,
        purposes: ['стройность', 'энергия', 'восстановление', 'лучшее для мужчин'],
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В защищенном от солнца помещени',
        gender: 'Женский, Мужской',
        images: [FatRaspberryDay11, FatRaspberryDay12, FatRaspberryDay13],
        img: FatRaspberryDay11,
        contraindications: <>
            <p>1. Продукт содержит лимонную кислоту, ароматизаторы и декстрозу, которые могут вызвать аллергические реакции.</p>
            <p>2. Продукт содержит витаминный премикс, поэтому людям с гипервитаминозом следует  проконсультироваться с врачом.</p>
            <p>3. Продукт содержит зеленый кофе, поэтому людям с нервными расстройствами рекомендуется проконсультироваться с врачом перед употреблением.</p>
            <p>4. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
        </>,
        contentMethod: (
            <>
                <p> смешать одну чайную ложку (5 гр) с тёплой водой (200 мл), принимать 1-2 раза в первой половине дня.</p>

            </>
        ),
    },
    'fat-burners-raspberry-night': {
        title: 'Жиросжигатель для похудения с L карнитином НОЧНОЙ со вкусом Малины',
        productType: 'Жиросжигатель. Порошок.',
        description: (
            <>
                <p>Keto Booster Day от Japan formula - это уникальная формула ингредиентов, которая способствует ускорению обмена веществ, снижению веса и уменьшению объемов тела. Keto Booster максимально эффективен, так как его постоянное воздействие ускоряет расщепление жира во всех проблемных зонах. Дневной жиросжигатель также подавляет чувство голода, повышает выносливость во время физических нагрузок, укрепляет мышцы, поддерживает стройность фигуры. Малина. </p>
            </>
        ),
        price: '1600 ',
        tasteEng: 'raspberry',
        taste: 'Малина',
        textPurpose: ' Восстановление, Выносливость, Красота и здоровье, Набор мышечной массы, Повышение работоспособности, Похудение/Сушка, Профилактика, Сила',
        composition: 'l-carnitine, лимонная кислота, ароматизатор, ананаса экстракт, кофе зеленый, декстроза, сукралоза, витаминный премикс витаминный, хрома пиколинат',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        water: <><p >Не растворять в горячей воде</p></>,
        purposes: ['стройность', 'энергия', 'восстановление', 'лучшее для мужчин'],
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В защищенном от солнца помещени',
        gender: 'Женский, Мужской',
        images: [FatRaspberryNigh11, FatRaspberryNight12, FatRaspberryNight13],
        img: FatRaspberryNigh11,
        contraindications: <>
            <p>1. Продукт содержит лимонную кислоту, ароматизаторы и декстрозу, которые могут вызвать аллергические реакции.</p>
            <p>2. Продукт содержит витаминный премикс, поэтому людям с гипервитаминозом следует  проконсультироваться с врачом.</p>
            <p>3. Продукт содержит зеленый кофе, поэтому людям с нервными расстройствами рекомендуется проконсультироваться с врачом перед употреблением.</p>
            <p>4. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
        </>,
        contentMethod: (
            <>
                <p> смешать одну чайную ложку (5 гр) с тёплой водой (200 мл), принимать 1-2 раза во второй половине дня.</p>

            </>
        ),
    },
    'fat-vitamins-carnitine': {
        title: 'L карнитин l carnitine жиросжигатель, капсулы для похудения',
        productType: 'Пищевая добавка. Капсулы.',
        description: (
            <>
                <p>Japan Formula l карнитин l-carnitine" - это инновационный жиросжигающий продукт, который поможет вам достичь желаемого результата в похудении. Он содержит активный ингредиент, который ускоряет метаболизм и сжигает лишние жиры, что позволяет вам чувствовать себя энергичнее и бодрее.</p>
                <p>Капсулы содержат только натуральные ингредиенты, которые не вызывают побочных эффектов и безопасны для здоровья. Кроме того, они легко усваиваются и быстро начинают действовать.</p>
                <p> Преимущества "Japan Formula" заключаются в том, что он помогает уменьшить аппетит, ускоряет обмен веществ и способствует сжиганию жиров. Это позволяет вам контролировать свой вес и достигать желаемых результатов в похудении без вреда для здоровья.</p>
                <p>"Japan Formula" - это отличный выбор для тех, кто хочет достичь своей цели в похудении и получить желаемый результат. Попробуйте его уже сегодня и убедитесь в его эффективности!</p>
            </>
        ),
        price: '1600 ',
        taste: '',
        textPurpose: 'Спортивное питание, жиросжигатель, кето, комплекс витаминов, похудение; корректор аппетита, жиросжигатель',
        composition: (
            <>
                <p>Л-карнитин тартрат, стеарат магния, кальций, Витамин С, экстракт гуараны, экстракт ананаса, экстракт плодов гарцинии камбоджийской, экстракт зеленого кофе, альфа липоевая кислота, желатиновая капсула (желатин, загуститель, вода, пищевой краситель).</p>
                <p>Диетические особенности: без ГМО, без аспартама, без глютена, без лактозы</p>
            </>
        ),

        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        purposes: ['стройность', 'энергия', 'восстановление', 'лучшее для мужчин'],
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В оригинальной упаковке. В защищенном от солнца помещении',
        gender: 'Женский, Мужской',
        images: [vitCarnitin11, vitCarnitin12, vitCarnitin13],
        img: vitCarnitin11,
        contraindications: <>
            <p>1. Индивидуальная непереносимость компонентов, входящих в состав</p>
            <p>2. Маленькие дети : Продукт не предназначен для детей младше 3 лет.</p>
            <p>3. Дети до 14 лет: Не рекомендуется детям младше 14 лет без консультации с врачом.</p>
        </>,
        contentMethod: (
            <>
                <p>Есть противопоказания, посоветуйтесь с врачом.</p>
                <p>Взрослым по 1 или 2 капсулы в день во время еды.</p>
                <p>Продолжительность приема - 6 месяцев.</p>мл
                <p>При необходимости прием можно повторить.</p>
            </>
        ),
    },
}