import React from "react";
import { Helmet } from "react-helmet-async";

const BaseHelmet = () => (
  <Helmet>
    <meta name="author" content="Japan Formula" />
    <meta
      name="copyright"
      content={`Copyright © ${new Date().getFullYear()} Japan Formula`}
    />
    <meta property="og:type" content="website" />
    <script type="application/ld+json">
      {JSON.stringify({
        "@context": "http://schema.org",
        "@type": "WebPage",
        author: {
          "@type": "Person",
          name: "Japan Formula",
        },
        copyrightHolder: {
          "@type": "Person",
          name: "Japan Formula",
        },
        copyrightYear: new Date().getFullYear(),
        publisher: {
          "@type": "Organization",
          name: "Japan Formula",
        },
      })}
    </script>
  </Helmet>
);

export default BaseHelmet;
