import strawberry from "../images/strawberry.png";
import kiwi from "../images/kiwi.png";
import cherry from "../images/cherry.png";
import chocolate from "../images/chocolate.png";
import mokko from "../images/mokko.png";
import raspberry from "../images/raspberry.png";
import caramel from "../images/caramel.png";
import peanut from "../images/peanut.png";
import lychee from "../images/lychee.png";
import berries from "../images/berries.png";
import coconut from "../images/coconut.png";
import mango from "../images/mango.png";
import RockyRoad from "../images/RockyRoad.png";
import pineapple from "../images/pineapple.png";
import nuts from "../images/nuts.png";

export const cardsTaste = [
  { img: strawberry, alt: "рисунок клубники", name: "strawberry", nameRus:"клубника" },
  { img: kiwi, alt: "рисунок киви", name: "kiwi", nameRus:"киви" },
  { img: cherry, alt: "рисунок вишни", name: "cherry", nameRus:"вишня" },
  { img: chocolate, alt: "рисунок шоколада", name: "chocolate" , nameRus:"шоколад" },
  { img: mokko, alt: "рисунок мокко", name: "frappuccino-moka", nameRus:"ФРАППУЧИНО МОКА" },
  { img: raspberry, alt: "рисунок малины", name: "raspberry", nameRus:"малина" },
  { img: lychee, alt: "рисунок личи", name: "lychee", nameRus:"личи" },
  { img: RockyRoad, alt: "рисунок RockyRoad", name: "rocky-road" , nameRus:"роки роуд" },
  { img: coconut, alt: "рисунок кокоса", name: "coconut", nameRus:"кокос" },
  { img: mango, alt: "рисунок манго", name: "mango" , nameRus:"манго" },
  { img: pineapple, alt: "рисунок ананаса", name: "pineapple", nameRus:"ананас" },
  { img: caramel, alt: "рисунок карамели", name: "caramel", nameRus:"карамель" },
  { img: peanut, alt: "рисунок арахиса", name: "peanut", nameRus:"арахис" },
  { img: berries, alt: "рисунок ягод", name: "berries", nameRus:"дикие ягоды" },
  { img: nuts, alt: "рисунок орехов", name: "nuts", nameRus:"орехи" },
];

	
