import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import PageHelmet from "../PageHelmet";

import { articlesData } from "../../utils/articlesData";

import "./ScientificCenter.css";
import Fon9 from "../../images/Fon9.png";
import CloudsImage from "../../images/Fon9cloud.png";
import WaterImage from "../../images/Fon9down.png";

const articles = Object.keys(articlesData).map((key) => ({
  id: key,
  title: articlesData[key].title,
  text: articlesData[key].text,
  img: articlesData[key].img,
}));

const ScientificCenter = ({ headerHeight }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [cardsPerPage, setCardsPerPage] = useState(0);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleLoadMore = () => {
    if (cardsPerPage < articles.length) {
      if (screenWidth >= 1160) {
        setCardsPerPage((prevCardsPerPage) => prevCardsPerPage + 4);
      } else if (screenWidth >= 865) {
        setCardsPerPage((prevCardsPerPage) => prevCardsPerPage + 3);
      } else {
        setCardsPerPage((prevCardsPerPage) => prevCardsPerPage + 2);
      }
    }
  };

  useEffect(() => {
    if (screenWidth >= 1280) {
      setCardsPerPage(6);
    } else if (screenWidth >= 769) {
      setCardsPerPage(4);
    } else {
      setCardsPerPage(3);
    }
  }, [screenWidth]);

  const cloudAnimation = {
    loop: Infinity,
    ease: "linear",
    duration: 60,
    repeatDelay: 0,
  };

  //тройное повторение движение воды
  const waterAnimation = {
    duration: 10,
    ease: "easeInOut",
    repeat: 3,
    repeatType: "loop",
    repeatDelay: 0,
  };

  return (
    <section
      className="scientific-center"
      id="scientific-center"
      style={{ paddingTop: `${headerHeight}px` }}
    >
<PageHelmet
        title="Полезные статьи о здоровье и питании"
        description="Читайте наши полезные статьи о здоровье, питании, фитнесе и традициях. Узнайте больше о том, как поддерживать здоровый образ жизни и заботиться о своем теле."
        keywords="здоровье, питание, фитнес, коллаген, японские традиции, правильное питание, советы по здоровью"
        image={Fon9}
        canonicalUrl="https://japanformula.com/scientific-center/"
      />
      <div className="scientific-center__wrapper">
        <div className="scientific-center__image-container">
          <img
            className="scientific-center__img"
            src={Fon9}
            alt="Горы в Японии"
          />
            <motion.img
              className="main-title__container-clouds"
              src={CloudsImage}
              alt="Двигающиеся облака"
              animate={{ x: [0, 100, 0] }}
              transition={cloudAnimation}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                pointerEvents: "none",
              }}
            />
            <motion.img
              className="main-title__container-water"
              src={WaterImage}
              alt="Вода"
              animate={{ x: [0, 3, 0] }}
              transition={waterAnimation}
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "98%",
                pointerEvents: "none",
              }}
            />
        </div>

        <ul className="scientific-center__container">
          {articles.slice(0, cardsPerPage).map((article) => (
            <li className="scientific-center__card" key={article.id}>
              <img
                className="scientific-center__card-img"
                src={article.img}
                alt={article.title}
              />
              <h2 className="scientific-center__card-title">{article.title}</h2>
              <Link
                to={`/scientific-center/${article.id}/`}
                className="scientific-center__card-link"
              >
                <span className="scientific-center__card-text">
                  {article.text}
                </span>

                <p className="scientific-center__card-subtitle">
                  Читать всю статью{" "}
                </p>
              </Link>
            </li>
          ))}
        </ul>

        {cardsPerPage < articles.length && (
          <button
            className="scientific-center__button"
            type="button"
            onClick={handleLoadMore}
          >
            Ещё
          </button>
        )}
      </div>
    </section>
  );
};

export default ScientificCenter;
