import React, { useEffect } from "react";

import PageHelmet from "../PageHelmet";
import MarketplaceLink from "../MarketplaceLink";
import { cardsMarketplaces } from "../../utils/cardsMarketplaces";

import "./Store.css";
import logo from "../../images/logo.png";

const Store = ({ headerHeight }) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <section
      className="store"
      id="store"
      style={{ paddingTop: `${headerHeight}px` }}
    >
      <PageHelmet
        title="Наши партнерские маркетплейсы"
        description="список проверенных и популярных онлайн-магазинов, где можно приобрести Japan Formula"
        keywords="Japan Formula, wildberries, ozon, detmir, megamarket, market.yandex"
        image={logo}
        canonicalUrl="https://japanformula.com/store/"
      />

      <div className="store__wrapper">
        <div className="store__container">
          <h1 className="about-us__title">
            Добро пожаловать в наш каталог маркетплейсов!
          </h1>

          <p className="about-us__text">
            Здесь вы найдете список проверенных и популярных онлайн-магазинов,
            где можно приобрести наш товар. Мы тщательно отобрали эти платформы,
            чтобы гарантировать вам качественный сервис и приятные покупки.
          </p>
        </div>

        <div className="store__marketplace-list">
          {cardsMarketplaces.map((marketplace) => (
            <MarketplaceLink key={marketplace.name} href={marketplace.url}>
              <img
                className="store__marketplace-item"
                src={marketplace.img}
                alt={marketplace.name}
                title={marketplace.name}
              />
            </MarketplaceLink>
          ))}
        </div>

        <p className="about-us__text store__text">
          Выбирайте тот маркетплейс, который вам наиболее привычен и удобен.
          <br />
          Нажмите на название любого магазина, чтобы узнать больше и перейти к
          покупкам.
        </p>
      </div>
    </section>
  );
};

export default Store;
