import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import PageHelmet from "../PageHelmet";
import { articlesProducts } from "../../utils/articlesProducts";

import "./Products.css";
import products from "../../images/products.png";

const articles = Object.keys(articlesProducts).map((key) => ({
  id: key,
  title: articlesProducts[key].title,
  text: articlesProducts[key].text,
  img: articlesProducts[key].img,
}));

const Products = ({ headerHeight }) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <section
      className="products"
      id="products"
      style={{ paddingTop: `${headerHeight}px` }}
    >
      <PageHelmet
        title="Протеиновые коктейли, протеиновые батончики, БАДы и витамины | JAPAN FORMULA"
        description="Купите качественные БАДы, протеиновые батончики и витамины в JAPAN FORMULA. Мы предлагаем широкий ассортимент для вашего здоровья и благополучия. Доставка по всей России."
        keywords="BAA, протеиновые батончики, витамины, спортивное питание, здоровье, JAPAN FORMULA, JAPAN FORMULA"
        image={products}
        canonicalUrl="https://japanformula.com/products/"
      />
      <div className="products__wrapper">
        <div className="products__image-container">
          <img className="products__img" src={products} alt="Горы в Японии" />
        </div>

        <ul className="products__container">
          {articles.map((article) => (
            <li className="products__card" key={article.id}>
              <Link
                to={`/products/${article.id}/`}
                className="products__card-link"
              >
                <img
                  className="products__card-img"
                  src={article.img}
                  alt={article.title}
                />
                <h2 className="products__card-title">{article.title}</h2>

                <span className="products__card-text">{article.text}</span>

                <p className="products__card-subtitle">
                  ознакомиться подробнее
                </p>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default Products;
