import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./MainTaste.css";
import { cardsTaste } from "../../utils/cardsTaste";

const MainTaste = ({ headerHeight }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [cardsPerPage, setCardsPerPage] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleLoadMore = () => {
    if (cardsPerPage < cardsTaste.length) {
      if (screenWidth >= 1160) {
        setCardsPerPage((prevCardsPerPage) => prevCardsPerPage + 4);
      } else {
        setCardsPerPage((prevCardsPerPage) => prevCardsPerPage + 5);
      }
    }
  };

  useEffect(() => {
    if (screenWidth >= 769) {
      setCardsPerPage(16);
    } else {
      setCardsPerPage(8);
    }
  }, [screenWidth]);

  const handleSlideClick = (taste) => {
    navigate(`/choose-taste/${taste}`);
  };

  return (
    <section
      className="main-taste"
      id="main-taste"
      style={{ paddingTop: `${headerHeight}px` }}
    >
      <div className="main-taste__wrapper">
        <div className="main-taste__text">
          <h1 className="main-taste__title">Выберите свой вкус</h1>
          <p className="main-taste__instruction">
            Нажмите на изображение фрукта, чтобы увидеть все продукты с этим
            вкусом.
          </p>
        </div>

        <ul className="main-taste__container">
          {cardsTaste.slice(0, cardsPerPage).map((data, index) => (
            <li key={index} className="main-taste__slide">
              <img
                className="main-taste__slide-img"
                src={data.img}
                alt={data.alt}
                title={data.nameRus}
                onClick={() => handleSlideClick(data.name)}
              />
            </li>
          ))}
        </ul>
      </div>
      {cardsPerPage < cardsTaste.length && (
        <button
          className="main-taste__button"
          type="button"
          onClick={handleLoadMore}
        >
          Ещё
        </button>
      )}
    </section>
  );
};

export default MainTaste;
