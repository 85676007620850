import detox01 from "./imagesDetox/detox01.webp";
import detox02 from "./imagesDetox/detox02.webp";
import detoxCherry11 from "./imagesDetox/detoxCherry11.webp";
import detoxCherry12 from "./imagesDetox/detoxCherry12.webp";
import detoxLychee11 from "./imagesDetox/detoxLychee11.webp";
import detoxLychee12 from "./imagesDetox/detoxLychee12.webp";
import detoxMango11 from "./imagesDetox/detoxMango11.webp";
import detoxMango12 from "./imagesDetox/detoxMango12.webp";
import detoxMixChLSC11 from "./imagesDetox/detoxMixChLSC11.webp";
import detoxMixChLSC12 from "./imagesDetox/detoxMixChLSC12.webp";
import detoxMixChLSC13 from "./imagesDetox/detoxMixChLSC13.webp";
import detoxMixChLSC14 from "./imagesDetox/detoxMixChLSC14.webp";
import detoxMixMChSK11 from "./imagesDetox/detoxMixMChSK11.webp";
import detoxMixMChSK12 from "./imagesDetox/detoxMixMChSK12.webp";
import detoxMixMChSK13 from "./imagesDetox/detoxMixMChSK13.webp";
import detoxMixMChSK14 from "./imagesDetox/detoxMixMChSK14.webp";
import detoxMixMLCh11 from "./imagesDetox/detoxMixMLCh11.webp";
import detoxMixMLCh12 from "./imagesDetox/detoxMixMLCh12.webp";
import detoxMixMLCh13 from "./imagesDetox/detoxMixMLCh13.webp";
import detoxMixMLCh14 from "./imagesDetox/detoxMixMLCh14.webp";


export const detoxProducts = {
    'detox-cherry': {
        title: ' Напиток дренажный Detox со вкусом Вишни от Japan Formula',
        productType: 'Мочегонное. Порошок.',
        description: (
            <>
                <p>Дренажный напиток является отличным средством для мягкого очищения организма, которое необходимо каждому человеку. Данный напиток разработан с целью борьбы с водным весом, а также для очищения организма от шлаков и токсинов.Это мягкое, но надежное очищение организма.  Вишня. </p>
            </>
        ),
        tasteEng: 'cherry',
        taste: 'Вишня',
        textPurpose: 'Восстановление, Выносливость, Красота и здоровье, Повышение работоспособности, Похудение/Сушка, Профилактика',
        composition: 'лимонная кислота, витамин е, таурин, киви экстракт, витамин c, витамин b6, витамин в3, спирулина, клубники ягод экстракт, декстроза, сукралоза, кальция пантотенат, ароматизатор натуральный, премикс витаминный',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        water: <><p >Не растворять в горячей воде</p></>,
        price: '900',
        purposes: ['стройность', 'детокс, очищение'],
        country: 'Россия',
        expirationDate: '24',
        storageConditions: ' В защищенном от солнца помещении, В прохладном темном месте, В сухом месте, В сухом прохладном месте, В сухом темном месте, При температуре до +30`C',
        gender: 'Женский, Мужской',
        images: [detoxCherry11, detoxCherry12, detox01, detox02],
        img: detoxCherry11,
        contraindications: <>
            <p>1.  Аллергия на ингредиенты: Продукт может содержать компоненты, такие как лимонная кислота, экстракты фруктов и ягод , которые могут вызвать аллергическую реакцию.</p>
            <p>2. Проблемы с пищеварением : Людям с хроническими заболеваниями желудочно-кишечного тракта следует употреблять с осторожностью.</p>
            <p>3. Беременность и кормление грудью: Продукт не рекомендуется употреблять беременным и кормящим женщинам без консультации с врачом.</p>
            <p>4. Проблемы с пищеварением: Людям с хроническими заболеваниями желудочно-кишечного тракта следует употреблять с осторожностью и после консультации с врачом.</p>
            <p>5. Дети до 16 лет: Напиток не рекомендуется детям младше 16 лет без консультации с врачом.</p>
        </>,
        contentMethod: (
            <>
                <p> Детокс напиток можно приготовить, смешав с тёплой водой.</p>
                <p>По количеству мл: 150 мл - насыщенный; 200 мл - классический; 250 мл - легкий.</p>мл
                <p>Размешайте одну чайную ложку (5 гр) с теплой водой.</p>
                <p>Принимать 1-2 раза в первой половине дня. </p>
            </>
        ),
    },
    'detox-lychee': {
        title: ' Напиток дренажный Detox со вкусом Личи от Japan Formula',
        productType: 'Мочегонное. Порошок.',
        description: (
            <>
                <p>Дренажный напиток является отличным средством для мягкого очищения организма, которое необходимо каждому человеку. Данный напиток разработан с целью борьбы с водным весом, а также для очищения организма от шлаков и токсинов.Это мягкое, но надежное очищение организма. Личи. </p>
            </>
        ),
        tasteEng: 'lychee',
        taste: 'Личи',
        textPurpose: ' Восстановление, Выносливость, Красота и здоровье, Повышение работоспособности, Похудение/Сушка, Профилактика',
        composition: 'лимонная кислота, витамин е, ароматизатор, таурин, витамин в3, витамин с, витамин, декстроза, витаминный премикс, натуральный подсластитель сукралоза, краситель пищевой водорастворимый',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        water: <><p >Не растворять в горячей воде</p></>,
        price: '900',
        purposes: ['стройность', 'детокс, очищение'],
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В защищенном от солнца помещении, В прохладном темном месте, В сухом прохладном месте',
        gender: 'Женский, Мужской',
        images: [detoxLychee11, detoxLychee12, detox01, detox02],
        img: detoxLychee11,
        contraindications: <>
            <p>1. Аллергия на ингредиенты: Продукт может содержать компоненты, такие как лимонная кислота, экстракты фруктов и ягод , которые могут вызвать аллергическую реакцию.</p>
            <p>2. Проблемы с пищеварением : Людям с хроническими заболеваниями желудочно-кишечного тракта следует употреблять с осторожностью.</p>
            <p>3. Беременность и кормление грудью: Продукт не рекомендуется употреблять беременным и кормящим женщинам без консультации с врачом.</p>
            <p>4. Проблемы с пищеварением: Людям с хроническими заболеваниями желудочно-кишечного тракта следует употреблять с осторожностью и после консультации с врачом.</p>
            <p>5. Дети до 16 лет: Напиток не рекомендуется детям младше 16 лет без консультации с врачом.</p>
        </>,
        contentMethod: (
            <>
                <p> Детокс напиток можно приготовить, смешав с тёплой водой.</p>
                <p>По количеству мл: 150 мл - насыщенный; 200 мл - классический; 250 мл - легкий.</p>мл
                <p>Размешайте одну чайную ложку (5 гр) с теплой водой.</p>
                <p>Принимать 1-2 раза в первой половине дня. </p>
            </>
        ),
    },
    'detox-mango': {
        title: ' Напиток дренажный Detox со вкусом Манго от Japan Formula',
        productType: 'Мочегонное. Порошок.',
        description: (
            <>
                <p>Дренажный напиток является отличным средством для мягкого очищения организма, которое необходимо каждому человеку. Данный напиток разработан с целью борьбы с водным весом, а также для очищения организма от шлаков и токсинов.Это мягкое, но надежное очищение организма. Манго. </p>
            </>
        ),
        tasteEng: 'mango',
        taste: 'Манго',
        textPurpose: ' Восстановление, Выносливость, Красота и здоровье, Повышение работоспособности, Похудение/Сушка, Профилактика',
        composition: 'лимонная кислота, витамин е, ароматизатор, таурин, витамин в3, витамин с, витамин, декстроза, витаминный премикс, натуральный подсластитель сукралоза, краситель пищевой водорастворимый',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        water: <><p >Не растворять в горячей воде</p></>,
        price: '900',
        purposes: ['стройность', 'детокс, очищение'],
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В защищенном от солнца помещении, В прохладном темном месте, В сухом месте',
        gender: 'Женский, Мужской',
        images: [detoxMango11, detoxMango12, detox01, detox02],
        img: detoxMango11,
        contraindications: <>
            <p>1. Аллергия на ингредиенты: Продукт может содержать компоненты, такие как лимонная кислота, экстракты фруктов и ягод , которые могут вызвать аллергическую реакцию.</p>
            <p>2. Проблемы с пищеварением : Людям с хроническими заболеваниями желудочно-кишечного тракта следует употреблять с осторожностью.</p>
            <p>3. Беременность и кормление грудью: Продукт не рекомендуется употреблять беременным и кормящим женщинам без консультации с врачом.</p>
            <p>4. Проблемы с пищеварением: Людям с хроническими заболеваниями желудочно-кишечного тракта следует употреблять с осторожностью и после консультации с врачом.</p>
            <p>5. Дети до 16 лет: Напиток не рекомендуется детям младше 16 лет без консультации с врачом.</p>
        </>,
        contentMethod: (
            <>
                <p> Детокс напиток можно приготовить, смешав с тёплой водой.</p>
                <p>По количеству мл: 150 мл - насыщенный; 200 мл - классический; 250 мл - легкий.</p>мл
                <p>Размешайте одну чайную ложку (5 гр) с теплой водой.</p>
                <p>Принимать 1-2 раза в первой половине дня. </p>
            </>
        ),
    },
    'detox-mix-lychee-cherry-strawberry': {
        title: ' Ассорти дренажных напитков Detox со вкусом Вишни, Личи, Клубники-Киви от Japan Formula',
        productType: 'Мочегонное. Порошок.',
        description: (
            <>
                <p>Детокс набор 3шт, дренажный напиток для похудения с мультивитаминами, от отеков и водного веса Вишня, Личи, Клубника-Киви. </p>
            </>
        ),
        tasteEng: 'strawberry kiwi lychee cherry',
        taste: 'Вишня Личи Клубника-Киви',
        textPurpose: ' Восстановление, Выносливость, Красота и здоровье, Повышение работоспособности, Похудение/Сушка, Профилактика',
        composition: 'Смотрите описание конкретного продукта',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        water: <><p >Не растворять в горячей воде</p></>,
        price: '2100',
        purposes: ['лучшее для женщин', 'лучшее для мужчин'],
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В защищенном от солнца помещении, В прохладном темном месте, В сухом месте',
        gender: 'Женский, Мужской',
        images: [detoxMixChLSC11, detoxMixChLSC12, detoxMixChLSC13, detoxMixChLSC14],
        img: detoxMixChLSC11,
        contraindications: <>
            <p>1. Аллергия на ингредиенты: Продукт может содержать компоненты, такие как лимонная кислота, экстракты фруктов и ягод , которые могут вызвать аллергическую реакцию.</p>
            <p>2. Проблемы с пищеварением : Людям с хроническими заболеваниями желудочно-кишечного тракта следует употреблять с осторожностью.</p>
            <p>3. Беременность и кормление грудью: Продукт не рекомендуется употреблять беременным и кормящим женщинам без консультации с врачом.</p>
            <p>4. Проблемы с пищеварением: Людям с хроническими заболеваниями желудочно-кишечного тракта следует употреблять с осторожностью и после консультации с врачом.</p>
            <p>5. Дети до 16 лет: Напиток не рекомендуется детям младше 16 лет без консультации с врачом.</p>
        </>,
        contentMethod: (
            <>
                <p> Детокс напиток можно приготовить, смешав с тёплой водой.</p>
                <p>По количеству мл: 150 мл - насыщенный; 200 мл - классический; 250 мл - легкий.</p>мл
                <p>Размешайте одну чайную ложку (5 гр) с теплой водой.</p>
                <p>Принимать 1-2 раза в первой половине дня. </p>
            </>
        ),
    },
    'detox-mix-mango-cherry-strawberry': {
        title: ' Ассорти дренажных напитков Detox со вкусом Манго,Вишни, Клубники-Киви от Japan Formula',
        productType: 'Мочегонное. Порошок.',
        description: (
            <>
                <p>Детокс набор 3шт, дренажный напиток для похудения с мультивитаминами, от отеков и водного веса Вишня, Манго, Клубника-Киви. </p>
            </>
        ),
        tasteEng: 'strawberry kiwi mango cherry',
        taste: 'Вишня Манго Клубника-Киви',
        textPurpose: ' Восстановление, Выносливость, Красота и здоровье, Повышение работоспособности, Похудение/Сушка, Профилактика',
        composition: 'Смотрите описание конкретного продукта',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        water: <><p >Не растворять в горячей воде</p></>,
        price: '2100',
        purposes: ['лучшее для женщин', 'лучшее для мужчин'],
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В защищенном от солнца помещении, В прохладном темном месте, В сухом месте',
        gender: 'Женский, Мужской',
        images: [detoxMixMChSK11, detoxMixMChSK12, detoxMixMChSK13, detoxMixMChSK14],
        img: detoxMixMChSK11,
        contraindications: <>
            <p>1. Аллергия на ингредиенты: Продукт может содержать компоненты, такие как лимонная кислота, экстракты фруктов и ягод , которые могут вызвать аллергическую реакцию.</p>
            <p>2. Проблемы с пищеварением : Людям с хроническими заболеваниями желудочно-кишечного тракта следует употреблять с осторожностью.</p>
            <p>3. Беременность и кормление грудью: Продукт не рекомендуется употреблять беременным и кормящим женщинам без консультации с врачом.</p>
            <p>4. Проблемы с пищеварением: Людям с хроническими заболеваниями желудочно-кишечного тракта следует употреблять с осторожностью и после консультации с врачом.</p>
            <p>5. Дети до 16 лет: Напиток не рекомендуется детям младше 16 лет без консультации с врачом.</p>
        </>,
        contentMethod: (
            <>
                <p> Детокс напиток можно приготовить, смешав с тёплой водой.</p>
                <p>По количеству мл: 150 мл - насыщенный; 200 мл - классический; 250 мл - легкий.</p>мл
                <p>Размешайте одну чайную ложку (5 гр) с теплой водой.</p>
                <p>Принимать 1-2 раза в первой половине дня. </p>
            </>
        ),
    },
    'detox-mix-cherry-lychee-mango': {
        title: ' Ассорти дренажных напитков Detox со вкусом Манго,Вишни, Личи от Japan Formula',
        productType: 'Мочегонное. Порошок.',
        description: (
            <>
                <p>Детокс набор 3шт, дренажный напиток для похудения с мультивитаминами, от отеков и водного веса Вишня, Манго, Личи. </p>
            </>
        ),
        tasteEng: 'lychee mango cherry',
        taste: 'Вишня Манго Личи',
        textPurpose: ' Восстановление, Выносливость, Красота и здоровье, Повышение работоспособности, Похудение/Сушка, Профилактика',
        composition: 'Смотрите описание конкретного продукта',
        text: 'БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ',
        water: <><p >Не растворять в горячей воде</p></>,
        price: '2100',
        purposes: ['лучшее для женщин', 'лучшее для мужчин'],
        country: 'Россия',
        expirationDate: '24',
        storageConditions: 'В защищенном от солнца помещении, В прохладном темном месте, В сухом месте',
        gender: 'Женский, Мужской',
        images: [detoxMixMLCh11, detoxMixMLCh12, detoxMixMLCh13, detoxMixMLCh14],
        img: detoxMixMLCh11,
        contraindications: <>
            <p>1. Аллергия на ингредиенты: Продукт может содержать компоненты, такие как лимонная кислота, экстракты фруктов и ягод , которые могут вызвать аллергическую реакцию.</p>
            <p>2. Проблемы с пищеварением : Людям с хроническими заболеваниями желудочно-кишечного тракта следует употреблять с осторожностью.</p>
            <p>3. Беременность и кормление грудью: Продукт не рекомендуется употреблять беременным и кормящим женщинам без консультации с врачом.</p>
            <p>4. Проблемы с пищеварением: Людям с хроническими заболеваниями желудочно-кишечного тракта следует употреблять с осторожностью и после консультации с врачом.</p>
            <p>5. Дети до 16 лет: Напиток не рекомендуется детям младше 16 лет без консультации с врачом.</p>
        </>,
        contentMethod: (
            <>
                <p> Детокс напиток можно приготовить, смешав с тёплой водой.</p>
                <p>По количеству мл: 150 мл - насыщенный; 200 мл - классический; 250 мл - легкий.</p>мл
                <p>Размешайте одну чайную ложку (5 гр) с теплой водой.</p>
                <p>Принимать 1-2 раза в первой половине дня. </p>
            </>
        ),
    },
}