import collagen from "../images/collagen-title.png";
import proteinBars from "../images/protein-bars-title.png";
import proteinShakes from "../images/protein-shakes-title.png";
import fatBurners from "../images/fat-burners-title.png";
import detox from "../images/detox-title.png";
import vitamins from "../images/vitamins-title.png";

export const articlesProducts = {
    'collagen': {
        title: 'Коллаген',
        content: (
            <>
                <p>Коллаген является основным структурным белком, обеспечивающим прочность и эластичность тканей организма. Он особенно важен для здоровья кожи, суставов, волос и ногтей. С возрастом уровень коллагена в организме снижается, что может привести к появлению морщин и другим признакам старения. Потребление коллагеновых добавок помогает поддерживать здоровье кожи и суставов.</p>
                <p>Коллаген Japan Formula - инновационный продукт, изготовленный на основе современных японских ингредиентов.</p>
            </>
        ),
        text: 'Коллаген — структурный белок, который обеспечивает прочность и эластичность тканей организма. Он важен для здоровья кожи, суставов, волос и ногтей. Уровень коллагена снижается с возрастом, что может привести к появлению морщин.',
        img: collagen,
        card: 'collagenProducts',
        titleMethod: 'Как принимать коллаген от JAPAN FORMULA',
        contentMethod: (
            <>
                <p>Принимать Коллаген Порошок необходимо в разведенном виде - 2 мерные ложки (4,5 гр) следует разводить в стакане с водой, йогуртом, молоком, кефиром, соевым молоком или любым типом молока на ваш вкус (100мл).</p>

                <p> Не растворять в горячей воде!</p>
                <br />
                <p> Принимать Коллаген в капсулах согласно инструкции.</p>
            </>
        ),
    },
    'protein-bars': {
        title: 'Протеиновые батончики',
        content: (
            <>
                <p>Протеиновые батончики от Japan Formula — это удобный и вкусный способ увеличить потребление белка. </p>
                <p>Этот батончик не содержит сахара, что делает его идеальным выбором для людей, которые хотят снизить потребление сахара и калорий. </p>
                <p>  Они идеальны для перекусов, особенно после тренировок, так как помогают восстановить мышцы и зарядить энергией.</p>

            </>
        ),
        text: 'Протеиновые батончики — удобный и вкусный способ увеличить потребление белка. Они идеальны для перекусов после тренировок, помогая восстановить мышцы и зарядить энергией.',
        img: proteinBars,
        card: 'proteinBarsProducts',
    },
    'protein-shakes': {
        title: 'Протеиновые коктейли',
        content: (
            <>
                <p>Протеиновые коктейли от Japan Formula — это легкий и вкусный способ увеличить потребление белка без сахара.</p>
                <p>Идеальные для тех, кто стремится поддерживать фигуру и улучшать физическую форму, они обеспечивают организм необходимыми аминокислотами и энергией.</p>
                <p>Эти коктейли отлично подходят как для утреннего приема, так и для восстановления после тренировок, способствуя росту и восстановлению мышечной массы.</p>
            </>
        ),
        text: <>
            <p>Протеиновые коктейли — легкий и вкусный способ увеличить потребление белка без сахара.</p>
            <p> Фитококтейль подарит вам красивое тело, заряд энергии, витаминов, поможет в контроле аппетита и наборе мышечной массы! </p>

        </>,
        img: proteinShakes,
        card: 'proteinShakesProducts',
    },
    'fat-burners': {
        title: 'Жиросжигатели',
        content: (
            <><p></p>
                <p>Жиросжигатели — это уникальная формула ингредиентов, добавки, предназначенные для ускорения метаболизма и увеличения скорости сжигания жира. Keto Booster максимально эффективен, так как его постоянное воздействие ускоряет расщепление жира во всех проблемных зонах.</p>
                <p>Мы предлагаем два вида жиросжигателей: дневной и ночной. Оба продукта разработаны для достижения максимального эффекта в течение всего дня и ночи.</p>
                <br />
                <p className="bold-text">Дневной Жиросжигатель</p>
                <p>Характеристики:</p>
                <p>- Ускоряет метаболизм: Формула дневного жиросжигателя специально разработана для увеличения скорости метаболизма в течение дня, когда ваше тело наиболее активно.</p>
                <p> - Повышает энергию: Содержит компоненты, которые помогают повысить уровень энергии, позволяя вам чувствовать себя бодрым и активным.</p>
                <p> - Снижает аппетит: Способствует контролю аппетита, помогая уменьшить желание перекусывать между основными приемами пищи.</p>
                <p> - Фокус и концентрация: Включает ингредиенты, которые улучшают умственную концентрацию и фокус.</p>
                <br />
                <p className="bold-text">Ночной Жиросжигатель</p>
                <p>Характеристики:</p>
                <p>Поддерживает метаболизм во время сна: Формула ночного жиросжигателя помогает ускорить метаболизм даже во время сна, способствуя эффективному сжиганию жира.</p>
                <p> - Улучшает качество сна: Содержит натуральные компоненты, которые способствуют расслаблению и улучшению качества сна, что важно для восстановления организма.</p>
                <p>  -Сжигание жира ночью: Способствует расщеплению жира в течение ночи, работая над проблемными зонами, пока вы отдыхаете.</p>
                <p> - Антиоксиданты и витамины: Включает в себя антиоксиданты и витамины, которые поддерживают общее здоровье организма и способствуют восстановлению.</p>
                <br />
                <p>
                    Обе формулы жиросжигателей разработаны с учетом суточного ритма вашего тела, обеспечивая круглосуточную поддержку в борьбе с лишним весом и достижении ваших фитнес-целей. Используйте их вместе для достижения наилучшего результата и ускорения процесса сжигания жира.</p>
            </>
        ),
        text: 'Жиросжигатели — добавки для ускорения метаболизма и увеличения скорости сжигания жира. Они содержат активные ингредиенты, такие как кофеин, зеленый чай и L-карнитин, способствующие снижению веса.',
        img: fatBurners,
        card: 'fatBurnersProducts',
    },
    'detox': {
        title: 'Комплекс "Детокс"',
        content: (
            <>
                <p>Дренажный напиток является отличным средством для мягкого очищения организма, которое необходимо каждому человеку. Данный напиток разработан с целью борьбы с водным весом, а также для очищения организма от шлаков и токсинов.</p>
                <br />
                <p> Состав дренажного напитка уникален, применены лучшие технологии для очистки сырья. В составе используются натуральные природные компоненты, которые выводят лишнюю воду из организма, борются с отеками, постепенно лишний вес уходит. Это мягкое, но надежное очищение организма.</p>
                <br />
                <p>Необходимо отметить, что дренажный напиток не только помогает избавиться от лишнего веса, но и укрепляет иммунитет. Данный напиток отлично подходит для похудения, поддержания диеты и утоления голода. Им вы можете заменить перекус. Выпив дренажный напиток, вы не только насытитесь, но и будете худеть, учитывая, что вы выпили минимальное количество килокалорий. Дренажный напиток подарит вам не только фигуру мечты, но и заряд бодрости!</p>
            </>
        ),
        text: 'Комплекс "Детокс" разработан для очищения организма от токсинов и улучшения самочувствия. Он содержит растительные экстракты, витамины и минералы, поддерживающие функцию печени и других органов, участвующих в детоксикации.',
        img: detox,
        card: 'detoxProducts',
    },
    'vitamins': {
        title: 'Витаминные комплексы',
        content: (
            <>
                <p>Витаминные комплексы содержат комбинации различных витаминов и минералов, необходимых для поддержания здоровья и нормального функционирования организма. Они помогают восполнить дефицит нутриентов, который может возникнуть из-за несбалансированного питания или повышенных физических и умственных нагрузок. Регулярный прием витаминных комплексов способствует укреплению иммунной системы, улучшению состояния кожи, волос и ногтей, а также повышению энергии и выносливости.</p>
                <br />
                <p>Не упустите возможность стать здоровее и красивее с Japan Formula!</p>
            </>
        ),
        text: 'Витаминные комплексы содержат комбинации витаминов и минералов, необходимых для здоровья. Они помогают восполнить дефицит нутриентов, укрепляют иммунную систему и улучшают состояние кожи, волос и ногтей.',
        img: vitamins,
        card: 'vitaminsProducts',
    },
}