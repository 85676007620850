import React from "react";

import "./Values.css";

const Values = ({ handleGoMain }) => {
  return (
    <section className="values" id="values">
      <div className="values__wrapper">
        <h1 className="values__title">Наши ценности</h1>
        <h2 className="values__subtitle">Здоровье:</h2>
        <p className="values__text">
          Мы убеждены, что здоровье – это основа счастливой и успешной жизни.
        </p>
        <h2 className="values__subtitle">Широкий ассортимент:</h2>
        <p className="values__text">
          У нас вы найдете всё необходимое для поддержания активного и здорового
          образа жизни, от спортивного питания до витаминов и БАДов.
        </p>
        <h2 className="values__subtitle">Честность:</h2>
        <p className="values__text">
          Мы предоставляем только правдивую информацию о наших продуктах и их
          свойствах.
        </p>
        <h2 className="values__subtitle">Клиентоориентированность:</h2>
        <p className="values__text">
          Ваши потребности и пожелания всегда в центре нашего внимания.
        </p>

        <h3 className="values__topic">
          Мы благодарим вас за выбор нашей компании и надеемся, что наши
          продукты помогут вам достигнуть новых высот в спорте и жизни.
        </h3>
        <button
          className="values__button-main privacy-policy__button"
          type="button"
          onClick={handleGoMain}
        >
          На главную
        </button>
      </div>
    </section>
  );
};

export default Values;
